(function () {
  angular.module('kmi.lms.course.registrations').component('playCourse', {
    template: require('ajs/modules/course/registrations/components/modern/play-course.html').default,
    controller: PlayCourseController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
      trackingName: '=?',
      mode: '<?',
      vertical: '<?',
      smallView: '<?',
      actionData: '=?',
      customName: '=',
      buttonAvailable: '=?',
    },
  });

  // LaunchPreEvaluationAction do not full support action

  /* @ngInject */
  function PlayCourseController(
    $scope,
    rootScopeService,
    currentUser,
    LearningObjectRegistration,
    $state,
    LearningObjectRegistrationWorkflow,
    userCertificatesService,
    notificationService,
    _,
    courseDetailsState,
    playCourseService,
    courseEnums,
    courseRegistrationStatus,
    eventExportService,
    $location,
    courseRegistrationService,
    sessionsService,
    globalConfig,
  ) {
    let vm = this;

    vm.courseFormatType = courseEnums.formatType;
    vm.courseFormat = courseEnums.format;
    vm.settings = _.get(globalConfig, 'settings.courseDetails');
    vm.isAnonymous = currentUser.get().anonymous;
    vm.locationType = courseEnums.locationType;
    vm.execute = execute;
    vm.printCertificate = printCertificate;
    vm.actions = [];
    vm.buyNow = buyNow;
    vm.isCourseInCreateRegistrationState = playCourseService.isCourseInCreateRegistrationState;
    vm.isCourseInRestartState = playCourseService.isCourseInRestartState;
    vm.isCoursePlayAvailable = playCourseService.isCoursePlayAvailable;
    vm.isEvaluationLaunchAvailable = playCourseService.isEvaluationLaunchAvailable;
    vm.isBuyNowAvailable = playCourseService.isBuyNowAvailable;
    vm.isSinglePlayCourse = playCourseService.isSinglePlayCourse;
    vm.isPrintCourseAvailable = playCourseService.isPrintCourseAvailable;
    vm.buttonPresented = buttonPresented;
    vm.$onInit = init;
    vm.scrollToCourse = scrollToCourse;
    vm.isContinueBtnAvailable = isContinueBtnAvailable;
    vm.isAttendTheMeetingBtnAvailable = isAttendTheMeetingBtnAvailable;
    vm.isEvaluateBtnAvailable = isEvaluateBtnAvailable;
    vm.isRegisterBtnAvailable = isRegisterBtnAvailable;
    vm.isAddToCalendarBtnAvailable = isAddToCalendarBtnAvailable;

    vm.attendTypeIdSelectionExists = attendTypeIdSelectionExists;

    vm.isMainPlayBtnAvailable = isMainPlayBtnAvailable;
    vm.isMarkCompletedBtnAvailable = isMarkCompletedBtnAvailable;
    vm.getMainButtonName = getMainButtonName;

    vm.isPrintCertBtnAvailable = isPrintCertBtnAvailable;
    vm.userHasCompletedSessions = userHasCompletedSessions;
    vm.addToCalendar = addToCalendar;
    vm.isLiveEvent = isLiveEvent;

    function init() {
      vm.currentBlsId = $location.search().currentBlsId;
      if (vm.currentBlsId && !_.get(vm, 'actionData.current_bls_id')) {
        if (!vm.actionData) {
          vm.actionData = {};
        }
        vm.actionData.current_bls_id = vm.currentBlsId;
      }

      vm.singlePlayCourse = playCourseService.isSinglePlayCourse(vm.mode);
      vm.isReady = !!vm.mode;

      vm.stateChanged = false;
      $scope.$on('event:embedded-course-player-will-open', function () {
        vm.stateChanged = true;
      });

      ///////////////// check event registration updated -----------------

      $scope.$on('event:course.registration.updated', function (event, registration) {
        if (registration.courseId === _.get(vm.userCourse, 'workflow.registration.courseId')) {
          courseRegistrationService
            .updateUserCourse(vm.userCourse, currentUser.get().id, vm.course)
            .then(function (newUserCourse) {
              vm.userCourse = newUserCourse;
            })
            .finally(function () {
              vm.registrationExecution = null;
            });
        } else {
          vm.registrationExecution = null;
        }
      });
      $scope.$watch('vm.buttonPresented()', function () {
        updateButtonShown();
      });
      $scope.$on('event:course.registration.action.canceled', function () {
        vm.registrationExecution = null;
      });

      $scope.$on('event:course.registration.action.start', function () {
        vm.registrationExecution = true;
      });

      $scope.$on('event:course.details.ready', function () {
        vm.isReady = true;
      });

      $scope.$watch('vm.actionsOpen', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue && vm.userCourse && !vm.userCourse.workflow) {
          loadWorkflow(true);
        }
      });
    }

    function updateButtonShown() {
      vm.buttonAvailable = attendTypeIdSelectionExists() || buttonPresented();
    }

    function scrollToCourse() {
      rootScopeService.broadcast('event:collection:scrollToCourse');
    }

    function goToCollectionPlayer() {
      rootScopeService.broadcast('event:collection:goToPlayer');
    }

    function goToCourseDetails() {
      $state.go('main.course', { id: vm.course.id });
    }

    function buttonPresented() {
      return isMainPlayBtnAvailable() || isAddToCalendarBtnAvailable() || isPrintCertBtnAvailable();
    }

    function execute(actionData) {
      if (actionData) {
        vm.actionData = actionData;
      }

      if (isRegisterBtnAvailable()) {
        if (vm.userCourse.workflow.registration.isCheckState()) {
          return vm.userCourse.workflow.showCheckStateNotification();
        } else {
          return scrollToSession();
        }
      }
      if (
        playCourseService.isSinglePlayCourse(vm.mode) &&
        (!courseDetailsState.modernFeaturesEnabled(vm.course) ||
          vm.course.formatTypeId === vm.courseFormatType.collection)
      ) {
        goToCourseDetails();
      } else {
        if (!vm.userCourse.workflow) {
          loadWorkflow().then(executeAction);
        } else {
          executeAction();
        }
      }
    }

    function executeAction() {
      // This method workflow required
      if (!vm.actions.length) {
        vm.actions = vm.userCourse.workflow.getPlayCourseActions();
      }
      let actions = playCourseService.getAvailablePlayCourseActions(vm.userCourse, vm.actions);

      if (actions.length > 0) {
        vm.registrationExecution = true;
        let skipExecute = false;

        let action = actions[actions.length - 1];
        if (isAttendTheMeetingBtnAvailable() && action.name !== 'LaunchOnlineMeetingAction') {
          action = _.find(actions, { name: 'LaunchOnlineMeetingAction' });
        }

        if (action.name === 'LaunchOnlineMeetingAction') {
          vm.actionData = vm.actionData || {};
          if (!vm.actionData.session_id) {
            vm.actionData.session_id = vm.userCourse.lastRegistration.sessionId;
          }

          if (!vm.actionData.schedule_id) {
            vm.actionData.schedule_id = vm.userCourse.workflow.registration.onlineMeetingSchedules[0].id;
          }
        }

        if (
          !playCourseService.isSinglePlayCourse(vm.mode) &&
          _.get(vm.userCourse.workflow, 'registration.course.formatTypeId') === 4 &&
          vm.course.playable
        ) {
          if ('MoveToInProgressAction' === action.name) {
            $scope.$on('event:course.registration.action.finished', function () {
              goToCollectionPlayer();
            });
          }

          if ('LaunchPreAssessmentAction' === action.name || 'LaunchPreEvaluationAction' === action.name) {
            // Launch PreAssessment for collection in collection player for playable components
            let registerAction = _.find(actions, function (currentAction) {
              return currentAction.name === 'RegisterAction';
            });

            if (registerAction) {
              // We need first create registration and then launch collection player with preAssessment.
              action = registerAction;
              $scope.$on('event:course.registration.action.finished', function () {
                goToCollectionPlayer();
              });
            } else {
              skipExecute = true;
              goToCollectionPlayer();
            }
          }

          if ('LaunchPostAssessmentAction' === action.name) {
            skipExecute = true;
            goToCollectionPlayer();
          }
        }

        if (!skipExecute) {
          vm.userCourse.workflow.exec(action.name, vm.actionData);
        }
      } else {
        if (playCourseService.componentsScrollAvailable(vm.course, vm.userCourse, vm.mode)) {
          // Go to collection player
          if (vm.course.playable) {
            goToCollectionPlayer();
          } else {
            scrollToCourse();
          }
        } else {
          if (vm.userCourse.workflow.registration.isCheckState()) {
            vm.userCourse.workflow.showCheckStateNotification();
          } else {
            if (vm.userCourse.workflow.registration.status_id === 1) {
              notificationService.info('The registration is not yet approved.', 3e3);
            } else {
              notificationService.info("Sorry, you don't have available actions.", 3e3);
            }
          }
        }
      }
    }

    function printCertificate() {
      // This method workflow required
      function print() {
        const registrationId =
          _.get(vm.userCourse, 'workflow.registration.id') ||
          _.get(vm.userCourse, 'workflow.registration.completedRegistration.id');

        if (registrationId) {
          userCertificatesService.printCertificate(registrationId);
        } else {
          notificationService.error('Certificate is not available.', 3e3);
        }
      }

      if (vm.userCourse.workflow) {
        print();
      } else {
        loadWorkflow()
          .then(print)
          .finally(function () {
            vm.loading = null;
          });
      }
    }

    function loadWorkflow(silentLoad) {
      vm.loading = !silentLoad;

      return LearningObjectRegistration.get(vm.course)
        .then(function (registration) {
          vm.userCourse.workflow = new LearningObjectRegistrationWorkflow(registration);
          vm.actions = vm.userCourse.workflow.getPlayCourseActions();
        })
        .finally(function () {
          vm.loading = null;
        });
    }

    ////// Methods for the payment

    function buyNow() {
      function addProduct() {
        if (_.get(vm, 'userCourse.workflow') && vm.userCourse.workflow.registration.isCheckState()) {
          vm.userCourse.workflow.showCheckStateNotification();
          return;
        }

        $state.go('main.checkout', { courseId: vm.course.id });
      }

      if (vm.isAnonymous || _.get(vm, 'userCourse.workflow')) {
        addProduct();
      } else {
        loadWorkflow().then(addProduct);
      }
    }
    ////// ------------

    function scrollToSession() {
      rootScopeService.broadcast('event:liveEvent:scrollToSession');
    }

    function isContinueBtnAvailable() {
      let registration = _.get(vm, 'userCourse.workflow.registration'),
        continueBtnActions = [
          'LaunchPreAssessmentAction',
          'LaunchPreEvaluationAction',
          'MoveToPostAssessmentAction',
          'LaunchPostAssessmentAction',
          'MoveToEvaluationPendingAction',
          'LaunchEvaluationAction',
        ],
        sessionContinueAllowed = false;

      if (registration && registration.session_id) {
        var currentSession = _.find(registration.sessions, function (session) {
          return session.id === registration.session_id;
        });

        if (
          _.intersection(registration.actions, [
            'MoveToPostAssessmentAction',
            'LaunchPostAssessmentAction',
            'MoveToEvaluationPendingAction',
            'LaunchEvaluationAction',
          ]).length
        ) {
          sessionContinueAllowed = true;
        } else {
          sessionContinueAllowed =
            currentSession &&
            currentSession.actions &&
            _.intersection(currentSession.actions, ['LaunchPreAssessmentAction', 'LaunchPreEvaluationAction']).length;
        }
      }

      return !!(
        !isAttendTheMeetingBtnAvailable() &&
        !isRegisterBtnAvailable() &&
        !_.includes([5, 40, 41, 42], _.get(vm.userCourse, 'lastRegistration.statusId')) &&
        ((!vm.isCourseInCreateRegistrationState(vm.course, vm.userCourse) &&
          !vm.isCourseInRestartState(vm.course, vm.userCourse) &&
          !isLiveEvent()) ||
          (isLiveEvent() &&
            registration &&
            registration.id &&
            _.intersection(registration.actions, continueBtnActions).length &&
            sessionContinueAllowed))
      );
    }

    function attendTypeIdSelectionExists() {
      return (
        vm.isCourseInCreateRegistrationState(vm.course, vm.userCourse) &&
        vm.isSinglePlayCourse(vm.mode) &&
        _.get(vm.course, 'courseLocation.typeId') === vm.locationType.inPersonAndVirtual
      );
    }

    function isAttendTheMeetingBtnAvailable() {
      return playCourseService.isAttendTheMeetingBtnAvailable(vm.userCourse, vm.course);
    }

    function isEvaluateBtnAvailable() {
      return !!(!isAttendTheMeetingBtnAvailable() && vm.isEvaluationLaunchAvailable(vm.course, vm.userCourse));
    }

    function isRegisterBtnAvailable() {
      if (!isLiveEvent()) {
        return false;
      }

      var availableSessions4Register = _.filter(vm.course.sessions, function (session) {
          return (
            !session.expired &&
            (!session.status_id ||
              (session.status_id && _.includes(courseRegistrationStatus.declinedSet, session.status_id)))
          );
        }).length,
        registration = _.get(vm, 'userCourse.workflow.registration'),
        hasSessionAction = false;

      if (registration && registration.sessions) {
        if (!vm.actions.length) {
          vm.actions = vm.userCourse.workflow.getPlayCourseActions();
        }

        hasSessionAction = _.find(registration.sessions, function (session) {
          return (
            session.actions &&
            session.actions.length &&
            _.intersection(session.actions, _.map(vm.actions, 'name')).length
          );
        });
      }

      return !!(
        !isAttendTheMeetingBtnAvailable() &&
        !sessionsService.userHasIncompletedSessions(vm.course.sessions) &&
        availableSessions4Register &&
        hasSessionAction &&
        !(
          registration &&
          (_.includes(registration.actions, 'GetApprovalAction') ||
            _.includes(registration.actions, 'LaunchPreAssessmentAction'))
        ) &&
        !isEvaluateBtnAvailable()
      );
    }

    function isAddToCalendarBtnAvailable() {
      var registration = _.get(vm, 'userCourse.workflow.registration'),
        excludedStatuses = angular.copy(courseRegistrationStatus.terminalSet);
      excludedStatuses.push(courseRegistrationStatus.notStarted);

      var incompletedSessions = sessionsService.getUserIncompletedSessions(vm.course.sessions);

      return !!(
        btnAvailableMainRule() &&
        !isContinueBtnAvailable() &&
        !isAttendTheMeetingBtnAvailable() &&
        !isMarkCompletedBtnAvailable() &&
        isLiveEvent() &&
        (!vm.course.hasOnlineMeetings ||
          (incompletedSessions && incompletedSessions.length && !incompletedSessions[0].expired)) &&
        registration &&
        registration.id &&
        !_.includes(excludedStatuses, registration.status_id) &&
        !_.includes(registration.actions, 'CompleteAction')
      );

      // It looks like this is not needed - _.includes(registration.actions, 'CompleteAction')
    }

    const mainPlayButtonNameByFormat = {
      defaultActions: {
        start: 'Start',
        reStart: 'Re-Start',
        continue: 'Continue',
        takePreAssessent: 'Continue',
        takeAssessent: 'Continue',
        takeEvaluation: 'Continue',
        attendTheMeeting: 'Continue',
        scrollToComponents: 'Continue',
        markCompleted: 'Continue',
      },
      formatType: {
        '2': {
          start: 'Register',
          reStart: 'Re-Register',
          attendTheMeeting: 'Attend The Meeting',
        },
        '7': {
          start: 'Register',
          reStart: 'Re-Register',
          attendTheMeeting: 'Attend The Meeting',
        },
      },
      format: {
        '131': {
          start: 'Register',
          reStart: 'Re-Register',
        },
        '132': {
          start: 'Register',
          reStart: 'Re-Register',
          takeAssessent: 'Take assessment',
          takeEvaluation: 'Take evaluation',
          continue: 'Complete',
          markCompleted: 'Complete',
        },
      },
    };

    function getMainButtonName() {
      if (vm.customName) {
        return vm.customName;
      }

      let names = angular.extend(
        {},
        mainPlayButtonNameByFormat.defaultActions,
        _.get(
          mainPlayButtonNameByFormat.formatType,
          vm.course.formatTypeId,
          _.get(mainPlayButtonNameByFormat.format, vm.course.formatId, {}),
        ),
      );

      if (!vm.isLiveEvent()) {
        // Check of registration in start state
        if (
          !vm.isCourseInRestartState(vm.course, vm.userCourse, vm.mode) &&
          vm.isCourseInCreateRegistrationState(vm.course, vm.userCourse)
        ) {
          return names.start;
        }

        // Check of registration in reStart state
        if (vm.isCourseInRestartState(vm.course, vm.userCourse)) {
          return names.reStart;
        }
      } else {
        if (vm.isRegisterBtnAvailable()) {
          if (vm.userHasCompletedSessions()) {
            return names.reStart;
          } else {
            return names.start;
          }
        }

        if (vm.isAttendTheMeetingBtnAvailable()) {
          return names.attendTheMeeting;
        }

        if (vm.isMarkCompletedBtnAvailable()) {
          return names.markCompleted;
        }
      }

      if (vm.isContinueBtnAvailable()) {
        if (_.get(vm.userCourse, 'lastRegistration.statusId') === courseRegistrationStatus.preAssessmentPending) {
          return names.takePreAssessent;
        }

        if (_.get(vm.userCourse, 'lastRegistration.statusId') === courseRegistrationStatus.evaluationPending) {
          return names.takeEvaluation;
        }
        if (_.get(vm.userCourse, 'lastRegistration.statusId') === courseRegistrationStatus.assessmentPending) {
          return names.takeAssessent;
        }

        return names.continue;
      }
    }

    function btnAvailableMainRule() {
      return vm.mode !== 'buyNowOnly' && vm.isCoursePlayAvailable(vm.course, vm.userCourse, vm.mode) && vm.isReady;
    }

    function isMainPlayBtnAvailable() {
      return !!(
        _.get(vm, 'userCourse') &&
        btnAvailableMainRule() &&
        ((vm.isCourseInCreateRegistrationState(vm.course, vm.userCourse) && !isLiveEvent()) ||
          (vm.isCourseInRestartState(vm.course, vm.userCourse) && !isLiveEvent()) ||
          vm.isContinueBtnAvailable() ||
          vm.isRegisterBtnAvailable() ||
          vm.isAttendTheMeetingBtnAvailable() ||
          vm.isMarkCompletedBtnAvailable())
      );
    }

    function isPrintCertBtnAvailable() {
      return !!(
        vm.mode !== 'buyNowOnly' &&
        vm.isReady &&
        vm.userCourse &&
        !vm.isCoursePlayAvailable(vm.course, vm.userCourse, vm.mode) &&
        !vm.isSinglePlayCourse(vm.mode) &&
        vm.isPrintCourseAvailable(vm.course, vm.userCourse)
      );
    }

    function isLiveEvent() {
      return vm.course.formatTypeId === vm.courseFormatType.liveEvent;
    }

    function isMarkCompletedBtnAvailable() {
      if (!isLiveEvent()) {
        return false;
      }

      // Live event course format button
      var registration = _.get(vm, 'userCourse.workflow.registration');
      return (
        !!(registration && registration.actions && _.includes(registration.actions, 'CompleteAction')) &&
        !vm.isAttendTheMeetingBtnAvailable()
      );
    }

    function addToCalendar() {
      var session = _.first(_.filter(vm.course.sessions, { id: vm.userCourse.workflow.registration.session_id }));
      if (session) {
        var singleDayEvent = _.get(globalConfig, 'settings.courseDetails.leCalendarSingleDayEvent');
        var schedules = session.schedules,
          calendarSchedule = angular.copy(schedules[0]);

        if (!singleDayEvent && schedules.length > 1) {
          calendarSchedule.end_date = schedules[schedules.length - 1].end_date;
          calendarSchedule.utc_end_date = schedules[schedules.length - 1].utc_end_date;
        }

        eventExportService.addToCalendarDialog(vm.userCourse.workflow.registration, calendarSchedule);
      }
    }

    function userHasCompletedSessions() {
      return _.some(vm.course.sessions, function (session) {
        return _.includes(courseRegistrationStatus.completedSet, session.status_id);
      });
    }
  }
})();
