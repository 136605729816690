(function () {
  angular.module('kmi.lms.course.purchase').component('courseReceiptComponent', {
    template: require('ajs/modules/course/purchase/history/course-receipt.html').default,
    controller: CourseReceiptController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      user: '=',
    },
  });

  function CourseReceiptController($scope, CoursePurchase) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      CoursePurchase.getHistory(vm.course.id, vm.user.id).then(function (response) {
        vm.purchases = response.data;
      });
    }
  }
})();
