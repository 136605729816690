(function () {
  angular.module('kmi.lms.course.common').factory('courseCompetencyService', courseCompetencyService);

  function courseCompetencyService($http, $cacheFactory, serverErrorHandlerService) {
    return {
      get: get,
      removeCourseModel: removeCourseModel,
      updateCourseModel: updateCourseModel,
      getCourseModelStructure: getCourseModelStructure,
    };

    function get(courseId, adminDetailsView) {
      let urlParts = ['/a/course/', courseId, '/core_competencies/'];
      if (adminDetailsView) {
        urlParts.push('?adminDetailsView=true');
      }

      return $http.get(urlParts.join('')).then(function (response) {
        return response.data;
      });
    }

    function getCourseModelStructure(courseId, modelId, params) {
      // permitted_for: ['course.edit'];
      let urlParts = ['/a/course/', courseId, '/core_competencies/models/', modelId, '/structure/'];

      return $http.get(urlParts.join(''), { params: params }).then(function (response) {
        return response.data;
      });
    }

    function removeCourseModel(courseId, modelId) {
      return $http
        .delete(['/a/course/', courseId, '/core_competencies/models/', modelId, '/'].join(''))
        .then(() => {
          let courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');
          courseCache.removeAll();
        })
        .catch(function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason);
        });
    }

    function updateCourseModel(courseId, modelId, selectedNodes) {
      return $http
        .put(['/a/course/', courseId, '/core_competencies/models/', modelId, '/'].join(''), {
          competencies: selectedNodes,
        })
        .then(() => {
          let courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');
          courseCache.removeAll();
        })
        .catch(function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason);
        });
    }
  }
})();
