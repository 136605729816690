(function () {
  angular.module('kmi.lms.core').component('generalFooter', {
    template: require('ajs/modules/app/page-view/general-footer.html').default,
    controllerAs: 'vm',
    controller: GeneralFooterController,
  });

  /* @ngInject */
  function GeneralFooterController(securityService, globalConfig) {
    const vm = this;

    vm.currentDate = new Date();
    vm.appSettings = globalConfig.settings;
    vm.isStateAvailable = securityService.isStateAvailable;
  }
})();
