(function () {
  angular.module('kmi.lms.course.details').component('registrationClosesState', {
    template: require('ajs/modules/course/views/components/state-messages/registration-closes-indicator.html').default,
    controller: RegistrationClosesStateController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function RegistrationClosesStateController(_, moment, courseEnums, courseRegistrationStatus) {
    const vm = this;

    vm.$onInit = onInit;
    vm.format = courseEnums.format;

    function onInit() {
      vm.deadline = vm.course.registrationDeadline && new Date(vm.course.registrationDeadline);

      vm.hasRegistrationDeadline =
        vm.course.courseFormat.registrationRequired &&
        vm.deadline &&
        moment(vm.deadline).diff(moment(), 'weeks') <= 2 &&
        (!_.get(vm.userCourse, 'statusId') ||
          courseRegistrationStatus.withdrawnSet.includes(vm.userCourse.statusId) ||
          courseRegistrationStatus.notStarted === _.get(vm.userCourse, 'statusId')) &&
        (!vm.course.registrationOpensDate || moment(vm.course.registrationOpensDate).diff(moment(), 'minutes') < 0);

      vm.closed = vm.deadline && moment().isAfter(moment(vm.deadline));
    }
  }
})();
