(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.certificates.service:Certificate
   *
   *
   * @description
   * Represents Certificate data model.
   */
  angular.module('kmi.lms.certificates').factory('Certificate', certificateService).run(moduleRun);

  /*@ngInject*/
  function certificateService($resource, uuid) {
    var Certificate = $resource(
      '/a/certificates/:certificateId/',
      { certificateId: '@id' },
      {
        get: {
          method: 'GET',
          transformResponse: transformResponse,
        },
        query: {
          method: 'GET',
          url: '/a/certificates/',
          isArray: false,
        },
        update: { method: 'PUT' },
        clone: {
          method: 'POST',
          params: { clone: true },
          hasBody: false,
        },
      },
      {
        stripTrailingSlashes: false,
      },
    );

    Object.defineProperty(Certificate, 'defaults', {
      get: function () {
        return {
          globalId: uuid.v4(),
          active: false,
          private: true,
        };
      },
    });

    Certificate.newCertificate = function (attrs) {
      return new Certificate(angular.extend(Certificate.defaults, attrs || {}));
    };

    return Certificate;

    function transformResponse(data, headers, status) {
      if (status === 200) {
        return data ? angular.extend(Certificate.defaults, angular.fromJson(data)) : null;
      }
      //When status is not 200 response body may contain error message or some useful data.
      if (headers('Content-Type') === 'application/json') {
        return angular.fromJson(data);
      }
      return data;
    }
  }

  /* @ngInject */
  function moduleRun(entityDataService, $injector) {
    entityDataService.registerLoader('certificate', function (id) {
      var Certificate = $injector.get('Certificate');

      return Certificate.get({ certificateId: id }).$promise;
    });
  }
})();
