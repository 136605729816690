(function () {
  angular.module('kmi.lms.course.registrations').component('courseRegistrationApprovalStatus', {
    bindings: {
      course: '=',
      workflow: '=',
    },
    template: require('ajs/modules/course/registrations/components/registration-approval-status.html').default,
    controller: CourseRegistrationStatusApprovalController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function CourseRegistrationStatusApprovalController($state, currentUser) {
    var vm = this;

    vm.hasContactsState = $state.get('main.contact');
    vm.isAnonymous = currentUser.get().anonymous;
  }
})();
