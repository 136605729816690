(function () {
  angular.module('kmi.lms.course.registrations').component('executionSummaryDialog', {
    bindings: {
      workflow: '=',
    },
    template: require('ajs/modules/course/registrations/components/dialogs/execution-summary.html').default,
    controller: ExecutionSummaryDialogController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function ExecutionSummaryDialogController(rootScopeService, courseRegistrationStatus, courseEnums, $state, _) {
    const vm = this;

    vm.$onInit = onInit;
    vm.close = close;
    vm.locationType = courseEnums.locationType;

    function onInit() {
      let registration = vm.workflow.registration;
      vm.creditTypes = registration.creditTypes || [];
      vm.creditTypes = vm.creditTypes.filter((creditType) => creditType.id);

      vm.workflow.currentStateHandlers.nextHandler = close;
      vm.workflow.currentStateHandlers.nextButtonNameHandler = nextButtonName;

      vm.approvalNotification =
        registration.notification.registration === 'Pending' &&
        registration.course &&
        registration.course.courseFormat.registrationRequired &&
        registration.statusId === courseRegistrationStatus.pending;
    }

    function nextButtonName() {
      const formatId = vm.workflow.registration.course.format || vm.workflow.registration.course.formatId;
      if (formatId === courseEnums.format.conference) {
        return 'Go to sessions';
      }

      return 'Close';
    }

    function close() {
      // clear notifications data for the current action
      vm.workflow.registration.notification.registration = null;
      vm.workflow.registration.notification.collectionRegistered = null;

      // broadcast event about complete
      rootScopeService.broadcast('event:course.registration.action.closeDialogs');
      rootScopeService.broadcast('event:collection:updateComponents');
      // scroll .......
      if (
        vm.workflow.registration.course.format === courseEnums.format.conference ||
        vm.workflow.registration.course.formatId === courseEnums.format.conference
      ) {
        if (!_.startsWith($state.current.name, 'main.course.')) {
          $state.go('main.course', { id: vm.workflow.registration.courseId });
        } else {
          rootScopeService.broadcast('event:collection:scrollToCourse');
        }
      }
    }
  }
})();
