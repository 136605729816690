(function () {
  angular.module('kmi.lms.course.details').component('modernCourseCommonInfo', {
    bindings: {
      course: '=',
      userCourse: '=',
      hideRating: '=?',
    },
    template: require('ajs/modules/course/views/components/modern/common-info.html').default,
    controller: CourseCommonInfoController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function CourseCommonInfoController(
    $scope,
    $state,
    metaService,
    currentUser,
    globalConfig,
    courseDetailsState,
    securityService,
    userSettingsService,
    courseService,
    playCourseService,
    moment,
    LearningObjectRegistration,
    LearningObjectRegistrationWorkflow,
    notificationService,
    _,
  ) {
    var vm = this;

    vm.courseDetailsSettings = globalConfig.settings.courseDetails;

    vm.$onInit = activate;
    vm.copyCourseLink = copyCourseLink;
    vm.playCourseService = playCourseService;
    vm.saveCourse = saveCourse;

    function activate() {
      vm.userSettings = currentUser.get();
      vm.description = vm.course.$getHtmlField('description');
      vm.modernFeatures = courseDetailsState.modernFeaturesEnabled(vm.course);
      vm.heroView = _.get(globalConfig, 'settings.courseDetails.heroImageDetails');
      vm.courseExpired = vm.course.deactivationDate && moment(vm.course.deactivationDate).diff(moment(), 'hours') < 0;
      vm.minRatingCount = _.get(globalConfig, 'settings.courseDetails.minNumberOfReviewsToShowRating', 1);

      vm.networkEnabled =
        userSettingsService.user_network_enabled &&
        securityService.isStateAvailable('main.user_network') &&
        _.get(vm.userSettings, 'userNetwork.profile');
    }

    function copyCourseLink() {
      courseService.copyCourseLink(vm.course.id, 'Copy and share this link to invite other users to take this course');
    }

    function saveCourse() {
      if (vm.userCourse.workflow) {
        addToCurriculum();
      } else {
        LearningObjectRegistration.get(vm.course).then(function (registration) {
          vm.userCourse.workflow = new LearningObjectRegistrationWorkflow(registration);
          addToCurriculum();
        });
      }

      function addToCurriculum() {
        if (vm.userCourse.workflow.hasAction('AddToCurriculum')) {
          vm.userCourse.workflow.exec('AddToCurriculum', {});
        } else {
          notificationService.error('You are unable to save this course', 3e3);
        }
      }
    }
  }
})();
