(function () {
  angular.module('kmi.lms.course.details').component('courseArticleDetails', {
    controller: CourseArticleDetailsController,
    controllerAs: 'vm',
    template: require('ajs/modules/course/views/formats/online/article/article.html').default,
    bindings: {
      course: '=',
    },
  });

  /* @ngInject */
  function CourseArticleDetailsController(CourseMediaTypes, _, currentUser) {
    var vm = this;

    vm.audioPlayerVisible = false;
    vm.$onInit = activate;
    vm.showAudio = showAudio;

    function activate() {
      vm.isAnonymous = currentUser.get().anonymous;

      vm.article = _.find(vm.course.media, function (mediaRecord) {
        return mediaRecord.type === CourseMediaTypes.article;
      });

      vm.audio = _.find(vm.course.media, function (mediaRecord) {
        return mediaRecord.type === CourseMediaTypes.audio;
      });
    }

    function showAudio() {
      vm.audioPlayerVisible = true;
    }
  }
})();
