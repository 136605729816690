(function () {
  angular.module('kmi.lms.course.details').component('courseExamTrackDetails', {
    template: require('ajs/modules/course/views/formats/collection/exam-track/exam-track-details.html').default,
    controller: CourseExamTrackDetailsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function CourseExamTrackDetailsController() {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.course.playable = false;
    }
  }
})();
