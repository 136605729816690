(function () {
  angular.module('kmi.lms.course.details').component('coursePrintableSchedule', {
    template: require('ajs/modules/course/views/printable/course-printable-schedule.html').default,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      user: '=',
    },
  });
})();
