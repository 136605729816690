(function () {
  angular.module('kmi.lms.emails').factory('Email', EmailService);

  /* @ngInject */
  function EmailService($resource, uuid) {
    var Email = $resource(
      '/a/emails/:emailId/',
      { emailId: '@id' },
      {},
      {
        stripTrailingSlashes: false,
      },
    );

    Object.defineProperty(Email, 'defaults', {
      get: function () {
        return {
          globalId: uuid.v4(),
          attachments: [],
        };
      },
    });

    Email.newEmail = function (attrs) {
      return new Email(angular.extend(Email.defaults, attrs || {}));
    };

    return Email;
  }
})();
