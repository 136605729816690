(function () {
  angular.module('kmi.lms.course.details').component('modernCourseDetailedInfo', {
    bindings: {
      course: '=',
      hideNoDataMessage: '=?',
      modern: '=',
      trackingName: '=',
    },
    template: require('ajs/modules/course/views/components/modern/detailed-info.html').default,
    controller: 'CourseDetailedInfoController',
    controllerAs: 'vm',
  });
})();
