(function () {
  angular.module('kmi.lms.core').factory('metaOgpService', MetaOgpService);

  /* @ngInject */
  function MetaOgpService(metaService) {
    var service = {
      ogTitle: metaService.title,
      ogDescription: metaService.description,
      ogUrl: metaService.url,
      ogImage: metaService.image,
      ogType: metaService.type,
    };

    return service;
  }
})();
