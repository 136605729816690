(function () {
  angular.module('kmi.lms.course.registrations').component('manageCourseRegistration', {
    template: require('ajs/modules/course/registrations/components/manage-course-registration.html').default,
    controller: ManageCourseRegistrationController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
      workflow: '=?',
    },
  });

  /* @ngInject */
  function ManageCourseRegistrationController(
    $scope,
    $window,
    globalConfig,
    LearningObjectRegistration,
    LearningObjectRegistrationWorkflow,
    _,
    rootScopeService,
    trainingService,
    moment,
    courseRegistrationStatus,
    serverErrorHandlerService,
    elmsAuthService,
    $state,
    $location,
    currentUser,
    backUrlService,
  ) {
    const vm = this;
    let filterStatuses = [courseRegistrationStatus.pending, courseRegistrationStatus.declined];

    vm.login = login;
    vm.backUrl = backUrlService;
    vm.allowAccountCreation = !!$state.get('prompt.userRegistration');
    vm.hideAnonymousWarnings =
      globalConfig.settings &&
      globalConfig.settings.courseDetails &&
      globalConfig.settings.courseDetails.hideAnonymousWarnings;
    vm.editable = globalConfig.settings.view === 'admin' || globalConfig.projectName === 'ubt';
    vm.registrationExpirationEnabled = globalConfig.settings.registrationExpirationEnabled;
    vm.courseDetailsSettings = globalConfig.settings.courseDetails;
    vm.trainingService = trainingService;
    vm.isAnonymous = currentUser.get().anonymous;
    vm.signInWording = globalConfig.signInWording;

    vm.buyNow = buyNow;
    vm.isExpired = isExpired;
    vm.willExpire = willExpire;
    vm.expireCountdown = expireCountdown;
    vm.regNotDeclined = regNotDeclined;

    vm.$onInit = onInit;

    function onInit() {
      if (vm.course.courseFormat.registrationRequired) {
        if (!vm.workflow) {
          LearningObjectRegistration.get(vm.course, null).then(
            function (registration) {
              vm.registration = registration;
              vm.currentBlsId = $location.search().currentBlsId;
              if (vm.currentBlsId) {
                vm.registration.current_bls_id = vm.currentBlsId;
              }
              vm.workflow = new LearningObjectRegistrationWorkflow(vm.registration);
              vm.userCourse.workflow = vm.workflow;
            },
            function (reason) {
              serverErrorHandlerService.handleForbiddenError(reason);
            },
          );
        }

        var actionFinishedCleanup = $scope.$on('event:course.registration.action.finished', onActionFinished);
        var registrationUpdatedCleanup = $scope.$on('event:course.registration.updated', onRegistrationUpdated);
        $scope.$on('$destroy', onDestroy);
      }

      function onDestroy() {
        actionFinishedCleanup();
        registrationUpdatedCleanup();
      }
    }

    function onActionFinished(event, registration) {
      elmsAuthService.refreshUserLoggedIn().then(function (result) {
        if (!result) {
          $state.go('prompt.login');
        } else {
          onRegistrationUpdated(event, registration);
        }
      });
    }

    function onRegistrationUpdated(event, registration) {
      // Reload current registration if registration on the same course or child course has been updated.
      if (
        vm.registration &&
        registration &&
        ((registration.courseId === vm.registration.courseId && registration !== vm.registration) ||
          registration.id !== vm.registration.id)
      ) {
        vm.registration.reload();
      }
    }

    function buyNow() {
      $state.go('main.checkout', { courseId: vm.course.id });
    }

    function willExpire() {
      return vm.userCourse && vm.userCourse.accessExpirationDate && regNotDeclined();
    }

    function expireCountdown() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return null;
      }

      return moment(vm.userCourse.accessExpirationDate).fromNow();
    }

    function isExpired() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return false;
      }
      return regNotDeclined() && moment(vm.userCourse.accessExpirationDate).diff(moment(), 'seconds') <= 0;
    }

    function regNotDeclined() {
      return filterStatuses.indexOf(vm.userCourse.statusId) === -1;
    }

    function login() {
      rootScopeService.broadcast('event:auth-loginRequired');
    }
  }
})();
