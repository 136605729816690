(function () {
  angular.module('kmi.lms.course.details').component('accreditationStatementsModal', {
    template: require('ajs/modules/course/views/components/modal/accreditation-statements-modal.html').default,
    controller: AccreditationStatementsModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  function AccreditationStatementsModalController() {
    const vm = this;
    vm.close = close;
    vm.$onInit = onInit;

    function onInit(): void {
      vm.course = vm.resolve.course;
      vm.accreditationStatements = vm.resolve.accreditationStatements;
    }

    function close(): void {
      vm.modalInstance.close();
    }
  }
})();
