(function () {
  angular.module('kmi.lms.course.details').factory('courseStateMessagesService', courseStateMessagesService);

  /* @ngInject */
  function courseStateMessagesService(moment, _, courseRegistrationStatus) {
    return {
      getMessages: getMessages,
    };

    function getMessages(course, userCourse) {
      const courseExpired = !!course.deactivationDate && moment(course.deactivationDate).diff(moment(), 'minutes') < 0;
      const userCourseExpired =
        _.get(userCourse, 'statusId') && userCourse.statusId === courseRegistrationStatus.expired;
      const registrationIsNotOpen =
        !!course.registrationOpensDate && moment(course.registrationOpensDate).diff(moment(), 'minutes') >= 0;
      const unpublished = !!course.publishDate && moment(course.publishDate).diff(moment(), 'minutes') >= 0;

      return [
        {
          restriction: () => {
            return (
              (!userCourseExpired || !course.courseFormat.registrationRequired) &&
              !!course.deactivationDate &&
              moment(course.deactivationDate).diff(moment(), 'weeks') <= 2
            );
          },
          template:
            '<course-expiration-date-state course="vm.course" expires-soon-indicator="true"></course-expiration-date-state>',
        },
        {
          restriction: () => {
            return course.courseFormat.registrationRequired && registrationIsNotOpen;
          },
          template: '<registration-opens-state course="vm.course"></registration-opens-state>',
        },
        {
          restriction: () => {
            let deadline = course.registrationDeadline && new Date(course.registrationDeadline);

            return (
              userCourse &&
              !courseExpired &&
              !userCourseExpired &&
              course.courseFormat.registrationRequired &&
              !!deadline &&
              moment(deadline).diff(moment(), 'weeks') <= 2 &&
              (!_.get(userCourse, 'statusId') ||
                courseRegistrationStatus.withdrawnSet.includes(_.get(userCourse, 'statusId')) ||
                courseRegistrationStatus.notStarted === _.get(userCourse, 'statusId')) &&
              !registrationIsNotOpen &&
              !unpublished
            );
          },
          template:
            '<registration-closes-state course="vm.course" user-course="vm.userCourse"></registration-closes-state>',
        },
        {
          restriction: () => {
            return (
              _.get(userCourse, 'lastRegistration.statusId') === 9 && _.get(userCourse, 'assessmentIntendedFor') === 2
            );
          },
          template:
            '<course-assessment-intended-for-state course="vm.course" user-course="vm.userCourse"></course-assessment-intended-for-state>',
        },
        {
          restriction: () => {
            return (
              course.courseFormat.registrationRequired &&
              !!_.get(userCourse, 'lastRegistration.targetCompletionDate') &&
              !courseRegistrationStatus.declinedSet.includes(_.get(userCourse, 'lastRegistration.statusId'))
            );
          },
          template:
            '<course-registration-target-date-state course="vm.course" user-course="vm.userCourse"></course-registration-target-date-state>',
        },
        {
          restriction: () => {
            return (
              course.courseFormat.registrationRequired &&
              !!_.get(userCourse, 'lastRegistration.dueDate') &&
              !courseRegistrationStatus.declinedSet.includes(_.get(userCourse, 'lastRegistration.statusId'))
            );
          },
          template:
            '<course-registration-due-date-state course="vm.course" user-course="vm.userCourse"></course-registration-due-date-state>',
        },
        {
          restriction: () => {
            return (
              course.allowRegisterOnce && courseRegistrationStatus.completedSet.includes(_.get(userCourse, 'statusId'))
            );
          },
          template:
            '<course-registration-register-once course="vm.course" user-course="vm.userCourse"></course-registration-register-once>',
        },
        {
          restriction: () => {
            return unpublished;
          },
          template:
            '<span>Course will be published <date-time date="vm.course.publishDate"></date-time>. Put this on your calendar!</span>',
        },
      ];
    }
  }
})();
