(function () {
  angular.module('kmi.lms.core').factory('customTitleService', CustomTitleService);

  /* @ngInject */
  function CustomTitleService(metaService, globalConfig) {
    var _base;

    var service = {
      base: base,
      pageTitle: pageTitle,
    };

    return service;

    //////////

    function base(siteSettings) {
      if (globalConfig.projectName === 'train') {
        _base = [
          siteSettings.siteShortName,
          siteSettings.default ? globalConfig.title.nationalSuffix : globalConfig.title.affiliateSuffix,
          globalConfig.title.end,
        ].join('');
      } else {
        _base = siteSettings.pageTitle;
      }

      return service;
    }

    function pageTitle() {
      var term = metaService.title(),
        divider = _base ? ' - ' : '';

      return [term ? [term, divider].join('') : '', _base].join('');
    }
  }
})();
