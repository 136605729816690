(function () {
  angular.module('kmi.lms.course.common').factory('CoursePurchase', CoursePurchaseService);

  /* @ngInject */
  function CoursePurchaseService($http) {
    return {
      getHistory: getHistory,
    };

    function getHistory(courseId, userId, cacheByPass) {
      var config = { params: { userId: userId } };
      if (cacheByPass) {
        config.cacheByPass = cacheByPass;
      }
      return $http.get('/a/course/' + courseId + '/purchase/history/', config);
    }
  }
})();
