(function () {
  angular.module('kmi.lms.core').factory('httpRequestStatusService', function ($q, notificationService, _) {
    var requestCount = 0;
    var errorCount = 0;

    var updateStatus = function () {
      if (requestCount === 0) {
        if (errorCount > 0) {
          errorCount = 0;
          notificationService.error('The remote server is not available at this time. Try again later.', 10000);
        }
      }
    };

    function success() {
      requestCount--;
      updateStatus();
    }

    function error(rejection) {
      requestCount--;

      //401 - Should be covered bt rootScope event handler
      //403 - Should be handled by controller which requested WS call
      //404 - Should be handled by controller which requested WS call
      if (
        !(rejection.status <= 0 && rejection.config.timeout) &&
        !_.includes([401 /*Unauthorized*/, 403 /*Forbidden*/, 404 /*Not Found*/], rejection.status)
      ) {
        // rejection.status == -1 when all requests canceled - reload page while loading or click on a link with href
        // in ff was shown error notification when reload or link click while xhr requests havent finish
        if (rejection.status !== -1) {
          errorCount++;
        }
      }

      updateStatus();
    }

    return {
      request: function (config) {
        requestCount++;

        return config || $q.when(config);
      },
      response: function (response) {
        success();

        return response || $q.when(response);
      },
      responseError: function (rejection) {
        error(rejection);

        return $q.reject(rejection);
      },
    };
  });
})();
