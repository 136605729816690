(function () {
  angular
    .module('kmi.lms.courseProviders', ['kmi.lms.core', 'ngResource', 'kmi.lms.search.common'])
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.provider_organization', {
        url: '^/organizations/{id:int}',
        params: {
          id: null,
        },
        component: 'courseProviderOrganizationComponent',
        resolve: {
          organization: function ($transition$, CourseProviderOrganization) {
            return CourseProviderOrganization.get({ organizationId: $transition$.params().id }).$promise;
          },
        },
        data: {
          label: 'Organization',
          availableFor: ['regularUser', 'admin'],
          adminRoleLimits: [1, 14, 50, 2340],
        },
      })
      .state('edit.provider_survey', {
        url: '/course-provider-survey/{id:int}',
        component: 'editCourseProviderSurveyComponent',
        data: {
          label: 'Survey',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: {
          provider: function ($transition$, CourseProvider) {
            if ($transition$.params().courseProvider) {
              if ($transition$.params().courseProvider instanceof CourseProvider) {
                return $transition$.params().courseProvider;
              } else if ($transition$.params().courseProvider.id) {
                return new CourseProvider($transition$.params().courseProvider);
              }
            } else if ($transition$.params().id) {
              return CourseProvider.get({ providerId: $transition$.params().id }).$promise;
            }
            return null;
          },
          targetPage: function () {
            return 'edit.account';
          },
        },
        params: {
          courseProvider: { value: null },
          id: { value: null },
        },
      });
  }
})();
