(function () {
  angular.module('kmi.lms.course.common').factory('sessionLocation', SessionLocationService);

  /* @ngInject */
  function SessionLocationService(_) {
    return {
      getAddress: function (location) {
        return _.filter([location.city, location.streetAddress, location.building, location.room]).join(', ');
      },
      getFullInfo: function (location) {
        return _.filter([location.streetAddress, location.zip, location.building, location.room]).join(', ');
      },
      getMapLink: function (location) {
        var fullAddress =
          location.streetAddress +
          ', ' +
          location.city +
          ', ' +
          (location.state.name || location.state) +
          ' ' +
          location.zip +
          ', ' +
          (location.country.name || location.country);
        return ['http://maps.google.com/maps?f=q&q=', fullAddress.replace(/ /g, '+'), '&z=13'].join('');
      },
    };
  }
})();
