(function () {
  angular.module('kmi.lms.course.registrations').component('enrollmentSessionConfirmationModal', {
    template: require('ajs/modules/course/registrations/bulk-enrollment/modal/enrollment-session-confirmation.html')
      .default,
    controller: EnrollmentSessionConfirmationModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  function EnrollmentSessionConfirmationModalController(
    $scope,
    $interval,
    courseBulkEnrollmentService,
    CourseBulkEnrollmentSession,
    serverErrorHandlerService,
    courseEnums,
  ) {
    const vm = this;

    vm.cancel = cancel;
    vm.register = register;
    vm.$onInit = onInit;
    vm.locationType = courseEnums.locationType;

    function onInit(): void {
      vm.options = vm.resolve.options;
      vm.title = vm.resolve.title;
      vm.enrollmentSession = CourseBulkEnrollmentSession.newSession(vm.resolve.enrollmentData);
    }

    function register(selectedLocationTypeId) {
      let locationTypeId = vm.options.locationTypeId;
      // Apply location if it needs
      if (selectedLocationTypeId) {
        locationTypeId = selectedLocationTypeId;
      }
      vm.enrollmentSession.courses.forEach((course) => {
        course.locationTypeId = locationTypeId;
      });

      // save enrollment
      vm.loading = vm.enrollmentSession
        .$save()
        .then(() => {
          vm.modalInstance.close(vm.enrollmentSession);
        }, serverErrorHandlerService.handleForbiddenError)
        .finally(() => {
          vm.loading = null;
        });
    }

    function cancel(): void {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
