(function () {
  angular.module('kmi.lms.course.registrations').component('registrationApprovalStatusComponent', {
    controller: RegistrationApprovalStatusController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
    template: function ($attrs) {
      var approvalStatusTemplate;

      if ($attrs.registration && $attrs.registration.course && $attrs.registration.course.price) {
        approvalStatusTemplate =
          require('ajs/modules/course/registrations/components/modal/payment-approval-status.html').default;
      } else {
        approvalStatusTemplate =
          require('ajs/modules/course/registrations/components/modal/registration-approval-status.html').default;
      }

      return approvalStatusTemplate;
    },
  });

  /* @ngInject */
  function RegistrationApprovalStatusController($state) {
    var vm = this;
    vm.$onInit = onInit;

    vm.closeModal = closeModal;
    vm.hasContactsState = $state.get('main.contact');

    function onInit() {
      vm.registration = vm.resolve.registration;
    }

    function closeModal() {
      vm.modalInstance.close();
    }
  }
})();
