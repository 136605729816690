(function () {
  angular.module('kmi.lms.course.details').component('courseSessionDetails', {
    template: require('ajs/modules/course/views/formats/live-event/components/session-details.html').default,
    controller: CourseSessionController,
    controllerAs: 'vm',
    bindings: {
      session: '=',
      workflow: '=',
      showExpired: '=',
      highlightedItem: '=',
      showCompleted: '=?',
      course: '=',
    },
  });

  /* @ngInject */
  function CourseSessionController(
    _,
    globalConfig,
    $scope,
    sessionLocation,
    moment,
    eventExportService,
    $interval,
    courseRegistrationStatus,
    $uibModal,
    $window,
    Instructor,
  ) {
    var vm = this,
      timer,
      starts;
    vm.timeBeforeTimeout = null;
    vm.enrolledLearnersVisible = false;
    vm.serverTimeZone = $window.elmsEnvironment.serverTimeZone;

    vm.getMapUrl = sessionLocation.getMapLink; // Generates Google maps url
    vm.addToCalendar = addToCalendar;
    vm.toggleContent = toggleContent;
    vm.isRegistered = isRegistered;
    vm.isCompleted = isCompleted;
    vm.isRegistrationExpired = isRegistrationExpired;
    vm.viewInstructor = viewInstructor;

    vm.$onInit = activate;

    function activate() {
      initSchedulesByDates();
      enrolledLearnersVisible();

      // Collect all locations. Set main location if it's only one
      var locations = _.uniqBy(vm.session.schedules, 'locationId');
      if (locations.length === 1) {
        vm.mainLocation = locations[0];
      }

      if (vm.session.deadline) {
        vm.deadlineExpired = moment.duration(moment(vm.session.deadline) - moment()) > 0;
      }

      $scope.$watch('vm.showExpired', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          initSchedulesByDates();
        }
      });

      $scope.$watch('vm.workflow.registration.id', function () {
        vm.session.isOpen = _.get(vm, 'workflow.registration.id') && vm.isRegistered();

        if (
          !starts &&
          !vm.session.expired &&
          ((vm.isRegistered() && !vm.isCompleted()) || vm.session.status_id === 7)
        ) {
          initStarts();
        }
      });

      if (!starts && !vm.session.expired && ((vm.isRegistered() && !vm.isCompleted()) || vm.session.status_id === 7)) {
        initStarts();
      }
    }

    function enrolledLearnersVisible() {
      vm.enrolledLearnersVisible =
        _.get(globalConfig.settings, 'courseDetails.sessions.enrolledLearnersVisible', false) &&
        _.get($window.elmsEnvironment, 'userSettings.enable_course_enrolled_learners', false);
    }

    function initStarts() {
      vm.startDateTime = vm.session.start_date;

      // this countdown timer may be realized as separate service or directive
      $scope.$watch('vm.timeBeforeTimeout', function (newValue) {
        if (timer) {
          $interval.cancel(timer);
        }
        if (newValue) {
          timer = $interval(function () {
            startsIn();
          }, newValue * 1e3);
        }
      });
      startsIn();
      starts = true;
    }

    function initSchedulesByDates() {
      // Group schedules by date and location
      vm.sessionDates = prepareSchedules();
    }

    function prepareSchedules() {
      // Group schedules by date, filter expired
      var daySchedules = _.groupBy(
        _.filter(vm.session.schedules, function (sc) {
          return !sc.expired || (sc.expired && vm.showExpired);
        }),
        function (schedule) {
          return moment(schedule.start_date).startOf('day').format('YYYY-MM-DD');
        },
      );

      var schedules = [];
      // group schedules by location for each date
      for (var day in daySchedules) {
        if (daySchedules.hasOwnProperty(day)) {
          schedules.push({
            date: moment(day),
            schedules: _.sortBy(daySchedules[day], 'start_date'),
          });
        }
      }

      return _.sortBy(schedules, function (s) {
        return s.date.format('YYYY-MM-DD');
      });
    }

    /**
     * @description
     * Toggles session detailed info
     */
    function toggleContent() {
      vm.session.isOpen = !vm.session.isOpen;
    }

    /**
     * @description
     * Checks whether user registered session is completed
     * @returns {*|boolean}
     */
    function isCompleted() {
      return vm.session.status_id && _.includes([5, 40, 41], vm.session.status_id);
    }

    /**
     * @description
     * Checks whether user registration is expired
     * @returns {*|boolean}
     */
    function isRegistrationExpired() {
      return vm.session.status_id && courseRegistrationStatus.expired === vm.session.status_id;
    }

    /**
     * @description
     * Checks whether user is registered on session
     * @returns {*|boolean}
     */
    function isRegistered() {
      return (
        vm.session.status_id &&
        vm.session.status_id !== 6 &&
        _.get(vm, 'workflow.registration.session_id') &&
        vm.workflow.registration.session_id === vm.session.id
      );
    }

    /**
     * @description
     * Opens dialog to export event to calendar
     * @param schedule
     */
    function addToCalendar(schedule) {
      eventExportService.addToCalendarDialog(vm.workflow.registration, schedule);
    }

    // this countdown timer may be realized as separate service or directive
    function startsIn() {
      var duration = moment.duration(moment(vm.session.startDateTime) - moment()),
        secondsBefore = duration / 1000;

      if (secondsBefore > 0) {
        if (secondsBefore < 14400) {
          // < 4 hours
          vm.timeBeforeFormat = 'y[y] M[m] d[d] h[h] m[m]';
          vm.timeBeforeTimeout = 60; // 1 minutes
        } else if (secondsBefore < 864e2) {
          // < 1 day
          vm.timeBeforeFormat = 'y[y] M[m] d[d] h[h]';
          vm.timeBeforeTimeout = 3600; // 1 hour
        } else if (secondsBefore < 864e3) {
          // < 10 days
          vm.timeBeforeFormat = 'y[y] M[m] d[d]';
          vm.timeBeforeTimeout = 864e2; // 1 day
        } else if (secondsBefore < 7776e3) {
          // < ≈3 month
          vm.timeBeforeFormat = 'y[y] M[m] d[d]';
          vm.timeBeforeTimeout = null;
        } else {
          // > ≈3 month
          vm.timeBeforeFormat = 'y[y] M[m]';
          vm.timeBeforeTimeout = null;
        }
        vm.timeRemaining = duration.format(vm.timeBeforeFormat);
      } else {
        vm.timeRemaining = null;
        vm.timeBeforeTimeout = null;
      }
    }

    function viewInstructor(instructor) {
      Instructor.showInformation(instructor);
    }
  }
})();
