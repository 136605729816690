(function () {
  angular.module('kmi.lms.course.details').component('courseSpecificFormatDetails', {
    template: require('ajs/modules/course/views/formats/course-specific-format-details.html').default,
    controller: CourseSpecificFormatDetailsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function CourseSpecificFormatDetailsController($scope, scrollService) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      scrollService.waitForScroll($scope);
    }
  }
})();
