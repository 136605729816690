(function () {
  angular.module('kmi.lms.coursePresenters').factory('coursePresenterService', coursePresenterService);

  /* @ngInject */
  function coursePresenterService($http) {
    return {
      getDictionaries: getDictionaries,
    };

    function getDictionaries() {
      return $http.get('/a/course/presenters/dictionaries/', { cache: true }).then((response) => {
        return response.data;
      });
    }
  }
})();
