(function () {
  angular.module('kmi.lms.course.purchase').directive('coursePurchaseHistory', coursePurchaseHistory);

  function coursePurchaseHistory() {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        purchaseHistory: '=purchaseHistory',
        course: '=course',
      },
      template: require('ajs/modules/course/purchase/history/history.html').default,
      controller: CoursePurchaseHistoryCtrl,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /* @ngInject */
  function CoursePurchaseHistoryCtrl(numberFilter, globalConfig) {
    var vm = this;

    vm.contacts = globalConfig.contacts;
    vm.formatPrice = formatPrice;

    function formatPrice(purchase) {
      var value = purchase.price;

      if (purchase.discount) {
        value = purchase.price - purchase.discount;
      }

      if (value && !isNaN(value)) {
        var price = numberFilter(value);
        return '$' + price;
      }

      return value || 'Free';
    }
  }
})();
