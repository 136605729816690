(function () {
  angular.module('kmi.lms.core').service('userSettingsService', userSettingsService);

  /* @ngInject */
  function userSettingsService($window, currentUser, _) {
    return angular.extend(
      {
        isNotImpersonated: !_.get($window, 'elmsEnvironment.impersonatedByUser') && !currentUser.get().anonymous,
      },
      _.get($window, 'elmsEnvironment.userSettings'),
    );
  }
})();
