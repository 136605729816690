(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.user.common.service:userGroupBrandingService
   * @description
   * This service is used to get user group branding options
   *
   */

  angular.module('kmi.lms.core').factory('userGroupBrandingService', userGroupBrandingService);

  /* @ngInject */
  function userGroupBrandingService($http, $q) {
    var brandingData;

    return {
      getInfo: getInfo,
    };

    /**
     * @description
     * Retrieves user group branding options.
     * @returns {IPromise<TResult>|*}
     */
    function getInfo(userId) {
      if (brandingData) {
        return $q.resolve(brandingData);
      }

      return $http
        .get(`/a/user/${userId}/group_settings/`, {
          cache: true,
        })
        .then(function (response) {
          brandingData = response.data;
          return brandingData;
        });
    }
  }
})();
