(function () {
  angular.module('kmi.lms.coursePresenters').factory('CoursePresenter', coursePresenterService);

  /* @ngInject */
  function coursePresenterService($resource, $cacheFactory, uuid) {
    let CoursePresenter = $resource(
      '/a/course/:courseId/presenters/:presenterId/',
      { courseId: '@courseId', presenterId: '@id' },
      {
        get: {
          method: 'GET',
          transformResponse: transformResponse,
        },
        query: {
          method: 'GET',
          params: { courseId: '@courseId' },
          url: '/a/course/:courseId/presenters/',
          isArray: true,
        },
        update: {
          method: 'PUT',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        save: {
          method: 'POST',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        delete: {
          method: 'DELETE',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        saveBulk: {
          method: 'POST',
          isArray: true,
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
      },
      {
        stripTrailingSlashes: false,
      },
    );

    function clearCache() {
      let cache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');
      cache.removeAll();
    }

    function transformResponse(data, headers, status) {
      if (status === 200) {
        return data ? angular.extend(CoursePresenter.defaults, angular.fromJson(data)) : null;
      }

      //When status is not 200 response body may contain error message or some useful data.
      if (headers('Content-Type') === 'application/json') {
        return angular.fromJson(data);
      }
      return data;
    }

    Object.defineProperty(CoursePresenter, 'defaults', {
      get: function () {
        return {
          globalId: uuid.v4(),
        };
      },
    });

    CoursePresenter.newPresenter = function (data) {
      return new CoursePresenter(angular.extend(CoursePresenter.defaults, angular.fromJson(data)));
    };

    return CoursePresenter;
  }
})();
