(function () {
  angular
    .module('kmi.lms.course.purchase', [
      'ui.router',
      'http-auth-interceptor',
      'kmi.lms.core',
      'kmi.lms.components',
      'kmi.lms.course.common',
      'kmi.lms.course.registrations',
      'kmi.lms.integration',
      'kmi.lms.ecommerce',
    ])
    .config(function ($stateProvider) {
      $stateProvider
        .state('main.coursePurchase', {
          abstract: true,
          url: '/course/{courseId}/purchase',
          params: {
            courseId: null,
          },
          component: 'coursePurchaseComponent',
          data: {
            label: 'Purchase',
            availableFor: ['anonymous', 'regularUser', 'admin'],
          },
          resolve: {
            course: function (Course, $transition$) {
              return Course.get({
                courseId: $transition$.params().courseId,
                cacheByPass: true,
              }).$promise;
            },
            purchaseWizardService: function (PurchaseWizard, currentUser) {
              var steps = [];
              steps.push({
                icon: 'fa-cart-shopping',
                title: 'Cart',
                state: 'main.coursePurchase.cart',
              });

              if (currentUser.get().anonymous) {
                steps.push({
                  icon: 'fa-user',
                  title: 'Create Account',
                  state: 'main.coursePurchase.account',
                });
              }

              steps.push({
                icon: 'fa-dollar-sign',
                title: 'Payment',
                state: 'main.coursePurchase.payment',
              });

              steps.push({
                icon: 'fa-check',
                title: 'Confirmation',
                state: 'main.coursePurchase.result',
              });

              return new PurchaseWizard({
                steps: steps,
              });
            },
          },
        })
        .state('main.coursePurchase.cart', {
          url: '/cart',
          data: {
            label: 'Purchase',
            availableFor: ['anonymous', 'regularUser', 'admin'],
          },
          component: 'coursePurchaseCartComponent',
        })
        .state('main.coursePurchase.account', {
          url: '/account',
          data: {
            label: 'Purchase',
            availableFor: ['anonymous', 'regularUser', 'admin'],
          },
          component: 'coursePurchaseCreateAccountComponent',
          resolve: {
            currentUser: function (User) {
              return User.get();
            },
          },
        })
        .state('main.coursePurchase.payment', {
          url: '/payment',
          data: {
            label: 'Purchase',
            availableFor: ['regularUser', 'admin'],
          },
          component: 'coursePurchasePaymentComponent',
        })
        .state('main.coursePurchase.result', {
          url: '/{purchaseId}/result',
          params: {
            purchaseId: null,
          },
          data: {
            label: 'Purchase',
            availableFor: ['regularUser', 'admin'],
          },
          component: 'coursePurchaseResultComponent',
          resolve: {
            user: function (User) {
              return User.get();
            },
            purchase: function ($transition$, Purchase, purchaseWizardService) {
              return Purchase.get({ purchaseId: $transition$.params().purchaseId }, function (purchase) {
                purchaseWizardService.purchase = purchase;
              }).$promise;
            },
          },
        })
        .state('mainPrint.coursePurchaseReceipt', {
          url: '/course/{courseId}/user/{userId}/receipt',
          params: {
            courseId: null,
            userId: null,
          },
          data: {
            label: 'Purchase',
            availableFor: ['regularUser', 'admin'],
          },
          component: 'courseReceiptComponent',
          resolve: {
            user: function ($transition$, User) {
              return User.get(parseInt($transition$.params().userId, 10), { permitted_for: 'courseRegistration.view' });
            },
            course: function ($transition$, Course) {
              return Course.get({ courseId: $transition$.params().courseId }).$promise;
            },
          },
        });
    });
})();
