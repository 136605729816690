(function () {
  angular.module('kmi.lms.course.details').component('articleContent', {
    controller: ArticleContentController,
    controllerAs: 'vm',
    template: require('ajs/modules/course/views/formats/online/article/article-content.component.html').default,
    bindings: {
      course: '=',
    },
  });

  /* @ngInject */
  function ArticleContentController(CourseMediaTypes, _, currentUser) {
    var vm = this;

    vm.$onInit = activate;

    function activate() {
      vm.isAnonymous = currentUser.get().anonymous;

      vm.article = _.find(vm.course.media, function (mediaRecord) {
        return mediaRecord.type === CourseMediaTypes.article;
      });
    }
  }
})();
