(function () {
  angular.module('kmi.lms.course.details').component('courseArticleAudio', {
    controller: CourseArticleAudioController,
    controllerAs: 'vm',
    template: require('ajs/modules/course/views/formats/online/article/article-audio.component.html').default,
    bindings: {
      course: '=',
    },
  });

  /* @ngInject */
  function CourseArticleAudioController(CourseMediaTypes, _, currentUser) {
    var vm = this;

    vm.audioPlayerVisible = false;
    vm.$onInit = activate;
    vm.showAudio = showAudio;

    function activate() {
      vm.isAnonymous = currentUser.get().anonymous;

      vm.audio = _.find(vm.course.media, function (mediaRecord) {
        return mediaRecord.type === CourseMediaTypes.audio;
      });
    }

    function showAudio() {
      vm.audioPlayerVisible = true;
    }
  }
})();
