(function () {
  angular.module('kmi.lms.course.registrations').component('registrationVerificationIndicator', {
    bindings: {
      regData: '=',
      hideDeclined: '<?',
      showHightStatus: '<?',
      includeTerminalStatuses: '<?',
    },
    template: require('ajs/modules/course/registrations/components/registration-verification-indicator.html').default,
    controller: RegistrationVerificationIndicatorController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function RegistrationVerificationIndicatorController($scope, globalConfig, courseRegistrationStatus, _) {
    let vm = this;
    vm.$onInit = onInit;
    vm.verificationEnabled = _.get(globalConfig, 'courseRegistration.verificationEnabled', false);

    function onInit() {
      defineCurrentStatus();

      $scope.$watchGroup(
        [
          'vm.regData',
          'vm.regData.status_id',
          'vm.regData.statusId',
          'vm.regData.course_status_id',
          'vm.regData.lastRegistration.statusId',
          'vm.regData.status',
          'vm.regData.verified',
          'vm.regData.lastRegistration.verified',
        ],
        function () {
          defineCurrentStatus();
        },
      );
    }

    function defineCurrentStatus() {
      let statusesSet = courseRegistrationStatus.completedSet;
      let registrationStatusId = _.get(vm.regData, 'statusId') || _.get(vm.regData, 'status_id');
      let lastRegistration = _.get(vm.regData, 'lastRegistration');

      if (!vm.showHightStatus && _.get(lastRegistration, 'statusId')) {
        vm.verified = lastRegistration.verified;
        vm.currentStatusId = lastRegistration.statusId;
      } else if (registrationStatusId) {
        vm.verified = vm.regData.verified || false;
        vm.currentStatusId = registrationStatusId;

        statusesSet = vm.includeTerminalStatuses ? courseRegistrationStatus.terminalSet : statusesSet;
      } else {
        vm.verified = false;
        vm.currentStatusId = courseRegistrationStatus.notStarted;
      }

      if (vm.hideDeclined && _.includes(courseRegistrationStatus.declinedSet, vm.currentStatusId)) {
        vm.verified = _.get(vm.regData, 'lastCompletedRegistration.verified', false);
        vm.currentStatusId = _.get(vm.regData, 'lastCompletedRegistration.statusId', null);
      }

      vm.showIndicator = _.includes(statusesSet, vm.currentStatusId);
    }
  }
})();
