(function () {
  angular.module('kmi.lms.course.details').component('courseConferenceDetails', {
    template: require('ajs/modules/course/views/formats/collection/conference/conference-details.html').default,
    controller: CourseConferenceDetailsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
      onlyRegisteredSessions: '<?',
      readonlyData: '<?',
    },
  });

  /* @ngInject */
  function CourseConferenceDetailsController(
    collectionsService,
    currentUser,
    $q,
    CollectionComponent,
    Course,
    moment,
    courseComponentsService,
    _,
    $scope,
    $timeout,
    rootScopeService,
    courseRegistrationStatus,
    conferenceDetailsService,
    CoursePresenter,
    $location,
    $uibModal,
    courseEnums,
  ) {
    const vm = this;

    vm.registrations = {};
    vm.status = courseRegistrationStatus;
    vm.isRegistered = conferenceDetailsService.isRegistered;
    vm.isCompleted = conferenceDetailsService.isCompleted;
    vm.selectTrack = selectTrack;
    vm.applySearch = applySearch;
    vm.registerSessions = registerSessions;
    vm.$onInit = onInit;
    vm.locationType = courseEnums.locationType;

    function onInit() {
      vm.loading = true;

      let search = $location.search();
      if (search.track) {
        vm.selectedTrack = search.track;
      }

      $scope.$watch(
        function () {
          return $location.search();
        },
        function () {
          if (vm.conferenceDescendants) {
            buildSessionsDatesWithFilter();
          }
        },
      );

      $q.all([loadSessions(), loadRegistrations()])
        .then(() => {
          if (!_.isEmpty(vm.registrations)) {
            return loadCertificates();
          }
        })
        .finally(() => {
          vm.loading = null;
          if ($location.hash()) {
            $timeout(function () {
              rootScopeService.broadcast('event:scrollTo', $location.hash(), 'center');
            });
          }
        });

      $scope.$on('event:collection:scrollToCourse', function () {
        // TODO implement logic to scroll for first needed sessions(We need to determine what sessions is need)
        $timeout(function () {
          rootScopeService.broadcast('event:scrollTo', 'sessionsList');
        });
      });

      $scope.$on('event:collection:updateComponents', function () {
        loadRegistrations();
      });

      $scope.$on('event:course.registration.updated', function () {
        loadRegistrations();
      });
    }

    function loadRegistrations() {
      if (currentUser.get().anonymous) {
        return $q.resolve();
      }

      return courseComponentsService.getCollectionComponentsRegistrations(vm.course.id).then((registrations) => {
        // Some data changed, actions need to be reloaded if needed
        rootScopeService.broadcast('event:course.registration.updatedAll');
        _.forEach(registrations, (registration) => {
          vm.registrations[registration.courseId] = registration;
        });
      });
    }

    function registerSessions() {
      let modalInstanceConfirmation = $uibModal.open({
        component: 'enrollmentSessionConfirmationModal',
        backdrop: 'static',
        resolve: {
          enrollmentData: function () {
            return {
              courses: _.map(vm.sessionsByTracks, (session) => {
                return {
                  courseId: session.course.id,
                  course: session.course,
                };
              }),
              users: [vm.userCourse.userId],
              selfRegister: true,
            };
          },
          options: function () {
            return {
              title: 'Register by track',
              text: 'Register for all sessions in track?',
              selectAttendance: vm.course.courseLocation.typeId === vm.locationType.inPersonAndVirtual,
              locationTypeId: vm.course.courseLocation.typeId,
              selectCredits: false,
              enterCode: false,
            };
          },
        },
      });

      modalInstanceConfirmation.result.then((enrollmentSession) => {
        let modalInstanceResult = $uibModal.open({
          component: 'enrollmentSessionResultModal',
          size: 'lg',
          backdrop: 'static',
          resolve: {
            enrollmentSession: function () {
              return enrollmentSession;
            },
            showDetails: function () {
              return true;
            },
          },
        });

        modalInstanceResult.result.then(() => {
          // Some registrations for sessions can be updated. trigger refresh registrations show more actual data
          rootScopeService.broadcast('event:course.registration.updatedAll');
          rootScopeService.broadcast('event:collection:updateComponents');
        });
      });
    }

    function loadSessions() {
      return $q
        .all([
          CollectionComponent.query({ courseId: vm.course.id }).$promise,
          courseComponentsService.getComponentsCertificates(vm.course.id),
          courseComponentsService.getComponentsCredits(vm.course.id),
          CoursePresenter.query({ courseId: vm.course.id }).$promise,
        ])
        .then(function (results) {
          processSessions(results[0], results[1], results[2], results[3]);
        });
    }

    function loadCertificates() {
      if (currentUser.get().anonymous) {
        return $q.resolve();
      }

      return collectionsService.getComponentsEarnedCertificates(vm.course.id).then((certificates) => {
        const certs = _.keyBy(certificates, 'registrationId');

        for (const key in vm.registrations) {
          const lastRegId = _.get(vm.registrations[key], 'lastCompletedRegistration.id');

          if (lastRegId && certs[lastRegId]) {
            vm.registrations[key].certificates = certs[lastRegId].certificates;
          }
        }
      });
    }

    function processSessions(componentsResponse, certificatesResponse, creditsResponse, presentersResponse) {
      let creditTypes = {};
      creditsResponse.forEach((creditType) => {
        creditTypes[creditType.courseId] = creditTypes[creditType.courseId] || [];
        creditTypes[creditType.courseId].push(creditType);
      });

      let certificates = {};
      certificatesResponse.forEach((certificate) => {
        certificates[certificate.courseId] = certificates[certificate.courseId] || [];
        certificates[certificate.courseId].push(certificate);
      });

      let presenters = _.keyBy(presentersResponse, 'id');
      vm.conferenceDescendants = componentsResponse.map((component) => {
        component.course = new Course(component.course);
        component.course.certificates = certificates[component.course.id] || [];
        component.course.creditTypes = creditTypes[component.course.id] || [];
        component.course.presenters = _.map(component.presenters, (presenter) => {
          return presenters[presenter.presenterId];
        });
        // Init user courses
        if (!vm.registrations[component.course.id]) {
          vm.registrations[component.course.id] = {};
        }
        return component;
      });

      vm.sessionsTracks = _.sortBy(
        _.uniqBy(_.map(_.flatten(_.map(vm.conferenceDescendants, 'tracks')), 'track'), 'id'),
        'name',
      );
      if (
        vm.selectedTrack &&
        !_.filter(vm.sessionsTracks, (track) => {
          return track.name === vm.selectedTrack;
        }).length
      ) {
        vm.selectedTrack = null;
      }

      buildSessionsDatesWithFilter();
    }

    function filterSessions(session) {
      if (!vm.onlyRegisteredSessions) {
        return true;
      }

      return !(!vm.registrations[session.course.id] || _.isEmpty(vm.registrations[session.course.id]));
    }

    function buildSessionsDatesWithFilter() {
      vm.sessionsByTracks = _.filter(vm.conferenceDescendants, (collectionComponent) => {
        if (!filterSessions(collectionComponent)) {
          return false;
        }

        return (
          !vm.selectedTrack ||
          _.find(collectionComponent.tracks, (track) => {
            return track.track.name === vm.selectedTrack;
          })
        );
      });

      const dates = _.groupBy(vm.sessionsByTracks, (collectionComponent) => {
        return moment(collectionComponent.course.startDate).startOf('day');
      });
      vm.sessionDates = Object.keys(dates).map((key) => ({
        date: dates[key][0].course.startDate,
        sessions: dates[key],
      }));
    }

    function selectTrack(track) {
      vm.selectedTrack = track.name;
      applySearch();
    }

    function applySearch() {
      let search = $location.search();
      search.track = vm.selectedTrack;
      $location.search(search);
    }
  }
})();
