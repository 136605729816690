(function () {
  /**
   * @ngdoc controller
   * @name kmi.lms.course.details.controller:SubjectAreasListInfoController
   *
   * @requires elms
   *
   * @description
   * Shows  Subject Areas
   */

  angular.module('kmi.lms.course.details').directive('subjectAreasListInfo', subjectAreasListInfo);

  function subjectAreasListInfo() {
    return {
      restrict: 'EA',
      scope: {
        subjectAreas: '=',
        trackingName: '=',
      },
      template: function (elem, attr) {
        return attr.modernView
          ? require('ajs/modules/course/views/components/modern/subject-areas-list-info.html').default
          : require('ajs/modules/course/views/components/subject-areas-list-info.html').default;
      },
      controller: SubjectAreasListInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /*@ngInject*/
  function SubjectAreasListInfoController() {
    var vm = this;

    vm.expanded = false;
    vm.quantity = vm.limit = 2;

    vm.toggle = toggle;

    function toggle() {
      vm.expanded = !vm.expanded;
      vm.quantity = vm.expanded ? vm.subjectAreas.length : vm.limit;
    }
  }
})();
