(function () {
  angular.module('kmi.lms.core').factory('siteSettingService', siteSettingService);

  /* @ngInject */
  function siteSettingService($http, $window, apiUrl, _) {
    var siteSetting = _.get($window, 'elmsEnvironment.siteSettings', null);

    var getSiteSetting = function () {
      if (!siteSetting) {
        siteSetting = {};
        $http.get(apiUrl('/a/about_elms/site_setting/')).then(function (response) {
          angular.extend(siteSetting, response.data);
        });
      }

      return siteSetting;
    };

    // Public API here
    return {
      getSiteSetting: getSiteSetting,
    };
  }
})();
