(function () {
  angular.module('kmi.lms.course.details').controller('CourseDetailedInfoController', CourseDetailedInfoController);

  /* @ngInject */
  function CourseDetailedInfoController($scope, _, globalConfig, contentSectionsService, courseService) {
    const vm = this;

    // used as a controller on different components
    vm.detailsOpen = true;
    vm.attributesOpen = false;
    vm.collections = [];
    vm.trainingCategories = [];
    vm.courseSettings = _.get(globalConfig, 'settings.courseDetails');
    vm.heroView = _.get(globalConfig, 'settings.courseDetails.heroImageDetails');

    vm.hasAttributes = hasAttributes;
    vm.hasDetails = hasDetails;
    vm.quantityAbout = vm.limitAbout = 1;

    vm.toggleAbout = toggleAbout;
    vm.getOrganization = getOrganization;
    vm.sectionIsShown = sectionIsShown;

    vm.$onInit = onInit;

    function onInit() {
      initDetailsSection();
      initAttributesSection();
      loadTrainingCategories();

      $scope.$watchGroup(['vm.targetCapabilitiesCount', 'vm.coreCompetenciesCount'], function () {
        initPanels();
      });
    }

    function sectionIsShown(form) {
      return contentSectionsService.checkSectionIsAvailable(vm.course, form);
    }

    function initDetailsSection() {
      vm.course.about = _.filter(
        [
          { name: 'Special Notes', value: vm.course.$getHtmlField('notes') },
          { name: 'Learning Objectives', value: vm.course.$getHtmlField('learningObjectives') },
          { name: 'About the Author', value: vm.course.$getHtmlField('aboutAuthor') },
          { name: 'Accreditation', value: vm.course.$getHtmlField('accreditation') },
        ],
        function (x) {
          return x.value;
        },
      );

      initPanels();
    }

    function initPanels() {
      vm.detailsOpen = hasDetails();
      vm.attributesOpen = !vm.detailsOpen;
    }

    function initAttributesSection() {
      vm.attributeColumsCount =
        !!vm.collections.length +
        !!_.get(vm.course, 'creditTypes.length') +
        !!_.get(vm.course, 'trainingCategories.length') +
        !!_.get(vm.course, 'subjectAreas.length') +
        !!_.get(vm.course, 'audienceBackgroundRoles.length') +
        !!_.get(vm.course, 'certificates.length') +
        !!_.get(vm.course, 'providerCourseNo') +
        !!_.get(vm.course, 'targetAudiences.length');

      if (!vm.modern) {
        vm.attributeColumsCount += !!_.get(vm.course, 'languages.length');
      } else {
        if (
          vm.course.languages &&
          (vm.course.languages.length > 1 || (vm.course.languages.length === 1 && vm.course.languages[0].id !== 1))
        ) {
          vm.attributeColumsCount += 1;
        }
      }
    }

    function hasOrganizations() {
      return !!vm.getOrganization(1) || !!vm.getOrganization(3);
    }

    function hasAttributes() {
      return vm.course.sponsor || vm.attributeColumsCount || hasOrganizations();
    }

    function hasDetails() {
      return _.get(vm.course, 'about.length') || vm.targetCapabilitiesCount || vm.coreCompetenciesCount;
    }

    function toggleAbout() {
      vm.expandedAbout = !vm.expandedAbout;
      vm.quantityAbout = vm.expandedAbout ? vm.course.about.length : vm.limitAbout;
    }

    function getOrganization(typeId) {
      return _.find(vm.course.organizations, { typeId: typeId });
    }

    function loadTrainingCategories() {
      courseService.getCourseTrainingCategories(vm.course.id).then(function (categories) {
        vm.trainingCategories = categories;
      });
    }
  }
})();
