(function () {
  angular
    .module('kmi.lms.core')
    .constant('LmsBasePathHttpHeader', 'Lms-BasePath')
    .constant('LmsApplicationViewHttpHeader', 'Lms-ApplicationView')
    .constant('LmsBuildInfoFile', 'buildVersion.json')
    .constant('lmsIntegrityConfig', {
      checkInterval: 60 * 60 * 1000,
      buildInfoFile: 'buildVersion.json',
    })
    .factory('lmsIntegrityService', lmsIntegrityService)
    .factory('httpRequestLmsIntegrity', httpRequestLmsIntegrity);

  /* @ngInject */
  function lmsIntegrityService($q, $http, $window, $timeout, lmsIntegrityConfig, globalConfig, _) {
    var _lastChecked, _currentBuild;

    //init();

    return {
      init: init,
      checkVersionChanged: checkVersionChanged,
      initBasePath: initBasePath,
    };

    function initBasePath() {
      if (_.get($window, 'elmsEnvironment.siteSettings.basePath')) {
        globalConfig.base = ['/', _.trim(_.get($window, 'elmsEnvironment.siteSettings.basePath'), '/'), '/'].join('');
      }
    }

    function init() {
      _lastChecked = Date.now();

      _getVersionInfo().then(function (buildInfo) {
        _currentBuild = buildInfo;
      });
    }

    function checkVersionChanged() {
      var deferred = $q.defer(),
        currentTime = Date.now();

      if (_lastChecked + lmsIntegrityConfig.checkInterval < currentTime) {
        _lastChecked = Date.now();

        _getVersionInfo().then(function (buildInfo) {
          if (!_currentBuild || !buildInfo) {
            return deferred.resolve('N/A');
          }

          if (buildInfo.build_number !== _currentBuild.build_number) {
            deferred.reject(buildInfo.build_number);
          } else {
            deferred.resolve(_currentBuild.build_number);
          }
        });
      } else {
        $timeout(function () {
          deferred.resolve(_currentBuild && _currentBuild.build_number);
        });
      }

      return deferred.promise;
    }

    function _getVersionInfo() {
      return $http
        .get([globalConfig.base, lmsIntegrityConfig.buildInfoFile].join(''), { anonymous: true })
        .then(function (result) {
          return result.data;
        });
    }
  }

  /* @ngInject */
  function httpRequestLmsIntegrity($q, LmsBasePathHttpHeader, globalConfig, LmsApplicationViewHttpHeader) {
    return {
      request: function (config) {
        if (config && config.headers) {
          if (globalConfig.base && !config.headers[LmsBasePathHttpHeader]) {
            config.headers[LmsBasePathHttpHeader] = globalConfig.base;
          }

          if (globalConfig.settings.view && !config.headers[LmsApplicationViewHttpHeader]) {
            config.headers[LmsApplicationViewHttpHeader] = globalConfig.settings.view;
          }
        }

        return config || $q.when(config);
      },
    };
  }
})();
