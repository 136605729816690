(function () {
  angular
    .module('kmi.lms.core')
    .constant('GroupCategory', {
      NATIONAL: 10,
      STATE: 12,
    })
    .factory('Group', groupResouse);

  /* @ngInject */
  function groupResouse($resource, uuid, $cacheFactory) {
    const groupCache = $cacheFactory('groupCache');
    let Group = $resource(
      '/a/groups/:groupId/',
      { groupId: '@id' },
      {
        query: {
          method: 'GET',
          url: '/a/groups/',
          isArray: false,
          cache: groupCache,
          transformResponse: wrapResponse,
        },
        get: {
          method: 'GET',
          transformResponse: transformResponse,
        },
        update: {
          method: 'PUT',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
      },
    );

    Object.defineProperty(Group, 'defaults', {
      get: function () {
        return {
          active: true,
          visible: true,
          globalId: uuid.v4(),
          promptPortalPolicy: true,
        };
      },
    });

    Group.newGroup = function (attrs) {
      return new Group(angular.extend(Group.defaults, attrs || {}));
    };

    function wrapResponse(data, headersGetter, status) {
      if (status === 200) {
        var wrapped = angular.fromJson(data);
        angular.forEach(wrapped.items, function (item, idx) {
          wrapped.items[idx] = new Group.newGroup(item); // eslint-disable-line new-cap
        });
        return wrapped;
      }

      return data;
    }

    function clearCache() {
      let groupCache = $cacheFactory.get('groupCache') || $cacheFactory('groupCache');
      groupCache.removeAll();
    }

    function transformResponse(data, headers, status) {
      if (status === 200) {
        return data ? angular.extend(Group.defaults, angular.fromJson(data)) : null;
      }
      //When status is not 200 response body may contain error message or some useful data.
      if (headers('Content-Type') === 'application/json') {
        return angular.fromJson(data);
      }
      return data;
    }

    return Group;
  }
})();
