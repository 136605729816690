(function () {
  angular
    .module('kmi.lms.courseProviders')
    .factory('CourseProviderOrganization', courseProviderOrganization)
    .run(moduleRun);

  /* @ngInject */
  function courseProviderOrganization($resource) {
    var Organization = $resource(
      '/a/course/providers/organizations/:organizationId/',
      { organizationId: '@id' },
      {
        update: { method: 'PUT' },
      },
      {
        stripTrailingSlashes: false,
      },
    );

    Organization.newOrganization = function (attrs) {
      return new Organization(
        angular.extend(
          {
            active: true,
            visible: true,
          },
          attrs,
        ),
      );
    };

    return Organization;
  }

  /* @ngInject */
  function moduleRun(entityDataService, $injector) {
    entityDataService.registerLoader('provider_organization', function (id) {
      var CourseProviderOrganization = $injector.get('CourseProviderOrganization');

      return CourseProviderOrganization.get({ organizationId: id }).$promise;
    });
  }
})();
