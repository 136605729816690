(function () {
  /**
   * @ngdoc controller
   * @name kmi.lms.course.details.controller:TargetAudiencesInfoControllerController
   *
   * @requires elms
   *
   * @description
   * Shows Target Audiences
   */

  angular.module('kmi.lms.course.details').directive('targetAudiencesListInfo', targetAudiencesListInfo);

  function targetAudiencesListInfo() {
    return {
      restrict: 'EA',
      scope: {
        targetAudiences: '=',
        trackingName: '=',
      },
      template: function (elem, attr) {
        return attr.modernView
          ? require('ajs/modules/course/views/components/modern/target-audiences-list-info.html').default
          : require('ajs/modules/course/views/components/target-audiences-list-info.html').default;
      },
      controller: TargetAudiencesInfoController,
      controllerAs: 'vm',
      bindToController: true,
    };
  }

  /*@ngInject*/
  function TargetAudiencesInfoController() {
    var vm = this;

    vm.expanded = false;
    vm.quantity = vm.limit = 2;

    vm.toggle = toggle;

    function toggle() {
      vm.expanded = !vm.expanded;
      vm.quantity = vm.expanded ? vm.targetAudiences.length : vm.limit;
    }
  }
})();
