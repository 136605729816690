(function () {
  // Formats 30, 66, 68 were added for "train" implementation. We need to support current formats.
  const format = Object.freeze({
    uploadedDocument: 101,
    SCORMCourse: 102,
    externalLink: 103,
    collection: 104,
    liveEvent: 106,
    exam: 108,
    videoCourse: 109,
    survey: 115,
    certification: 117,
    googleDrive: 118,
    zoomMeeting: 119,
    googleMeeting: 120,
    office365: 121,
    trainingCategory: 123,
    msTeamsMeeting: 124,
    doxyMeMeeting: 122,
    trainingHistory: 125,
    webExMeeting: 126,
    goToMeeting: 127,
    examTrack: 128,
    article: 129,
    SCORMCourse2004: 130,
    conference: 131,
    conferenceSession: 132,
    library: 133,
  });

  const formatType = Object.freeze({
    online: 1,
    liveEvent: 2,
    physicalCarrier: 3,
    collection: 4,
    conference: 5,
    conferenceSession: 6,
    exercise: 7,
  });

  const liveFormatTypes = [formatType.liveEvent, formatType.exercise];

  const approvalStatus = Object.freeze({
    incomplete: 5,
    approved: 2,
  });

  let locationType = Object.freeze({
    inPerson: 1,
    virtual: 2,
    inPersonAndVirtual: 3,
  });

  angular
    .module('kmi.lms.course.common')
    .constant('courseEnums', {
      format: format,
      formatType: formatType,
      liveFormatTypes: liveFormatTypes,
      locationType: locationType,
      approvalStatus: approvalStatus,
      uploadContentFormats: [format.SCORMCourse, format.SCORMCourse2004, format.videoCourse, format.uploadedDocument],
      payLaterCourses: [
        format.liveEvent,
        format.zoomMeeting,
        format.googleMeeting,
        format.doxyMeMeeting,
        format.msTeamsMeeting,
        format.webExMeeting,
        format.goToMeeting,
      ],
    })
    .constant('supportedCourseFormats', [1, 2, 3, 4, 5, 6, 7, 8]);
})();
