(function () {
  angular.module('kmi.lms.course.quizzes', ['kmi.lms.course.common']).config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider.state('main.courseQuizResult', {
      url: '^/course/{courseId}/quiz_results/{resultId}',
      component: 'courseQuizResultViewComponent',
      params: {
        courseId: null,
        resultId: null,
      },
      data: {
        label: 'Quiz',
        availableFor: ['admin', 'regularUser'],
      },
      resolve: {
        course: function ($transition$, Course) {
          return Course.get({ courseId: $transition$.params().courseId, allowSystemFormat: true }).$promise;
        },
        quizResult: function ($transition$, QuizResult) {
          return QuizResult.get({ resultId: $transition$.params().resultId }).$promise;
        },
      },
    });
  }
})();
