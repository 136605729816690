(function () {
  angular
    .module('kmi.lms.course.registrations')
    .factory('courseRegistrationStatus', courseRegistrationStatus)
    .run(moduleRun);

  /* @ngInject */
  function courseRegistrationStatus($http, globalConfig, _) {
    var statuses = {
      pending: 1,
      approved: 2,
      declined: 3,
      inProgress: 4,
      completed: 5,
      withdrawn: 6,
      waitList: 7,
      preAssessmentPending: 8,
      assessmentPending: 9,
      notStarted: 10,
      registrationPending: 17,
      postAssessmentSubmitted: 19,
      evaluationPending: 20,
      passed: 40,
      notPassed: 41,
      expired: 42,
    };

    statuses.notStartedSet = [
      statuses.notStarted,
      statuses.pending,
      statuses.approved,
      statuses.waitList,
      statuses.declined,
      statuses.withdrawn,
      statuses.registrationPending,
    ];
    statuses.inProgressSet = [
      statuses.inProgress,
      statuses.preAssessmentPending,
      statuses.assessmentPending,
      statuses.postAssessmentSubmitted,
      statuses.evaluationPending,
    ];
    statuses.activeSet = [
      statuses.notStarted,
      statuses.pending,
      statuses.approved,
      statuses.inProgress,
      statuses.registrationPending,
      statuses.waitList,
      statuses.preAssessmentPending,
      statuses.assessmentPending,
      statuses.postAssessmentSubmitted,
      statuses.evaluationPending,
    ];

    statuses.completedSet = [statuses.completed, statuses.passed, statuses.notPassed];

    statuses.strictCompletedSet = [statuses.completed, statuses.passed];

    statuses.incompleteSet = [
      statuses.pending,
      statuses.registrationPending,
      statuses.approved,
      statuses.inProgress,
      statuses.waitList,
      statuses.preAssessmentPending,
      statuses.assessmentPending,
      statuses.notStarted,
      statuses.postAssessmentSubmitted,
      statuses.evaluationPending,
      statuses.notPassed,
    ];

    statuses.declinedSet = [statuses.declined, statuses.withdrawn];

    statuses.withdrawnSet = [statuses.declined, statuses.withdrawn, statuses.expired];
    statuses.terminalSet = [
      statuses.completed,
      statuses.passed,
      statuses.notPassed,
      statuses.expired,
      statuses.withdrawn,
    ];

    statuses.statusNamesOverride = _.get(globalConfig, 'courseRegistration.statusNamesOverride') || {};
    statuses.allItems = [];
    statuses.names = {};
    statuses.titles = {};

    statuses.populateStatuses = populateStatuses;

    function populateStatuses() {
      $http.get('/a/course_registrations/dictionaries/', { cache: true }).then(function (response) {
        angular.forEach(response.data.statuses, function (status) {
          if (
            !(
              globalConfig.courseRegistration &&
              globalConfig.courseRegistration.excludedStatuses &&
              _.includes(globalConfig.courseRegistration.excludedStatuses, status.id)
            )
          ) {
            statuses.allItems.push(status);
          }
        });

        fillStatusNames();
      });
    }

    function fillStatusNames() {
      var statusesDict = _.zipObject(_.map(statuses.allItems, 'id'), _.map(statuses.allItems, 'name'));

      angular.extend(statuses.names, statusesDict);
    }

    statuses.getTitle = getTitle;
    statuses.getStatusIdByUserCourse = getStatusIdByUserCourse;
    statuses.getDataSource = getDataSource;
    statuses.getStatusName = getStatusName;
    statuses.orderByStatusSet = orderByStatusSet;

    fillStatusNames();

    return statuses;

    function getTitle(statusId) {
      return _.get(statuses.titles, statusId, statuses.names[statusId]);
    }

    function getStatusName(statusId, defaultId) {
      return _.get(statuses.names, statusId) || (defaultId && _.get(statuses.names, defaultId));
    }

    function getStatusIdByUserCourse(node, highestResult, recordDataSource) {
      var dataSource = getDataSource(node, highestResult, recordDataSource);
      return dataSource && dataSource.statusId ? dataSource.statusId : statuses.notStarted;
    }

    function getDataSource(node, highestResult, recordDataSource) {
      var item = recordDataSource && _.get(node, recordDataSource);
      var last = _.get(node, 'lastRegistration');
      if (last && last.statusId === statuses.expired) {
        return last;
      } else if (item) {
        return item;
      } else if (highestResult) {
        return node;
      } else if (last) {
        return last;
      }

      return null;
    }

    function orderByStatusSet(status) {
      if (statuses.expired === status.id) {
        return 900 + status.order;
      } else {
        if (_.includes(statuses.withdrawnSet, status.id)) {
          return status.order;
        } else {
          if (_.includes(statuses.notStartedSet, status.id)) {
            return 200 + status.order;
          } else {
            if (_.includes(statuses.inProgressSet, status.id)) {
              return 400 + status.order;
            } else {
              return 800 + status.order;
            }
          }
        }
      }
    }
  }

  function moduleRun(courseRegistrationStatus) {
    courseRegistrationStatus.populateStatuses();
  }
})();
