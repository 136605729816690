(function () {
  angular.module('kmi.lms.course.registrations').component('registrationCreditTypesSelection', {
    template: require('ajs/modules/course/registrations/components/credit-types-selection.html').default,
    controller: RegistrationCreditTypesSelectionController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      registration: '=?',
      promptForAttributes: '<?',
      selectedCredits: '=',
      creditsFormRestricted: '<?',
      additionalAttributes: '=?',
      lockCredits: '<?',
      loading: '=?',
      onChange: '&onChange',
      formValid: '=?',
    },
  });

  /* @ngInject */
  function RegistrationCreditTypesSelectionController(
    $scope,
    $q,
    elms,
    attributesService,
    userAttributesService,
    creditTypesService,
    currentUser,
    $http,
    _,
  ) {
    const vm = this,
      defaultEmptyCredit = { id: null, creditType: { name: 'None', id: -1 } };

    vm.chesAndMches = [{ creditType: { id: 6 } }, { creditType: { id: 52 } }];

    let allAttributes = [];
    vm.creditTypes = [];

    vm.creditChecked = creditChecked;
    vm.syncCreditAmount = syncCreditAmount;
    vm.formatDigit = elms.formatDigit;
    vm.$onInit = onInit;

    function onInit() {
      vm.promptForAttributes = vm.promptForAttributes && !vm.course.courseFormat.system;

      vm.additionalAttributes = vm.additionalAttributes || [];
      vm.loading = true;
      initCreditList()
        .then(initAttributes)
        .finally(function () {
          vm.loading = null;
          $scope.$watch('vm.creditTypes', handleCreditsChanged, true);
        });

      $scope.$watch('vm.selectedCredits', () => {
        if (vm.creditsFormRestricted && (!vm.selectedCredits || vm.selectedCredits.length === 0)) {
          vm.creditTypes.forEach((credit) => (credit.selected = false));
          vm.selectedCredit = null;
        }
      });

      /** @deprecated Used only for support form validation sync with the upgraded component. Remove before upgrade */
      $scope.$watch('vm.registrationCreditsForm.$valid', (newValue, oldValue) => {
        if (newValue !== oldValue) {
          vm.formValid = newValue;
        }
      });
    }

    function handleCreditsChanged(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      vm.selectedCredits = [];
      vm.requiredAttributesForSelectedCredits = [];
      vm.additionalAttributes = [];

      vm.creditTypes.forEach((credit) => {
        if (credit.selected) {
          vm.selectedCredits.push(credit);
          vm.requiredAttributesForSelectedCredits.push(..._.get(credit, 'requiredAttributes', []));
          vm.additionalAttributes.push(..._.get(credit, 'userAttributes', []));
        }
      });

      vm.additionalAttributes.forEach((attribute) => {
        attribute.required = vm.requiredAttributesForSelectedCredits.includes(attribute.typeId);
      });

      findSingleSelection();

      if (angular.isFunction(vm.onChange)) {
        vm.onChange();
      }
    }

    function findSingleSelection() {
      if (vm.selectedCredits.length > 0) {
        vm.selectedCredit = vm.selectedCredits[0].creditType.id;
      }
    }

    function initCreditList() {
      vm.selectedCredits = vm.selectedCredits || [];

      return creditTypesService.getCourseCreditTypes(vm.course.id).then(function (creditTypes) {
        vm.creditTypes = _.sortBy(creditTypes, (ct) => {
          return _.get(ct, 'creditType.name', '').toLowerCase();
        });

        if (!vm.course.providesMultipleCredits) {
          vm.creditTypes.push(defaultEmptyCredit);
        }

        const creditDict = _.keyBy(vm.creditTypes, 'creditType.id');

        vm.selectedCredits.forEach(function (selectedCredit) {
          try {
            if (!creditDict[selectedCredit.creditType.id]) {
              vm.creditTypes.push(angular.extend({ selected: true }, selectedCredit));
            } else {
              creditDict[selectedCredit.creditType.id].selected = true;

              if (vm.registration) {
                creditDict[selectedCredit.creditType.id].originalAmount =
                  creditDict[selectedCredit.creditType.id].amount;
                creditDict[selectedCredit.creditType.id].amount = selectedCredit.amount;

                creditDict[selectedCredit.creditType.id].originalAccreditationAgency =
                  creditDict[selectedCredit.creditType.id].accreditationAgency;
                creditDict[selectedCredit.creditType.id].accreditationAgency = selectedCredit.accreditationAgency;

                creditDict[selectedCredit.creditType.id].originalAccreditationAgencyId =
                  creditDict[selectedCredit.creditType.id].accreditationAgencyId;
                creditDict[selectedCredit.creditType.id].accreditationAgencyId = selectedCredit.accreditationAgencyId;
              }
            }
          } catch (e) {
            console.error(e);
          }
        });

        findSingleSelection();
      });
    }

    function initAttributes() {
      if (vm.promptForAttributes) {
        vm.creditTypes.forEach((creditType) => {
          if (creditType.userAttributes) {
            creditType.requiredAttributes = _.map(
              _.filter(creditType.userAttributes, { required: true }),
              'userAttributeType.id',
            );

            creditType.userAttributes = _.map(creditType.userAttributes, (userAttribute) => {
              let userAttributeType = _.find(allAttributes, (aua) => aua.id === userAttribute.userAttributeType.id);
              if (!userAttributeType) {
                userAttributeType = userAttribute.userAttributeType;
                allAttributes.push(userAttributeType);
              }
              // userAttributeType.required = userAttributeType.required || userAttribute.required;
              userAttributeType.typeId = userAttribute.userAttributeType.id;
              return userAttributeType;
            });
          }
        });

        return userAttributesService
          .getAttributeValues(currentUser.get().id, { include_inactive_categories: true })
          .then(function (attributeValues) {
            attributesService.mergeWithValues(allAttributes, attributeValues, 'typeId');
          });
      }

      return $q.resolve();
    }

    function creditChecked(credit) {
      for (var i = vm.creditTypes.length - 1; i >= 0; i--) {
        vm.creditTypes[i].selected = credit.creditType.id === vm.creditTypes[i].creditType.id;
      }
    }

    function syncCreditAmount(credit) {
      $http
        .put(['/a/course_registrations/', vm.registration.id, '/credits/', credit.creditType.id, '/'].join(''), {
          amount: credit.originalAmount,
        })
        .then(function (response) {
          credit.amount = response.data.amount;
          delete credit.originalAmount;
        });
    }
  }
})();
