(function () {
  angular
    .module('kmi.lms.course.registrations')
    .service(
      'courseRegistrationService',
      function (
        rootScopeService,
        $http,
        $q,
        $window,
        $injector,
        apiUrl,
        courseEnums,
        searchRequestItemCount,
        CourseRegistration,
        UserCourse,
        courseDetailsState,
        courseRegistrationStatus,
        LearningObjectRegistration,
        LearningObjectRegistrationWorkflow,
        _,
        serverErrorHandlerService,
      ) {
        let activeRegistrationStatusesSet = courseRegistrationStatus.activeSet
          .concat(courseRegistrationStatus.completedSet)
          .concat([courseRegistrationStatus.expired]);

        this.aggregated = (function () {
          var apiEndpoint = apiUrl('/a/course_registrations/compilations/'),
            search = (function () {
              var params = {
                  query: '',
                  filter: {
                    archived: false,
                  },
                  sort: {
                    field: '',
                    reverse: false,
                  },
                },
                hasMoreItems = true,
                exportEndpoint = apiUrl('/a/course_registrations/compilations/export/');

              var performSearch = function (offset) {
                params.offset = offset;
                params.take = searchRequestItemCount;

                return $http.get(apiEndpoint, { params: params }).then(function (response) {
                  hasMoreItems = response.data.items.length === searchRequestItemCount;
                  return response.data;
                });
              };

              var searchNext = function () {
                if (hasMoreItems) {
                  return performSearch(params.offset + searchRequestItemCount);
                } else {
                  var def = $q.defer();
                  def.resolve({ items: [] });
                  return def.promise;
                }
              };

              var exportResult = function () {
                var parts = [];
                _.forEach(
                  {
                    query: params.query,
                    filter: params.filter,
                    sort: params.sort,
                  },
                  function (value, key) {
                    if (value === null || angular.isUndefined(value)) {
                      return;
                    }

                    if (!angular.isArray(value)) {
                      value = [value];
                    }

                    _.forEach(value, function (v) {
                      if (angular.isObject(v)) {
                        v = angular.toJson(v);
                      }

                      parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(v));
                    });
                  },
                );
                if (parts.length > 0) {
                  exportEndpoint += (exportEndpoint.indexOf('?') === -1 ? '?' : '&') + parts.join('&');
                }

                $window.open(exportEndpoint, '_blank', '');
              };

              return {
                query: function () {
                  return performSearch(0);
                },
                conditions: params,
                next: searchNext,
                exportResult: exportResult,
              };
            })();

          function updateRegistrations(items, action) {
            return $http.post(apiEndpoint, {
              items: items,
              action: action,
            });
          }

          return {
            search: search,
            toggleActivity: function (items) {
              return updateRegistrations(items, 'toggleActivity');
            },
          };
        })();

        this.setExpirationDate = function (courseId, userId, expirationDate) {
          var userCourse = $injector.get('UserCourse');
          return userCourse.update(
            {
              userId: userId,
              courseId: courseId,
            },
            { accessExpirationDate: expirationDate },
          ).$promise;
        };

        this.addCourseRegistration = function (courseId, sessionId, userId, expirationDate) {
          var UserCourse = $injector.get('UserCourse');

          var userCourse = new UserCourse({
            userId: userId,
            courseId: courseId,
          });

          userCourse.sessionId = sessionId;
          userCourse.accessExpirationDate = expirationDate;

          return userCourse.$save();
        };

        this.getRegistrationAssessmentResult = function (registrationId, typeId) {
          return $http
            .get(['/a/course_registrations/', registrationId, '/assessments/types/', typeId, '/'].join(''))
            .then(function (response) {
              return response.data;
            });
        };

        this.getHistory = function (courseId, userId) {
          return $http.get(['/a/user/', userId, '/courses/', courseId, '/history/'].join('')).then(function (response) {
            return _.map(response.data, function (reg) {
              return new CourseRegistration(reg);
            });
          });
        };

        this.compilationStructureExportUrl = function (courseId) {
          return apiUrl('/a/course_details/' + courseId + '/compilation_structure/export/');
        };

        this.addComponentRegistrations = function (courseId) {
          return $http.post(apiUrl('/a/course_details/' + courseId + '/compilation_structure/'), {
            action: 'register',
          });
        };

        this.calculateCollectionProgress = countNodes;

        function countNodes(node, includeOptional) {
          let totalCount = 0;
          let completedCount = 0;
          let strictlyCompletedCount = 0;
          let activeRegistrationCount = 0;

          includeOptional = includeOptional || false;

          _.forEach(node.items, function (blsItem) {
            if (blsItem.type === 2) {
              var countItems = countNodes(blsItem);
              if (blsItem.required === true || includeOptional) {
                totalCount += countItems.total;
                completedCount += countItems.completed;
                strictlyCompletedCount += countItems.strictlyCompleted;
                activeRegistrationCount += countItems.activeRegistrations;
              } else if (blsItem.required === false) {
                totalCount += 1;
                completedCount += countItems.completed > 0 ? 1 : 0;
                strictlyCompletedCount += countItems.strictlyCompleted > 0 ? 1 : 0;
                activeRegistrationCount += countItems.activeRegistrations;
              }
            } // Course
            else {
              totalCount++;
              if (courseRegistrationStatus.strictCompletedSet.includes(blsItem.status_id)) {
                strictlyCompletedCount++;
              }
              if (courseRegistrationStatus.completedSet.includes(blsItem.status_id)) {
                completedCount++;
              }
              if (activeRegistrationStatusesSet.includes(blsItem.status_id)) {
                activeRegistrationCount++;
              }
            }
          });
          return {
            total: totalCount,
            activeRegistrations: activeRegistrationCount,
            completed: completedCount,
            strictlyCompleted: strictlyCompletedCount,
          };
        }

        this.updateUserCourse = function (oldUserCourse, userId, course, skipWorkflow) {
          return UserCourse.get({
            userId: userId,
            courseId: course.id,
            skip_workflow: skipWorkflow || true,
          })
            .$promise.then(function (newUserCourse) {
              if (
                _.get(oldUserCourse, 'workflow.registration.status_id') &&
                newUserCourse.lastRegistration &&
                oldUserCourse.workflow.registration.status_id === newUserCourse.lastRegistration.statusId
              ) {
                newUserCourse.workflow = oldUserCourse.workflow;
              }

              if (
                !newUserCourse.workflow &&
                course.formatTypeId === courseEnums.formatType.liveEvent &&
                courseDetailsState.modernFeaturesEnabled(course)
              ) {
                return LearningObjectRegistration.get(course, null).then(
                  function (registration) {
                    newUserCourse.workflow = new LearningObjectRegistrationWorkflow(registration);
                    return newUserCourse;
                  },
                  function (reason) {
                    serverErrorHandlerService.handleForbiddenError(reason);
                  },
                );
              } else {
                return newUserCourse;
              }
            })
            .finally(function () {
              rootScopeService.broadcast('event:course.details.ready');
            });
        };
      },
    );
})();
