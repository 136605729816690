(function () {
  angular.module('kmi.lms.course.registrations').factory('eventExportService', eventExportService);

  /* @ngInject */
  function eventExportService($uibModal) {
    return {
      addToCalendarDialog: addToCalendarDialog,
    };

    function addToCalendarDialog(registration, schedule) {
      $uibModal.open({
        component: 'addToCalendarComponent',
        keyboard: false,
        resolve: {
          schedule: function () {
            if (!schedule || !schedule.id) {
              schedule = {};
              schedule.start_date = schedule.end_date = registration.targetCompletionDate;
            }
            schedule.registration_id = registration.id;
            schedule.subject = registration.course.name;
            schedule.description =
              'Please note that scheduled time of event is converted to you local time if Time Zone of event is different from your local Time Zone.';

            return schedule;
          },
        },
      });
    }
  }
})();
