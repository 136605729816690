(function () {
  angular.module('kmi.lms.core').directive('lmsMetaProperty', lmsMetaProperty);

  function lmsMetaProperty(metaOgpService) {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, element, attrs) {
      scope.ogpMeta = metaOgpService;

      scope.$watch(
        function () {
          var property = attrs.lmsMetaProperty;
          if (property === 'og:title') {
            return scope.ogpMeta.ogTitle();
          }
          if (property === 'og:description') {
            return scope.ogpMeta.ogDescription();
          }
          if (property === 'og:url') {
            return scope.ogpMeta.ogUrl();
          }
          if (property === 'og:image') {
            return scope.ogpMeta.ogImage();
          }
          if (property === 'og:type') {
            return scope.ogpMeta.ogType();
          }
        },
        function (newValue) {
          if (newValue && !angular.equals(newValue, element[0].content)) {
            element[0].content = newValue;
          }
        },
        true,
      );
    }
  }
})();
