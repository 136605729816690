import { Subject } from 'rxjs';

(function () {
  angular
    .module('kmi.lms.course.registrations')
    .factory('LearningObjectRegistrationWorkflow', LearningObjectRegistrationWorkflow);

  function LearningObjectRegistrationWorkflow(
    rootScopeService,
    $q,
    currentUser,
    EventDispatcher,
    $window,
    _,
    globalConfig,
    courseEnums,
    courseRegistrationStatus,
    Email,
    serverErrorHandlerService,
    notificationService,
    siteSettingService,
    trainingService,
    courseDetailsState,
    $transitions,
    registrationWorkflowService,
    $uibModal,
    $state,
  ) {
    let workflowStates = {
      preAssessmentExpirationState: {
        name: 'preAssessmentExpirationState',
        customFooter: true,
        nextButtonName: 'Submit Result',
        required: function () {
          return true;
        },
      },
      postAssessmentExpirationState: {
        name: 'postAssessmentExpirationState',
        customFooter: true,
        nextButtonName: 'Submit Result',
        required: function () {
          return true;
        },
      },
      codeBasedApproval: {
        name: 'codeBasedApproval',
        nextButtonName: 'OK',
        required: function (registration) {
          if (registration.id && registration.statusId !== courseRegistrationStatus.notStarted) {
            return false;
          }

          return registration.is_code_based_approval;
        },
      },
      enterCustomFields: {
        name: 'enterCustomFields',
        nextButtonName: 'OK',
        pre: function (registration, data) {
          data.custom_fields = {};

          for (let i = 0; i < registration.custom_fields.length; i++) {
            data.custom_fields[registration.custom_fields[i].id] = '';
          }
        },
        required: function (registration) {
          return registration.custom_fields_required;
        },
      },
      completeProfile: {
        name: 'completeProfile',
        nextRestricted: true,
        nextButtonName: 'Go to profile',
        nextHandler: () => $state.go('edit.account'),
        required: function (registration) {
          return (
            registration.profileCompletionRequired &&
            (registration.id === null || courseRegistrationStatus.notStartedSet.indexOf(registration.status_id) > -1)
          );
        },
      },
      fillUserAttributes: {
        name: 'fillUserAttributes',
        title: 'Required attributes',
        nextButtonName: 'Save',
        pre: function (registration) {
          if (registration.missingUserAttributes && registration.missingUserAttributes.length) {
            registration.missingUserAttributes = registration.missingUserAttributes.map(function (attr) {
              attr.required = true;
              return attr;
            });
          }
        },
        required: function (registration) {
          return (
            registration.missingUserAttributes &&
            registration.missingUserAttributes.length &&
            (registration.id === null || courseRegistrationStatus.notStartedSet.indexOf(registration.status_id) > -1)
          );
        },
      },
      selectCreditType: {
        name: 'selectCreditType',
        overflowBody: true,
        required: function (registration) {
          if (_.get($window, 'elmsEnvironment.settings.creditTypeSelectionEnabled', false)) {
            if (
              _.get(registration, 'course.creditTypes.length', 0) > 0 &&
              _.get(registration, 'actions.length', 0) > 0 &&
              !registration.course.courseFormat.system
            ) {
              if (
                registration.confirmUserAttributesRequired ||
                registration.id === null ||
                registration.status_id === courseRegistrationStatus.notStarted
              ) {
                return true;
              }
            }

            if (registration.course.courseFormat.system && registration.confirmUserAttributesRequired) {
              return true;
            }
          }

          return false;
        },
      },
      selectAttendanceType: {
        name: 'selectAttendanceType',
        pre: function (registration) {
          if (registration) {
            registration.attendanceTypeId = null;
          }
        },
        required: function (registration, data) {
          return (
            !data.attendanceTypeId &&
            (registration.id === null || registration.status_id === courseRegistrationStatus.notStarted) &&
            _.get(registration, 'course.courseLocation.typeId') === courseEnums.locationType.inPersonAndVirtual &&
            registration.actions &&
            registration.actions.length > 0
          );
        },
      },
      changeAttendanceType: {
        name: 'changeAttendanceType',
        title: 'Change way of attendance',
        pre: function (registration, data) {
          data.attendanceTypeId = registration.attendanceTypeId;
        },
        required: function () {
          return true;
        },
      },
      selectRole: {
        name: 'selectRole',
        title: 'Event Role',
        nextButtonName: 'Register',
        pre: function (registration, data) {
          let session = data.session_id && _.find(registration.course.sessions, { id: data.session_id });

          if (session) {
            registration.session = session;
          }
        },
        required: function (registration, data) {
          let session = data.session_id && _.find(registration.course.sessions, { id: data.session_id });

          return (
            session &&
            _.get(session, 'roles.length') &&
            (registration.id === null || registration.status_id === courseRegistrationStatus.notStarted)
          );
        },
      },
      changeCreditType: {
        name: 'changeCreditType',
        pre: function (registration, data) {
          data.creditTypes = registration.creditTypes;
        },
        required: function () {
          return true;
        },
      },
      editEmailConfirmation: {
        name: 'editEmailConfirmation',
        title: 'Order Information Request',
        nextButtonName: 'Submit',
        pre: function (registration, data) {
          //[#ContactName|Sir or Madam] - {0}
          //[#CourseName| ] - {1}
          //[#Username|TRAIN User] - {2}
          //[#UserEmail|training@phf.org] - {3}
          let siteShortName = _.get(siteSettingService.getSiteSetting(), 'siteShortName', null);
          let user = currentUser.get();
          let body = [
            'Dear Sir or Madam: \n\n',
            'I would like to receive registration information for your course titled "',
            registration.course.name || '',
            '". \n\nPlease forward the requested information to: \n\n',
            user.firstName,
            ' ',
            user.lastName,
            '\n',
            user.email,
            '\n',
          ].join('');

          data.orderEmail = Email.newEmail({
            emailTo: _.map(registration.course.contacts, function (item) {
              return item.email;
            }),
            emailFrom: user.email,
            subject: (siteShortName ? siteShortName + ' ' : '') + 'Order Information Request',
            body: body,
          });
        },
        required: function () {
          return true;
        },
      },
      enterTargetCompletionDate: {
        name: 'enterTargetCompletionDate',
        title: 'Add to Curriculum',
        nextButtonName: 'Add',
        pre: function (registration, data) {
          data.target_completion_date = null;
        },
        required: function () {
          return _.get(globalConfig, 'settings.registration.enterTargetCompletionDate', true);
        },
      },
      takeAssessmentConfirmation: {
        name: 'takeAssessmentConfirmation',
        nextButtonName: 'OK',
        required: function (registration) {
          return !registration.completion_code_required;
        },
      },
      takeEvaluationConfirmation: {
        name: 'takeEvaluationConfirmation',
        nextButtonName: 'OK',
        required: function (registration) {
          return !registration.completion_code_required;
        },
      },
      completionCodeConfirmation: {
        name: 'completionCodeConfirmation',
        title: 'Completion Code',
        nextButtonName: 'OK',
        required: function (registration) {
          return registration.completion_code_required && registration.status_id !== 9;
        },
      },
      completionConfirmation: {
        name: 'completionConfirmation',
        nextButtonName: 'Complete',
        required: function (registration) {
          let modernFeatures = courseDetailsState.modernFeaturesEnabled(registration.course);

          // Not conference session and conference without auto complete
          return modernFeatures;
        },
      },
      withdrawConfirmation: {
        name: 'withdrawConfirmation',
        nextButtonName: 'Withdraw',
        destructive: true,
        required: function () {
          // Not conference session
          return true;
        },
      },
      publishDateConfirmation: {
        name: 'publishDateConfirmation',
        nextRestricted: true,
        backRestricted: true,
        nextHandler: (workflow) => workflow.cancelAction(),
        required: function (registration, actionData) {
          return (
            registration.checkState === 'CoursePublishedCheck' &&
            registration.checkStateAllowedActions &&
            !_.includes(registration.checkStateAllowedActions, actionData.actionName)
          );
        },
        nextButtonName: 'OK',
      },
      componentRegistrationRestrictedConfirmation: {
        name: 'componentRegistrationRestrictedConfirmation',
        nextRestricted: true,
        backRestricted: true,
        nextHandler: (workflow) => workflow.cancelAction(),
        required: function (registration, actionData) {
          return (
            registration.checkState === 'ComponentRegistrationRestrictedCheck' &&
            registration.checkStateAllowedActions &&
            !_.includes(registration.checkStateAllowedActions, actionData.actionName)
          );
        },
        nextButtonName: 'OK',
      },
      registrationOpensDateConfirmation: {
        name: 'registrationOpensDateConfirmation',
        nextRestricted: true,
        backRestricted: true,
        nextHandler: (workflow) => workflow.cancelAction(),
        required: function (registration, actionData) {
          return (
            registration.checkState === 'CourseRegOpenCheck' &&
            registration.checkStateAllowedActions &&
            !_.includes(registration.checkStateAllowedActions, actionData.actionName)
          );
        },
        nextButtonName: 'OK',
      },
      removeConfirmation: {
        name: 'removeConfirmation',
        title: 'Remove Transcript Entry',
        nextButtonName: 'Accept',
        destructive: true,
      },
      registrationConfirmation: {
        name: 'registrationConfirmation',
        nextButtonName: 'OK',
        required: function (registration) {
          return (
            (!registration.id || registration.status_id === 10) &&
            registration.registration_confirmation_message &&
            registration.registration_confirmation_message.length > 0
          );
        },
      },
      moveToExternalRegistrationConfirm: {
        name: 'moveToExternalRegistrationConfirm',
        nextButtonName: 'Continue',
        required: function (registration, data) {
          return (
            _.get(globalConfig, 'settings.registration.externalRegistrationConfirmation', true) &&
            !!registration.external_course_url &&
            !data.onlyExecute
          );
        },
      },
      moveToExternalPaymentRegistrationConfirm: {
        name: 'moveToExternalPaymentRegistrationConfirm',
        nextButtonName: 'Continue',
        required: function (registration, data) {
          return (
            _.get(globalConfig, 'settings.registration.externalRegistrationConfirmation', true) &&
            !!registration.external_course_url &&
            !data.onlyExecute
          );
        },
      },
      executionConfirmation: {
        name: 'executionConfirmation',
        nextButtonName: 'Continue',
        required: function (registration, data) {
          if (
            (registration.id && registration.statusId !== courseRegistrationStatus.notStarted) ||
            registration.course.courseFormat.system ||
            ['RemoveAction', 'WithdrawAction', 'LaunchFollowUpEvaluationAction'].includes(data.actionName)
          ) {
            return false;
          }

          if (data.actionName === 'AddToCurriculum' && data.target_completion_date === undefined) {
            return false;
          }

          if (registration.course.courseLocation) {
            return true;
          }

          return !!Object.keys(data).filter((key) => {
            if (
              [
                'creditTypes',
                'custom_fields',
                'session_role_id',
                'target_completion_date',
                'attendanceTypeId',
              ].includes(key)
            ) {
              return data[key] !== undefined;
            }
          }).length;
        },
      },
      summaryStep: {
        name: 'summaryStep',
        nextButtonName: 'Go to sessions',
        required: function (registration, data) {
          /**
           * @description
           * This method checked before execution and after.
           * In this case we need to not hide modal before execution and
           * conditions is different before and after execution
           * 'AddToCurriculum' action should not trigger summary because we only add bookmark(Action deprecated, wait to remove)
           */
          if (
            ['AddToCurriculum', 'WithdrawAction', 'CompleteAction', 'LaunchFollowUpEvaluationAction'].includes(
              data.actionName,
            )
          ) {
            return false;
          }

          // approval notification
          // exists only for action 'GetApprovalAction' but requires notification.
          const courseFormatId = registration.course.formatId || registration.course.format;

          if (data.actionName === 'GetApprovalAction') {
            return true;
          }

          return (
            courseFormatId === courseEnums.format.conference &&
            (!registration.id ||
              registration.status_id === courseRegistrationStatus.notStarted ||
              !!registration.notification.collectionRegistered)
          );
        },
      },
    };

    let checkStatePrerequisites = {
      CoursePublishedCheck: [workflowStates.publishDateConfirmation],
      CourseRegOpenCheck: [workflowStates.registrationOpensDateConfirmation],
      ComponentRegistrationRestrictedCheck: [workflowStates.componentRegistrationRestrictedConfirmation],
    };

    //TODO: Investigate whether we can leave only one prerequisites sequence with the extended collection of states
    // (basePrerequisitesWithExternal).
    let baseRegistrationPrerequisites = [
        workflowStates.completeProfile,
        workflowStates.codeBasedApproval,
        workflowStates.registrationConfirmation,
        workflowStates.selectRole,
        workflowStates.selectAttendanceType,
        workflowStates.selectCreditType,
        workflowStates.fillUserAttributes,
        workflowStates.enterCustomFields,
      ],
      baseRegistrationPrerequisitesNoConfirm = [
        workflowStates.codeBasedApproval,
        workflowStates.selectAttendanceType,
        workflowStates.selectCreditType,
        workflowStates.fillUserAttributes,
        workflowStates.enterCustomFields,
      ];

    /**
     * @description
     * Action names reflects server's workflow actions
     * Action handler executes when server's call finished successfully
     * Only actions with order are involved in simple launch process
     */
    let workflowActions = {
      FinishExpiredPreAssessment: {
        prerequisites: [workflowStates.preAssessmentExpirationState],
        playCourseOrder: 5,
      },
      FinishExpiredPostAssessment: {
        prerequisites: [workflowStates.postAssessmentExpirationState],
        playCourseOrder: 5,
      },
      SkipEvaluation: {},
      AddToCurriculum: {
        prerequisites: [workflowStates.enterTargetCompletionDate],
        notificationMessage: 'Successfully saved in your curriculum.',
      },
      Launch: {
        prerequisites: baseRegistrationPrerequisites,
        launchType: 'course',
        courseContent: true,
        defaultLaunch: true,
        getWindow: function (registration) {
          if (
            _.includes(
              _.get(globalConfig, 'settings.courseDetails.forceNewWindowFormats', []),
              registration.course.format,
            ) ||
            courseEnums.format.externalLink === registration.course.format
          ) {
            return $window.open();
          }

          return null;
        },
        handler: function (workflow) {
          workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 5,
      },
      LaunchExternal: {
        prerequisites: baseRegistrationPrerequisites.concat([workflowStates.moveToExternalRegistrationConfirm]),
        launchType: 'launch_external',
        courseContent: true,
        defaultLaunch: true,
        getWindow: function () {
          return _.get(globalConfig, 'settings.registration.externalRegistrationTargetWindow', 'blank') === 'self'
            ? $window
            : $window.open();
        },
        handler: function (workflow) {
          workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 4,
      },
      LaunchPhysicalCarrierAction: {
        prerequisites: baseRegistrationPrerequisites,
        launchType: 'physical_carrier',
        courseContent: true,
        defaultLaunch: true,
        getWindow: function () {
          return $window.open();
        },
        handler: function (workflow) {
          workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 5,
      },
      LaunchExternalPayment: {
        prerequisites: baseRegistrationPrerequisites.concat([workflowStates.moveToExternalPaymentRegistrationConfirm]),
        launchType: 'launch_external',
        courseContent: true,
        defaultLaunch: true,
        getWindow: function () {
          return _.get(globalConfig, 'settings.registration.externalRegistrationTargetWindow', 'blank') === 'self'
            ? $window
            : $window.open();
        },
        handler: function (workflow) {
          workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 4,
      },
      LaunchExternalPaymentApproval: {
        prerequisites: baseRegistrationPrerequisites.concat([workflowStates.moveToExternalPaymentRegistrationConfirm]),
        launchType: 'launch_external',
        courseContent: true,
        defaultLaunch: true,
        getWindow: function () {
          return _.get(globalConfig, 'settings.registration.externalRegistrationTargetWindow', 'blank') === 'self'
            ? $window
            : $window.open();
        },
        handler: function (workflow) {
          workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 4,
      },
      Download: {
        prerequisites: baseRegistrationPrerequisitesNoConfirm,
        launchType: 'download',
        courseContent: true,
        defaultLaunch: true,
        getWindow: function () {
          return $window.open();
        },
        handler: function (workflow) {
          workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 5,
      },
      PrintAction: {
        getWindow: function (registration) {
          if (registration.hasTranscript) {
            return $window.open('', '', 'menubar=no,toolbar=no,status=no,resizable=yes,scrollbars=yes');
          } else {
            return $window.open(['/d/course/', registration.courseId, '/transcript/'].join(''));
          }
        },
        handler: function (workflow) {
          if (workflow.registration.hasTranscript) {
            workflow.launchContent('print', workflow.actionData.newWindow);
          }
        },
      },
      MoveToInProgressAction: {
        prerequisites: baseRegistrationPrerequisites,
        playCourseOrder: 5,
      },
      RegisterAction: {
        prerequisites: baseRegistrationPrerequisites,
        playCourseOrder: 2,
      },
      EmailPhysicalCarrierAction: {
        prerequisites: baseRegistrationPrerequisites.concat([workflowStates.editEmailConfirmation]),
        playCourseOrder: 5,
      },
      GetApprovalAction: {
        prerequisites: baseRegistrationPrerequisites,
        playCourseOrder: 2,
      },
      LaunchPreAssessmentAction: {
        launchType: 'pre',
        prerequisites: baseRegistrationPrerequisites,
        handler: function (workflow) {
          return workflow.launchContent(this.launchType);
        },
        playCourseOrder: 5,
      },
      LaunchPostAssessmentAction: {
        launchType: 'post',
        prerequisites: [workflowStates.completionCodeConfirmation, workflowStates.selectCreditType],
        handler: function (workflow) {
          return workflow.launchContent(this.launchType);
        },
        playCourseOrder: 6,
      },
      LaunchPreEvaluationAction: {
        launchType: 'preEvaluation',
        prerequisites: baseRegistrationPrerequisites,
        handler: function (workflow) {
          return workflow.launchContent(this.launchType);
        },
        playCourseOrder: 5,
      },
      LaunchEvaluationAction: {
        prerequisites: [workflowStates.selectCreditType],
        launchType: 'eval',
        handler: function (workflow) {
          return workflow.launchContent(this.launchType);
        },
        playCourseOrder: 9,
      },
      LaunchFollowUpEvaluationAction: {
        launchType: 'followUpEvaluation',
        handler: function (workflow) {
          return workflow.launchContent(this.launchType);
        },
        playCourseOrder: 9,
      },
      LaunchBrowseContentAction: {
        prerequisites: baseRegistrationPrerequisites,
        launchType: 'browse',
        courseContent: true,
        defaultLaunch: true,
        handler: function (workflow) {
          return workflow.launchContent('browse');
        },
        playCourseOrder: 5,
      },
      ChangeCreditTypeAction: {
        prerequisites: [workflowStates.changeCreditType],
      },
      ChangeWayOfAttendAction: {
        prerequisites: [workflowStates.changeAttendanceType],
      },
      CompleteAction: {
        prerequisites: [
          workflowStates.completionCodeConfirmation,
          workflowStates.completionConfirmation,
          workflowStates.selectCreditType,
        ],
        playCourseOrder: 7,
      },
      MoveToPostAssessmentAction: {
        prerequisites: [
          workflowStates.takeAssessmentConfirmation,
          workflowStates.completionCodeConfirmation,
          workflowStates.selectCreditType,
        ],
        playCourseOrder: 7,
        handler: function (workflow) {
          if (workflow.hasAction('LaunchPostAssessmentAction')) {
            workflow.processingAction = null;
            return workflow.exec('LaunchPostAssessmentAction');
          }
        },
      },
      MoveToEvaluationPendingAction: {
        prerequisites: [
          workflowStates.takeEvaluationConfirmation,
          workflowStates.completionCodeConfirmation,
          workflowStates.selectCreditType,
        ],
        playCourseOrder: 7,
        handler: function (workflow) {
          if (workflow.hasAction('LaunchEvaluationAction')) {
            workflow.processingAction = null;
            return workflow.exec('LaunchEvaluationAction');
          }
        },
      },
      WithdrawAction: {
        prerequisites: [workflowStates.withdrawConfirmation],
        notificationMessage: 'Successfully withdrawn from the course.',
        getCustomMessage: function (statusId) {
          if (statusId === 10) {
            return 'Course successfully removed.';
          }
        },
        handler: function (workflow) {
          workflow.registration.originalId = null;
        },
      },
      RemoveAction: {
        prerequisites: [workflowStates.removeConfirmation],
      },
      LaunchOnlineMeetingAction: {
        launchType: 'online_meeting',
        courseContent: true,
        defaultLaunch: true,
        allowLaunchContentOnError: true,
        getWindow: function () {
          return $window.open();
        },
        handler: function (workflow) {
          return workflow.launchContent(this.launchType, workflow.actionData.newWindow);
        },
        playCourseOrder: 5,
      },
      AddToWaitListAction: {
        prerequisites: baseRegistrationPrerequisites,
        playCourseOrder: 3,
      },
      LaunchWithoutRecordingAction: {
        launchType: 'launch_without_recording',
        courseContent: true,
        defaultLaunch: true,
        handler: function (workflow) {
          return workflow.launchContent(this.launchType);
        },
      },
      UpdateCustomFieldsAction: {},
    };

    function Workflow(registration, attrs) {
      this.registration = registration;
      angular.extend(this, attrs);

      this.currentState = null;
      this.currentStateHandlers = {};
      this.confirmationState = workflowStates.executionConfirmation;
      this.summaryState = workflowStates.summaryStep;
      this.skipValidationNotification = false;
      this.actionPrerequisitesSequence = [];
      this.actionData = {};
      this.processingAction = null;

      // This is a workaround to provide events from
      // the rootScope to the angular component
      this.workflowEvents = new Subject<any>();

      this.initCurrentStateHandlers = function () {
        this.currentStateHandlers = {
          nextHandler: this.currentState && this.currentState.nextHandler ? this.currentState.nextHandler : null,
          prevHandler: this.currentState && this.currentState.prevHandler ? this.currentState.prevHandler : null,
        };
      };

      this.launchContent = function (type, newWindow) {
        if (!this.actionData.openBook && (this.bypassContent || this.actionData.onlyExecute)) {
          return;
        }

        let defer = $q.defer();

        if (!this.actionData.newWindow && (!newWindow || newWindow === $window)) {
          let cleanUp = $transitions.onSuccess({}, function () {
            cleanUp();
            defer.resolve();
          });
        } else {
          defer.resolve();
        }

        trainingService.launchContent(this.registration, {
          type: type,
          newWindow: newWindow,
        });

        return defer.promise;
      };

      EventDispatcher.call(this);

      rootScopeService.broadcast('event:course.registration.workflow.initialized', this);
      this.workflowEvents.next({ event: 'initialized', payload: this });
    }

    angular.extend(Workflow.prototype, {
      cancelAction: function (restrictCloseDialogs?) {
        if (!restrictCloseDialogs) {
          rootScopeService.broadcast('event:course.registration.action.closeDialogs');
          this.workflowEvents.next({ event: 'event:course.registration.action.closeDialogs' });
        }

        this.currentState = null;
        this.actionPrerequisitesSequence = [];
        this.actionData = {};
        this.currentStateHandlers = {};
        this.processingAction = null;
        if (this.processingActionDefer) {
          this.processingActionDefer.reject();
        }
        rootScopeService.broadcast('event:course.registration.action.canceled');
        this.workflowEvents.next({ event: 'event:course.registration.action.canceled' });
      },
      showWorkflowModal: function () {
        if (!this.stepsInProgress) {
          $uibModal.open({
            component: 'workflowModalComponent',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              workflow: this,
            },
          });
        }
      },
      prevState: function () {
        if (
          (this.actionRequiredPrerequisitesIndex === 0 && this.currentState.name !== this.confirmationState.name) ||
          this.actionRequiredPrerequisitesIndex < 0
        ) {
          this.cancelAction();
        } else {
          if (this.actionRequiredPrerequisitesIndex > 0 && this.currentState.name !== this.confirmationState.name) {
            this.actionRequiredPrerequisitesIndex -= 1;
          }

          this.currentState = this.actionRequiredPrerequisitesSequence[this.actionRequiredPrerequisitesIndex];
          this.initCurrentStateHandlers();
        }
      },
      stepsCount: function () {
        let count = this.actionRequiredPrerequisitesSequence.length;
        if (this.confirmationState && this.confirmationState.required(this.registration, this.actionData)) {
          count++;
        }

        if (this.summaryState && this.summaryState.required(this.registration, this.actionData)) {
          count++;
        }

        return count;
      },
      nextState: function () {
        if (!this.processingAction) {
          return;
        }

        let state = false;
        if (this.actionRequiredPrerequisitesSequence.length) {
          if (this.actionRequiredPrerequisitesIndex + 1 < this.actionRequiredPrerequisitesSequence.length) {
            this.actionRequiredPrerequisitesIndex += 1;
            state = this.actionRequiredPrerequisitesSequence[this.actionRequiredPrerequisitesIndex];
          }
        }

        if (
          !state &&
          (!this.currentState || this.currentState.name !== this.confirmationState.name) &&
          this.confirmationState.required(this.registration, this.actionData)
        ) {
          state = this.confirmationState;
        }

        if (state) {
          if (angular.isDefined(state.pre)) {
            $q.when(state.pre(this.registration, this.actionData)).then(
              function () {
                this.currentState = state;
                this.initCurrentStateHandlers();
                this.showWorkflowModal();
                this._processStateHandler(state);
              }.bind(this),
            );
          } else {
            this.currentState = state;
            this.initCurrentStateHandlers();
            this.showWorkflowModal();
            this._processStateHandler(state);
          }
        } else {
          let action = workflowActions[this.processingAction];
          let actionWindow;

          if (angular.isDefined(action.getWindow)) {
            actionWindow = this.openContentWindow(action);
          }
          let oldStatusId = this.registration.status_id;
          if (!this.summaryState || !this.summaryState.required(this.registration, this.actionData)) {
            this.currentState = null;
            rootScopeService.broadcast('event:course.registration.action.closeDialogs');
            this.workflowEvents.next({ event: 'event:course.registration.action.closeDialogs' });
          }

          this.execution = true;
          let executionActionData = this.actionData;

          return this.registration.executeAction(this.processingAction, this.actionData).then(
            function () {
              let promise;

              if (action.handler) {
                promise = $q.when(action.handler(this));
              }

              if (action.notificationMessage) {
                let notificationMessage = action.notificationMessage;
                if (typeof action.getCustomMessage === 'function') {
                  let customMessage = action.getCustomMessage(oldStatusId);
                  if (customMessage) {
                    notificationMessage = customMessage;
                  }
                }
                notificationService.info(notificationMessage, 5e3);
              }

              return $q.when(promise).then(
                function () {
                  this.execution = false;
                  this.finishAction();
                  this.cancelAction(true);
                  if (this.summaryState && this.summaryState.required(this.registration, executionActionData)) {
                    this.currentState = this.summaryState;
                    this.initCurrentStateHandlers();
                    this.showWorkflowModal();
                  } else {
                    rootScopeService.broadcast('event:course.registration.action.closeDialogs');
                    this.workflowEvents.next({ event: 'event:course.registration.action.closeDialogs' });
                    let actionCallback = this.actionData.actionCallback;
                    if (actionCallback) {
                      actionCallback();
                    }
                  }
                }.bind(this),
              );
            }.bind(this),
            function (reason) {
              this.execution = false;
              this.processingAction = null;
              if (action.allowLaunchContentOnError) {
                this.launchContent(action.launchType, actionWindow);
              }
              return serverErrorHandlerService.handleForbiddenError(reason);
            }.bind(this),
          );
        }
      },
      finishAction: function () {
        let action = this.processingAction;
        this.execution = false;

        this.processingActionDefer.resolve();

        if (
          !this.skipValidationNotification &&
          this.registration &&
          this.registration.notification &&
          this.registration.notification.validation &&
          this.registration.notification.validation.message
        ) {
          notificationService.error(this.registration.notification.validation.message, 5e3);
        }

        rootScopeService.broadcast('event:course.registration.action.finished', this.registration, action);
        this.workflowEvents.next({
          event: 'event:course.registration.action.finished',
          payload: { registration: this.registration, action: action },
        });
      },
      _processStateHandler: function (state) {
        if (state) {
          if (angular.isDefined(state.handler)) {
            let promise = state.handler(this);
            if (promise) {
              promise.then(
                function () {
                  this.nextState();
                }.bind(this),
              );
            }
          }
        } else {
          this.finishAction();
          this.cancelAction();
        }
      },
      openContentWindow: function (action) {
        if (!this.actionData.onlyExecute && (!this.bypassContent || this.actionData.openBook)) {
          this.actionData.newWindow = action.getWindow(this.registration);
          return this.actionData.newWindow;
        }
        return null;
      },
      exec: function (actionName, data, options) {
        if (this.processingAction) {
          return $q.reject();
        }

        this.cancelAction();

        this.processingActionDefer = $q.defer();

        rootScopeService.broadcast('event:course.registration.action.start');
        this.workflowEvents.next({ event: 'event:course.registration.action.start' });
        angular.extend(this.actionData, data);

        if (this.registration.current_bls_id) {
          angular.extend(this.actionData, { current_bls_id: this.registration.current_bls_id });
        }

        // Save current_bls_id for the course player
        this.registration.current_bls_id = this.actionData.current_bls_id;

        this.processingAction = actionName;

        this.actionRequiredPrerequisitesSequence = [];
        if (!(options && options.skipPrerequisites)) {
          if (actionName in workflowActions && workflowActions[actionName].prerequisites) {
            this.actionPrerequisitesSequence = [].concat(workflowActions[actionName].prerequisites);
          }
        } else {
          this.actionPrerequisitesSequence = [];
        }

        if (this.registration.checkState) {
          this.actionPrerequisitesSequence = checkStatePrerequisites[this.registration.checkState].concat(
            this.actionPrerequisitesSequence,
          );
        }

        this.actionData.actionName = this.processingAction;
        if (this.actionPrerequisitesSequence.length) {
          this.actionRequiredPrerequisitesSequence = this.actionPrerequisitesSequence.filter(
            (step) => step && step.required && step.required(this.registration, this.actionData),
          );
          this.actionRequiredPrerequisitesSequenceNames = this.actionRequiredPrerequisitesSequence.map(
            (action) => action.name,
          );

          // Init start index
          this.actionRequiredPrerequisitesIndex = -1;
        }

        this.nextState();
        return this.processingActionDefer.promise;
      },
      hasAction: function (action) {
        return _.includes(this.registration.actions, action);
      },
      editRegistration: function () {
        trainingService.editRegistration(this.registration.courseId);
      },
      getAvailableCourseLaunchTypes: function () {
        /**
         * @description
         * actions that launch some content
         */
        return _.map(
          _.filter(
            _.map(workflowActions, function (value, key) {
              return {
                actionName: key,
                type: value.launchType,
                playCourseOrder: value.playCourseOrder,
                courseContent: value.courseContent,
              };
            }),
            _.bind(function (val) {
              return _.includes(this.registration.actions, val.actionName) && val.type && val.courseContent;
            }, this),
          ),
          function (v) {
            return v;
          },
        );
      },
      getAvailableLaunchTypes: function () {
        /**
         * @description
         * actions that launch some content or quiz
         */
        return _.map(
          _.filter(
            _.map(workflowActions, function (value, key) {
              return {
                actionName: key,
                type: value.launchType,
                playCourseOrder: value.playCourseOrder,
                courseContent: value.courseContent,
              };
            }),
            _.bind(function (val) {
              return _.includes(this.registration.actions, val.actionName) && val.type;
            }, this),
          ),
          function (v) {
            return v;
          },
        );
      },
      getDefaultLaunchActions: function () {
        /**
         * @description
         *  actions which provide default launch action
         */
        return _.filter(
          _.map(workflowActions, function (value, key) {
            return {
              actionName: key,
              type: value.launchType,
              default: value.defaultLaunch,
            };
          }),
          _.bind(function (val) {
            return _.includes(this.registration.actions, val.actionName) && val.type && val.default;
          }, this),
        );
      },
      getPostAssessmentLaunchActionName: function (actions) {
        return _.find(this.registration.actions, function (a) {
          return _.includes(actions, a);
        });
      },
      getPlayCourseActions: function () {
        if (!this.playCourseActions) {
          this.playCourseActions = _.filter(
            _.map(workflowActions, function (value, key) {
              return {
                name: key,
                playCourseOrder: value.playCourseOrder,
              };
            }),
            function (action) {
              return !!action.playCourseOrder && action.playCourseOrder > 0;
            },
          );
        }
        return this.playCourseActions;
      },
      hasPlayCourseActionsForRegistration: function () {
        let playActions = this.getPlayCourseActions();
        return this.registration && _.intersection(this.registration.actions, _.map(playActions, 'name')).length > 0;
      },
      getCheckStateNotification: function () {
        const message = registrationWorkflowService.getCheckStateNotification(
          this.registration.checkState || this.registration.state,
          this.registration.course,
        );

        if (message) {
          return message;
        }

        if (this.registration.status_id === 1) {
          return 'The registration is not yet approved.';
        }
      },

      showCheckStateNotification: function () {
        if (this.registration.state || this.registration.checkState) {
          notificationService.info(this.getCheckStateNotification(), 3e3);
        }
      },
    });

    return Workflow;
  }
})();
