(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.course.common.service:sessionsService
   * @description
   * This service is used to obtain sessions data
   *
   */

  angular.module('kmi.lms.course.common').factory('sessionsService', sessionsService);

  /* @ngInject */
  function sessionsService($http, $uibModal, _, courseRegistrationStatus) {
    return {
      get: get,
      cloneSession: cloneSession,
      deleteSession: deleteSession,
      processSessionSchedules: processSessionSchedules,
      userHasIncompletedSessions: userHasIncompletedSessions,
      getUserIncompletedSessions: getUserIncompletedSessions,
      getScheduleDefaults: getScheduleDefaults,
      hasOnlineMeetings: hasOnlineMeetings,
      getCourseSessionRoles: getCourseSessionRoles,
    };

    /**
     * @description
     * Retrieves sessions data for current user.
     * @returns {IPromise<TResult>|*}
     */
    function get(params) {
      return $http.get('/a/course/sessions/', { params: params }).then(function (response) {
        return response.data;
      });
    }

    function cloneSession(session, saveCourseBeforeCloning) {
      var modalInstance = $uibModal.open({
        component: 'cloneCourseSessionModal',
        resolve: {
          session: function () {
            return session;
          },
          saveCourseBeforeCloning: function () {
            return saveCourseBeforeCloning;
          },
        },
      });

      return modalInstance.result;
    }

    function deleteSession(courseSession) {
      var modalInstance = $uibModal.open({
        component: 'deleteCourseSessionModal',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          courseSession: function () {
            return courseSession;
          },
        },
      });

      return modalInstance.result;
    }

    function processSessionSchedules(data) {
      return _.map(_.groupBy(data, 'scheduleDate'), function (schedules, date) {
        var map = {};
        var locations = [];

        _.each(schedules, function (schedule) {
          if (schedule.location || schedule.internetLocation) {
            var location = schedule.location || {};
            location.times = [];

            if (!map[schedule.locationId]) {
              map[schedule.locationId] = location;
              locations.push(location);
            }

            map[schedule.locationId].times.push({
              startDateTime: schedule.startDateTime,
              endDateTime: schedule.endDateTime,
            });
          }
        });

        return {
          date: date,
          startDateTime: schedules[0].startDateTime,
          endDateTime: schedules[schedules.length - 1].endDateTime,
          locationDescription: schedules[0].locationDescription,
          onlineMeetingIntegration: schedules[0].onlineMeetingIntegration,
          internetLocation: schedules[0].internetLocation,
          locations: locations,
          languages: schedules[0].languages,
        };
      });
    }

    function userHasIncompletedSessions(sessions) {
      return _.some(sessions, function (session) {
        return session.status_id && !_.includes(courseRegistrationStatus.terminalSet, session.status_id);
      });
    }

    function getCourseSessionRoles(courseId, sessionId) {
      return $http
        .get(['/a/course/', courseId, '/sessions/', sessionId, '/registrations/roles/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function getUserIncompletedSessions(sessions) {
      var incompletedSessions = _.filter(sessions, function (session) {
        return session.status_id && !_.includes(courseRegistrationStatus.terminalSet, session.status_id);
      });

      return incompletedSessions.length ? incompletedSessions : null;
    }

    function getScheduleDefaults(course) {
      if (course.format === 119) {
        return {
          onlineMeetingIntegration: true,
          meetingProviderId: 2,
        };
      }

      if (course.format === 120) {
        return {
          onlineMeetingIntegration: true,
          meetingProviderId: 3,
        };
      }

      if (course.format === 122) {
        return {
          onlineMeetingIntegration: true,
          meetingProviderId: 4,
        };
      }

      if (course.format === 124) {
        return {
          onlineMeetingIntegration: true,
          meetingProviderId: 5,
        };
      }

      if (course.format === 126) {
        return {
          onlineMeetingIntegration: true,
          meetingProviderId: 6,
        };
      }

      if (course.format === 127) {
        return {
          onlineMeetingIntegration: true,
          meetingProviderId: 7,
        };
      }

      return null;
    }

    function hasOnlineMeetings(sessions) {
      // Any session schedule should have breeze_integration flag turned on
      return _.some(sessions, function (session) {
        return _.some(session.schedules, 'onlineMeetingIntegration');
      });
    }
  }
})();
