declare const angular: angular.IAngularStatic;

export class NotificationService {
  private _suggestion: any = null;
  private _visibleSuggestion = false;
  private _visibleInfo = false;
  private _visibleError = false;
  private _messageInfo = '';
  private _messageError = '';
  private _timeoutPromiseError: any;
  private _timeoutPromiseInfo: any;
  private _timeoutPromiseSuggestion: any;

  private $timeout: any;

  static $inject = ['$timeout'];
  constructor($timeout: any) {
    this.$timeout = $timeout;
  }

  private setMessageError(message: string, timeout: number) {
    this._messageError = message;
    this._visibleError = true;

    // if timeout is specified
    if (angular.isDefined(timeout)) {
      // cancel the previous' message timeout
      if (angular.isDefined(this._timeoutPromiseError)) {
        this.$timeout.cancel(this._timeoutPromiseError);
      }
      // start timer
      this._timeoutPromiseError = this.$timeout(() => {
        this._visibleError = false;
        this.$timeout.cancel(this._timeoutPromiseError);
      }, timeout);
    }
  }

  private setMessageInfo(message: string, timeout?: number) {
    this._messageInfo = message;
    this._visibleInfo = true;
    // if timeout is specified
    if (angular.isDefined(timeout)) {
      // cancel the previous' message timeout
      if (angular.isDefined(this._timeoutPromiseInfo)) {
        this.$timeout.cancel(this._timeoutPromiseInfo);
      }
      // start timer
      this._timeoutPromiseInfo = this.$timeout(() => {
        this._visibleInfo = false;
        this.$timeout.cancel(this._timeoutPromiseInfo);
      }, timeout);
    }
  }

  private setSuggestInfo(value: any, timeout?: number) {
    this._suggestion = value;
    this._visibleSuggestion = true;
    // if timeout is specified
    if (angular.isDefined(timeout)) {
      // cancel the previous' message timeout
      if (angular.isDefined(this._timeoutPromiseSuggestion)) {
        this.$timeout.cancel(this._timeoutPromiseSuggestion);
      }
      // start timer
      this._timeoutPromiseSuggestion = this.$timeout(() => {
        this._visibleSuggestion = false;
        this.$timeout.cancel(this._timeoutPromiseSuggestion);
      }, timeout);
    }
  }

  public hideInfo() {
    this._visibleInfo = false;
  }

  public hideError() {
    this._visibleError = false;
  }

  public hideSuggestion() {
    this._visibleSuggestion = false;
  }

  public dismissSuggestion() {
    if (this._suggestion && this._suggestion.closeHandler) {
      this._suggestion.closeHandler();
    }
    this._visibleSuggestion = false;
  }

  public error(msg: string, timeout: number) {
    if (angular.isUndefined(msg)) {
      return this._messageError;
    }
    this.setMessageError(msg, timeout);
  }

  public info(msg: string, timeout?: number) {
    if (angular.isUndefined(msg)) {
      return this._messageInfo;
    }
    this.setMessageInfo(msg, timeout);
  }

  public visibleError(value: boolean) {
    if (angular.isUndefined(value)) {
      return this._visibleError;
    }
    this._visibleError = value;
  }

  public visibleInfo(value: boolean) {
    if (angular.isUndefined(value)) {
      return this._visibleInfo;
    }
    this._visibleInfo = value;
  }

  public visibleSuggestion(value: boolean) {
    if (angular.isUndefined(value)) {
      return this._visibleSuggestion;
    }
    this._visibleSuggestion = value;
  }

  public suggest(value: any, timeout: number) {
    if (angular.isUndefined(value)) {
      return this._suggestion;
    }
    this.setSuggestInfo(value, timeout);
  }
}
