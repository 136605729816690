(function () {
  angular.module('kmi.lms.course.reviews').directive('editCourseReview', function () {
    return {
      restrict: 'EA',
      scope: {
        course: '=course',
        review: '=',
        cancelHandler: '&onCancel',
        fullStart: '=?',
        hideCancel: '=?',
        trackingName: '<?',
        addHandler: '&onAdd',
      },
      template: require('ajs/modules/course/reviews/edit/edit-review.html').default,
      controller: EditCourseReviewController,
      controllerAs: 'vm',
      bindToController: true,
    };
  });

  /* @ngInject */
  function EditCourseReviewController(
    rootScopeService,
    $scope,
    $state,
    $q,
    serverErrorHandlerService,
    UserNetworkSettings,
    userSettingsService,
    globalConfig,
    currentUser,
    CourseReview,
    UserNetworkActivity,
    CourseReviewServiceUpgraded,
    notificationService,
  ) {
    var vm = this,
      user = currentUser.get();

    vm.stars = ['Poor', 'Below average', 'Average', 'Above average', 'Excellent'];
    vm.starRating = null;
    vm.hoverRating = null;
    vm.jobRoleEnabled = globalConfig.settings.courseDetails.reviewsJobRoleEnabled;
    vm.enableRecommendation =
      userSettingsService.user_network_enabled &&
      !!$state.get('main.user_network') &&
      user.userNetwork &&
      user.userNetwork.profile;

    vm.hoverOver = hoverOver;
    vm.save = save;
    vm.cancel = cancel;

    vm.$onInit = activate;

    //////////

    function activate() {
      if (vm.review) {
        vm.reviewDraft = angular.copy(vm.review); // Make a copy of the review in order to have an ability to decline changes
        vm.starRating = vm.reviewDraft.rating;
      }
      $scope.$watch('vm.starRating', changeRating);

      if (vm.jobRoleEnabled) {
        UserNetworkSettings.getJobRoles(function (jobRoles) {
          vm.jobRoles = jobRoles;
        });
      }
    }

    /**
     * @description
     * Event handler for UIB rating on-hover, updates the vm.hoverRating value
     * @param [Number] value - New Value of Temp Rating
     */
    function hoverOver(value) {
      vm.hoverRating = value;
    }

    /**
     * @description
     * Updates review rating when stars was changed
     * @param [Number] value - New Value of Rating
     */
    function changeRating(value) {
      if (value) {
        if (!vm.review) {
          vm.review = CourseReview.newReview({ courseId: vm.course.id });
          vm.reviewDraft = angular.copy(vm.review);
        }
        vm.starsError = false;
        vm.reviewDraft.rating = value;
      }
    }

    /**
     * @description
     * Cancels editing or creation review
     */
    function cancel() {
      vm.reviewDraft = null;
      vm.starRating = null;
      vm.cancelHandler();
    }

    /**
     * @description
     * Save new review
     * @param index
     */
    function save() {
      vm.submitted = true;

      if (!vm.starRating) {
        vm.starsError = true;
        return;
      }

      if (vm.editReviewForm.$valid) {
        vm.savePromise = (
          vm.reviewDraft.id
            ? CourseReviewServiceUpgraded.update(vm.course.id, vm.reviewDraft.id, vm.reviewDraft, true)
            : CourseReviewServiceUpgraded.save(vm.course.id, vm.reviewDraft, true)
        ).toPromise();

        $q.all([
          vm.savePromise,
          vm.enableRecommendation && vm.reviewDraft.recommendCourse
            ? UserNetworkActivity.save({
                activityOrigin: 'user',
                activityTypeId: 9,
                objectId: vm.course.id,
                text: vm.reviewDraft.comments || 'Check out this course:',
              }).$promise
            : $q.when(true),
        ])
          .then(function (results) {
            //Apply saved data to the original review object
            angular.extend(vm.review, results[0]);
            rootScopeService.broadcast('event:user:course:review:added', vm.review);
            if (angular.isFunction(vm.addHandler)) {
              vm.addHandler({ review: vm.review });
            }
          }, showHandledServerError)
          .finally(function () {
            vm.savePromise = null;
          });
      }
    }

    function showHandledServerError(reason) {
      return notificationService.error(reason.error, 5e3);
    }
  }
})();
