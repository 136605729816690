(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.course.common.service:courseSessionOptionsService
   * @description
   * This service is used to obtain course session dependant options, lookups
   *
   */

  angular.module('kmi.lms.course.common').factory('courseSessionOptionsService', courseSessionOptionsService);

  /* @ngInject */
  function courseSessionOptionsService($http, $cacheFactory) {
    var courseSessionOptionsCache = $cacheFactory('courseSessionOptions');

    return {
      getOptions: getOptions,
      getLabels: getLabels,
      getDefaultSessionRoles: getDefaultSessionRoles,
    };

    /**
     * @description
     * Retrieves session lookups and stores them in the cache.
     * @returns {IPromise<TResult>|*}
     */
    function getOptions() {
      return $http.get('/a/course/sessions/options/', { cache: courseSessionOptionsCache }).then(function (response) {
        return response.data;
      });
    }

    function getLabels(query) {
      return $http.get('/a/course/sessions/labels/', { cache: true, params: query }).then(function (response) {
        return response.data;
      });
    }

    function getDefaultSessionRoles() {
      return [
        { name: 'Player', limit: null, registrationExists: false, activeRegistrationExists: false },
        { name: 'Evaluator', limit: null, registrationExists: false, activeRegistrationExists: false },
        { name: 'Controller', limit: null, registrationExists: false, activeRegistrationExists: false },
        { name: 'Facilitator', limit: null, registrationExists: false, activeRegistrationExists: false },
        { name: 'Observer', limit: null, registrationExists: false, activeRegistrationExists: false },
        { name: 'Planner', limit: null, registrationExists: false, activeRegistrationExists: false },
        { name: 'Simulator', limit: null, registrationExists: false, activeRegistrationExists: false },
      ];
    }
  }
})();
