(function () {
  angular.module('kmi.lms.portals').factory('portalsService', portalsService);

  /* @ngInject */
  function portalsService($http, _) {
    return {
      getUserPortals: getUserPortals,
      getPortalPath: getPortalPath,
    };

    function getUserPortals() {
      return $http.get('/a/user/me/portals/').then(function (response) {
        return response.data;
      });
    }

    function getPortalPath(portal) {
      if (portal.basePath) {
        return ['https://', portal.domainName, ['/', _.trim(portal.basePath, '/'), '/'].join('')].join('');
      } else {
        return ['https://', portal.domainName].join('');
      }
    }
  }
})();
