(function () {
  angular.module('kmi.lms.core').factory('policiesService', function ($http, apiUrl) {
    return {
      getPolicies: getPolicies,
    };

    function getPolicies() {
      return $http.get(apiUrl('/a/about_elms/site_policies/')).then(function (response) {
        return response.data;
      });
    }
  });
})();
