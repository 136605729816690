(function () {
  angular.module('kmi.lms.core').component('mainNavigationMenuItems', {
    template: require('ajs/modules/app/navigation/components/main-navigation-menu-items.html').default,
    controller: MainNavigationMenuItemsController,
    controllerAs: 'vm',
    bindings: {
      menuItems: '<',
      onMenuClick: '&?',
      showSeparator: '<?',
    },
  });

  function MainNavigationMenuItemsController($state) {
    const vm = this;

    vm.state = $state;
  }
})();
