(function () {
  angular.module('kmi.lms.course.common').component('recommendCourse', {
    template: require('ajs/modules/course/common/recommend-course.html').default,
    controller: RecommendCourseController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
  });

  /* @ngInject */
  function RecommendCourseController(UserNetworkActivity, notificationService) {
    var vm = this;

    vm.trackingName = 'course_details_share_course';

    vm.cancel = cancel;

    vm.$onInit = function () {
      vm.course = vm.resolve.course;
    };

    vm.save = save;

    function save() {
      vm.saving = true;

      UserNetworkActivity.save({
        activityOrigin: 'user',
        activityTypeId: 9,
        objectId: vm.course.id,
        text: vm.actionComment || 'Check out this course:',
      })
        .$promise.then(function () {
          notificationService.info('Shared in the Community feed.', 3e3);
          vm.modalInstance.close();
        })
        .finally(function () {
          vm.saving = null;
        });
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
