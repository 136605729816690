(function () {
  angular.module('kmi.lms.course.registrations').component('selectRegistrationCreditTypesDialog', {
    bindings: {
      workflow: '=',
    },
    template: require('ajs/modules/course/registrations/components/dialogs/select-credit-types.html').default,
    controller: RegistrationCreditTypesDialogController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function RegistrationCreditTypesDialogController(
    $scope,
    $q,
    currentUser,
    attributesService,
    userAttributesService,
    courseRegistrationStatus,
    _,
  ) {
    const vm = this;

    vm.save = save;
    vm.requiredChanged = requiredChanged;
    vm.$onInit = onInit;

    function getPrevButtonName() {
      if (vm.stepsCount === 2 && vm.currentStep === 2) {
        return 'Back';
      }
    }

    function onInit() {
      $scope.$watch('vm.workflow.currentState', (newValue) => {
        if (newValue && ['selectCreditType', 'changeCreditType'].includes(newValue.name)) {
          vm.workflow.currentStateHandlers.nextHandler = save;
          vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
          if (vm.stepsCount === 2) {
            vm.workflow.currentStateHandlers.prevStateHandler = back;
            vm.workflow.currentStateHandlers.prevStateNameHandler = getPrevButtonName;
          }
        }
      });

      // init credits steps
      vm.stepsCount = 1;
      vm.currentStep = 1;
      if (
        vm.workflow.actionRequiredPrerequisitesSequenceNames.includes('selectCreditType') &&
        (!vm.workflow.registration.id || vm.workflow.registration.statusId === courseRegistrationStatus.notStarted)
      ) {
        vm.stepsCount += 1;
      }

      let creditTypes = _.get(vm.workflow, 'registration.creditTypes');
      if (vm.stepsCount === 2) {
        vm.creditsRequired = true;
      }

      vm.attributes = [];

      vm.workflow.actionData.creditTypes =
        creditTypes && creditTypes.length
          ? creditTypes
          : !_.get(vm.workflow, 'registration.course.providesMultipleCredits') &&
              _.get(vm.workflow, 'currentState.name') === 'changeCreditType'
            ? [{ id: null, creditType: { name: 'None', id: -1 } }]
            : [];

      vm.lockCredits =
        !_.get(vm.workflow, 'registration.course.providesMultipleCredits') &&
        _.get(vm.workflow, 'currentState.name') !== 'changeCreditType' &&
        _.get(vm.workflow, 'registration.id') &&
        ![courseRegistrationStatus.notStarted].includes(_.get(vm.workflow, 'registration.statusId'));
    }

    function back() {
      if (vm.currentStep === 2 && vm.stepsCount === 2) {
        vm.currentStep = 1;
      } else {
        vm.workflow.prevState();
      }
    }

    function save() {
      if (vm.currentStep === 1 && vm.stepsCount === 2 && vm.creditsRequired) {
        vm.currentStep = 2;
      } else {
        vm.processing = true;

        saveAttributes()
          .then(function () {
            vm.workflow.nextState();
          })
          .finally(() => {
            vm.processing = null;
          });
      }
    }

    function requiredChanged() {
      if (vm.stepsCount === 2 && vm.currentStep === 1 && !vm.creditsRequired) {
        vm.workflow.actionData.creditTypes = [];
      }
    }

    function isContinueDisabled() {
      return (vm.selectCreditsForm && vm.selectCreditsForm.$invalid) || vm.processing || vm.loading;
    }

    function saveAttributes() {
      if (vm.attributes.length && vm.selectCreditsForm.$valid) {
        vm.attributes = _.uniq(vm.attributes);
        attributesService.formatResultData(vm.attributes);
        return userAttributesService.save(currentUser.get().id, vm.attributes);
      }
      return $q.resolve();
    }
  }
})();
