(function () {
  angular.module('kmi.lms.core').factory('menuService', menuService);

  /* @ngInject */
  function menuService($state, $injector, securityService, currentUser, globalConfig, _, rootScopeService) {
    var adminMenu;

    rootScopeService.on('event:userInfoLoaded', function () {
      adminMenu = _buildAdminMenu();
    });

    return {
      getMenuItems: getMenuItems,
      getAdminMenuItems: getAdminMenuItems,
      isMenuItem: isMenuItem,
      getFirstMenuState: getFirstMenuState,
      buildMenuLink: buildMenuLink,
    };

    function isMenuItem(stateName) {
      return _.some(globalConfig.menu, ['stateName', stateName]);
    }

    function getMenuItems() {
      var menu = [];

      _setupAdminRoot();

      angular.forEach(globalConfig.menu, function (menuItem) {
        if (_isMenuAvailable(menuItem)) {
          menu.push(createTab(menuItem));
        }
      });

      return menu;

      // gets menu item object
      function createTab(menuItem) {
        return {
          title: menuItem.name,
          type: menuItem.type,
          state: angular.isDefined(menuItem.stateName) ? menuItem.stateName : '',
          stateParams: menuItem.stateParams,
          link: buildMenuLink(menuItem),
          target: angular.isDefined(menuItem.target) ? menuItem.target : '_self',
          icon: angular.isDefined(menuItem.icon) ? menuItem.icon : '',
          backUrl: menuItem.backUrl,
        };
      }
    }

    function buildMenuLink(menuItem) {
      let linkTemplate = _.template(menuItem.href);

      return angular.isDefined(menuItem.href)
        ? linkTemplate(
            angular.extend({
              basePath: globalConfig.base,
              externalDomain: globalConfig.externalDomain,
            }),
          )
        : angular.isDefined(menuItem.stateName)
          ? $state.href(menuItem.stateName)
          : '';
    }

    function getAdminMenuItems() {
      if (!adminMenu) {
        adminMenu = _buildAdminMenu();
      }
      return adminMenu;
    }

    function _isMenuAvailable(menuItem) {
      if (menuItem.stateName) {
        var available = securityService.isStateAvailable(menuItem.stateName);

        return _.get(menuItem, 'data.availableFor')
          ? available && securityService.isPageAvailable(menuItem)
          : available;
      }

      return securityService.isPageAvailable(menuItem);
    }

    function _buildAdminMenu() {
      var user = currentUser.get();

      if (!user.roles || !user.roles.length) {
        return [];
      }

      return collectMenu();

      function collectMenu(parentMenuItem?) {
        var menuItems;

        if (!parentMenuItem) {
          menuItems = angular.copy(globalConfig.adminMenuItems);
        } else {
          menuItems = parentMenuItem.children;
        }

        var filtered = _.filter(menuItems || [], function (item) {
          // Filter out menu items denied for the current environment
          if (
            _.get(item, 'data.environment') &&
            !_.includes(_.get(item, 'data.environment'), globalConfig.environment)
          ) {
            return false;
          }

          var availableChildren = collectMenu(item);

          if (item.children && item.children.length) {
            var roles = _collectAllRoles(item.children);

            if (item.state) {
              var state = $state.get(item.state);
              if (state) {
                if (!state.data) {
                  state.data = {};
                }

                state.data.adminRoleLimits = roles.concat(state.data.adminRoleLimits || []);
              }
            } else {
              item.roleIds = roles.concat(item.roleIds || []);
            }
          }

          item.children = availableChildren;

          if (item.link) {
            item._link = item.link;
            Object.defineProperty(item, 'link', {
              get: function () {
                var backUrlParam = $injector.get('backUrlService').external.getBackUrlParam();

                if (item.includeBackUrl) {
                  return [item._link, item._link.indexOf('?') === -1 ? '?' : '&', backUrlParam].join('');
                }
                return item._link;
              },
            });
          }

          if (item.roleIds && !user.isInRole(item.roleIds)) {
            return false;
          }

          if (item.roleIdsExclusions && user.isInRole(item.roleIdsExclusions)) {
            return false;
          }

          return !(item.state && !securityService.isStateAvailable(item.state));
        });

        return !parentMenuItem
          ? _.filter(filtered, function (item) {
              var state = $state.get(item.state);
              return (item.children && item.children.length > 0) || item.link || (state && !state.template);
            })
          : filtered;
      }
    }

    function _setupAdminRoot() {
      let adminRoot = $state.get('main.admin');
      if (adminRoot) {
        if (!adminRoot.data) {
          adminRoot.data = {};
        }

        const roles = _collectAllRoles(getAdminMenuItems());
        adminRoot.data.adminRoleLimits = roles.concat(adminRoot.data.adminRoleLimits || []);
      }
    }

    function _collectAllRoles(collection) {
      return _.uniq(
        _.reduce(
          collection,
          (roles, child) => {
            if (child.roleIds && child.roleIds.length) {
              // replace state roles with menu roles
              return child.roleIds ? roles.concat(child.roleIds) : roles;
            } else {
              if (child.state) {
                let state = $state.get(child.state);

                return _.get(state, 'data.adminRoleLimits') ? roles.concat(state.data.adminRoleLimits) : roles;
              } else {
                return roles;
              }
            }
          },
          [],
        ),
      );
    }

    function getFirstMenuState() {
      for (var i = 0; i < globalConfig.menu.length; i++) {
        if (_isMenuAvailable(globalConfig.menu[i]) && !globalConfig.menu[i].external) {
          return globalConfig.menu[i];
        }
      }
      if (securityService.isStateAvailable('prompt.login')) {
        return { stateName: 'prompt.login' };
      } else {
        return null;
      }
    }
  }
})();
