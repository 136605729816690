(function () {
  angular.module('kmi.lms.course.common').component('courseEditActionsUpgraded', {
    template: require('ajs/modules/course/common/course-edit-actions-component.html').default,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      actions: '@',
      disabled: '<?',
    },
  });
})();
