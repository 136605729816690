(function () {
  angular.module('kmi.lms.course.reviews').component('courseReviewsGraph', {
    template: require('ajs/modules/course/reviews/graph.html').default,
    controller: CourseReviewsGraphController,
    controllerAs: 'vm',
    bindings: {
      stars: '=',
      starsTemplate: '=?',
      selectedStar: '=?',
      blocker: '=?',
      trackingName: '=',
    },
  });

  /* @ngInject */
  function CourseReviewsGraphController($scope) {
    var vm = this;
    vm.allStarsAmount = 0;

    vm.$onInit = onInit;

    function onInit() {
      vm.starsTemplate = vm.starsTemplate || [5, 4, 3, 2, 1];

      $scope.$watch('vm.stars', function () {
        for (var i in vm.stars) {
          vm.allStarsAmount += vm.stars[i].amount;
        }
      });
    }
  }
})();
