(function () {
  angular.module('kmi.lms.course.reviews').factory('courseReviewsService', courseReviewsService);

  /* @ngInject */
  function courseReviewsService($q, $http, $window, $httpParamSerializer, moment, CourseReview, courseReviewsConst) {
    var params = {},
      hasMoreItems = true,
      total = 0,
      loaded = 0;

    return {
      query: function (query, totalCount) {
        angular.extend(params, query);
        total = totalCount;
        return performSearch(0);
      },
      conditions: params,
      next: searchNext,
      updateStat: updateStat,
      exportReviews: exportReviews,
    };

    /**
     * @description
     * Updates course ratings data from server
     * @param course
     * @returns {*|angular.IPromise<TResult>}
     */
    function updateStat(course) {
      return $http.get(['/a/course/', course.id, '/reviews/stat/'].join('')).then(function (response) {
        angular.extend(course.ratings, response.data); // Update course ratings info with the new data
        total = response.data.count;
        return response.data;
      });
    }

    function performSearch(offset) {
      params.offset = offset;
      if (!params.take) {
        params.take = courseReviewsConst.REQUEST_ITEM_COUNT;
      }

      loaded = offset ? loaded : 0;

      return CourseReview.query(params, function (response) {
        loaded += response.items.length;
        total = response.total;
        hasMoreItems = loaded < total;
        return response;
      }).$promise;
    }

    function searchNext() {
      if (hasMoreItems) {
        return performSearch((params.offset || 0) + courseReviewsConst.REQUEST_ITEM_COUNT);
      } else {
        var def = $q.defer();
        def.resolve({ items: [] });
        return def.promise;
      }
    }

    function exportReviews(courseId: number, options: any) {
      const fileName =
        (options && options.fileName) ||
        ['reviews_for_course_', courseId, '_', moment(new Date()).format('YYYY-MM-DD')].join('');
      const format = (options && options.format) || 'csv';
      const params = {
        includeInactive: true,
        offset: 0,
        take: 999999,
        format: format,
        file_name: fileName,
      };
      $window.location.assign(['/a/course/', courseId, '/reviews/?', $httpParamSerializer(params)].join(''));
    }
  }
})();
