(function () {
  angular.module('kmi.lms.course.registrations').component('conferenceRegistrationDetails', {
    template:
      require('ajs/modules/course/registrations/view/formats/collection/conference/conference-registration-details.html')
        .default,
    controller: ConferenceRegistrationDetailsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      registration: '=',
    },
  });

  /* @ngInject */
  function ConferenceRegistrationDetailsController(courseComponentsService, moment, _, courseRegistrationStatus) {
    var vm = this;
    vm.$onInit = onInit;
    vm.hasCredits = hasCredits;

    vm.loading = true;
    function onInit() {
      courseComponentsService
        .getCompilationStructure(vm.course.id, vm.registration.userId, { view: 'user' })
        .then(function (response) {
          processSessions(response.structure);
        })
        .finally(() => {
          vm.loading = null;
        });
    }
    function processSessions(compilationStructure) {
      let conferenceDescendants = courseComponentsService.getFlattenCourseList(compilationStructure);
      conferenceDescendants = _.map(conferenceDescendants, function (course) {
        if (!course.registration) {
          return course;
        }

        return angular.extend(course, {
          registration: _.includes(courseRegistrationStatus.declinedSet, course.registration.lastRegistration.statusId)
            ? course.registration.lastCompletedRegistration
            : course.registration.lastRegistration,
        });
      });

      conferenceDescendants = _.filter(conferenceDescendants, function (course) {
        return course.registration && !courseRegistrationStatus.declinedSet.includes(course.registration.statusId);
      });

      const dates = _.groupBy(conferenceDescendants, (collectionComponent) => {
        return moment(collectionComponent.startDate).startOf('day');
      });
      vm.sessionDates = Object.keys(dates).map((key) => ({ date: dates[key][0].startDate, sessions: dates[key] }));
    }
    function hasCredits(registration) {
      return registration && registration.creditTypes && registration.creditTypes.length;
    }
  }
})();
