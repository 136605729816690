(function () {
  angular.module('kmi.lms.course.common').factory('playCourseService', playCourseService);

  /* @ngInject */
  function playCourseService(moment, _, courseRegistrationStatus, approvalStatus, courseEnums, globalConfig) {
    const notStartedStatuses = [
      courseRegistrationStatus.notStarted,
      courseRegistrationStatus.withdrawn,
      courseRegistrationStatus.declined,
    ];
    const formatType = courseEnums.formatType;

    return {
      isCourseInCreateRegistrationState: isCourseInCreateRegistrationState,
      isCourseInRestartState: isCourseInRestartState,
      isEvaluationLaunchAvailable: isEvaluationLaunchAvailable,
      componentsScrollAvailable: componentsScrollAvailable,
      isExpired: isExpired,
      isBuyNowAvailable: isBuyNowAvailable,
      isCoursePlayAvailable: isCoursePlayAvailable,
      isSinglePlayCourse: isSinglePlayCourse,
      saveForLaterAvailable: saveForLaterAvailable,
      isPrintCourseAvailable: isPrintCourseAvailable,
      isAttendTheMeetingBtnAvailable: isAttendTheMeetingBtnAvailable,
      getAvailablePlayCourseActions: getAvailablePlayCourseActions,
    };

    function isPrintCourseAvailable(course, userCourse) {
      return (
        (userCourse &&
          userCourse.workflow &&
          userCourse.workflow.registration &&
          userCourse.workflow.registration.certificates_data &&
          userCourse.workflow.registration.certificates_data.print_status === 1) ||
        (course &&
          userCourse &&
          (course.certificate || _.get(course, 'certificates.length')) &&
          _.includes(courseRegistrationStatus.strictCompletedSet, userCourse.statusId) &&
          [6, 41, 42].indexOf(_.get(userCourse, 'lastCompletedRegistration.statusId')) < 0 &&
          (course.certificate ||
            hasCertificateForLastCompletedRegistration(_.get(userCourse, 'lastCompletedRegistration'), course)))
      );
    }

    function hasCertificateForLastCompletedRegistration(registration, course) {
      if (!registration) {
        return false;
      }

      let registrationCreditTypes =
        registration && registration.creditTypes
          ? registration.creditTypes.map((creditType) => creditType.creditType.id)
          : [];

      if (course.certificate) {
        return registrationCreditTypes.length > 0
          ? registrationCreditTypes.includes(course.certificate.creditTypeId)
          : !course.certificate.creditTypeId;
      } else {
        if (registrationCreditTypes.length > 0) {
          return course.certificates.some((certificate) => registrationCreditTypes.includes(certificate.creditTypeId));
        } else {
          return course.certificates.some((certificate) => !certificate.creditTypeId);
        }
      }
    }

    function saveForLaterAvailable(course, userCourse) {
      // isCourseInCreateRegistrationState is false when price exists
      return (
        course.active &&
        course.courseFormat.registrationRequired &&
        course.approvalStatusId === approvalStatus.approved &&
        (!_.get(userCourse, 'id') ||
          ((isCourseInCreateRegistrationState(course, userCourse, false, true) ||
            isCourseInRestartState(course, userCourse)) &&
            (_.includes([3, 6, 42], userCourse.statusId) || !userCourse.statusId)))
      );
    }

    function isCourseInCreateRegistrationState(course, userCourse, skipPriceCheck, skipExpiredCheck) {
      if (isRegistrationDeadline(course)) {
        return false;
      }

      let isAvailableByPrice =
        skipPriceCheck ||
        !_.get(globalConfig, 'settings.ecommerceEnabled') ||
        !_.get(course, 'price.amount') ||
        (userCourse && checkAccessExpiration(userCourse));

      if ((skipExpiredCheck || !isExpired(course)) && isAvailableByPrice) {
        return !_.get(userCourse, 'id') || _.includes(notStartedStatuses, userCourse.statusId);
      } else {
        return false;
      }
    }

    function isCourseInRestartState(course, userCourse) {
      if (course.allowRegisterOnce) {
        return false;
      }

      if (
        !isExpired(course) &&
        userCourse &&
        (_.includes([41, 42], userCourse.statusId) ||
          (_.includes(courseRegistrationStatus.strictCompletedSet, userCourse.statusId) &&
            !isPrintCourseAvailable(course, userCourse)))
      ) {
        if (userCourse.workflow) {
          return (
            userCourse.workflow.registration &&
            userCourse.workflow.hasPlayCourseActionsForRegistration() &&
            (!userCourse.workflow.registration.id ||
              _.includes(notStartedStatuses, userCourse.workflow.registration.status_id))
          );
        } else {
          if (
            course.registrationAttemptsLimit > 0 &&
            course.registrationAttemptsLimit <= userCourse.completedRegistrationsCount
          ) {
            return false;
          }
          return (
            (isLastRegistrationCompleted(userCourse) ||
              (userCourse.lastRegistration && _.includes([42, 10, 6, 3], userCourse.lastRegistration.statusId))) &&
            (!userCourse.accessExpirationDate || checkAccessExpiration(userCourse))
          );
        }
      } else {
        return false;
      }
    }

    function isEvaluationLaunchAvailable(course, userCourse) {
      return _.get(userCourse, 'lastRegistration.statusId') === courseRegistrationStatus.evaluationPending;
    }

    function isCoursePlayAvailable(course, userCourse, mode) {
      // If registration to course is not open or course is not published continue prohibited
      if (!isRegistrationToCourseOpen(course)) {
        return false;
      }

      // For online courses we always show continue when exists available action except course registration creation actions
      // For collections continue shows only when exists assessments or evaluations
      if (isCourseInCreateRegistrationState(course, userCourse)) {
        return true;
      }

      if (isCourseInRestartState(course, userCourse)) {
        return true;
      } else {
        if (
          isSinglePlayCourse(mode) &&
          userCourse &&
          _.includes(courseRegistrationStatus.strictCompletedSet, userCourse.statusId)
        ) {
          return false;
        }

        if (isEvaluationLaunchAvailable(course, userCourse)) {
          return true;
        } else {
          if (userCourse && userCourse.statusId === courseRegistrationStatus.pending) {
            // Pending registration requires internal process for next statuses.
            // For the "train" it is allowed with external payments. We need to check that manage recipe of payment
            // is enabled and course has prices for the current date
            if (course.courseUrl && course.paymentApproval && course.multiplePrices && course.multiplePrices.length) {
              return true;
            }

            // all other cases
            return false;
          }

          if (userCourse && userCourse.workflow && userCourse.workflow.registration) {
            return (
              userCourse.workflow.registration.id &&
              (((userCourse.workflow.hasPlayCourseActionsForRegistration() ||
                (course.formatTypeId === formatType.collection &&
                  userCourse.workflow.registration.status_id === courseRegistrationStatus.inProgress)) &&
                !_.includes(notStartedStatuses, userCourse.workflow.registration.status_id)) ||
                (course.formatTypeId === formatType.liveEvent &&
                  course.sessions &&
                  course.sessions.length &&
                  _.includes(courseRegistrationStatus.incompleteSet, userCourse.workflow.registration.status_id)))
            );
          } else {
            const availableByPrice =
              !_.get(course, 'price.amount') ||
              (userCourse &&
                (checkAccessExpiration(userCourse) ||
                  (!userCourse.hasActiveAssessment &&
                    userCourse.lastRegistration &&
                    userCourse.lastRegistration.statusId === courseRegistrationStatus.inProgress)));

            return (
              availableByPrice &&
              userCourse &&
              userCourse.lastRegistration &&
              !_.includes(notStartedStatuses, userCourse.lastRegistration.statusId) &&
              !isLastRegistrationCompleted(userCourse)
            );
          }
        }
      }
    }

    function isSinglePlayCourse(mode) {
      return mode === 'component';
    }

    function isLastRegistrationCompleted(userCourse) {
      return (
        userCourse.lastCompletedRegistration &&
        userCourse.lastRegistration.id === userCourse.lastCompletedRegistration.id
      );
    }

    function componentsScrollAvailable(course, userCourse, mode) {
      // It is ok use there workflow because this check should be only after available button Continue is clicked
      return (
        course.formatTypeId === formatType.collection &&
        userCourse &&
        !isSinglePlayCourse(mode) &&
        !isCourseInCreateRegistrationState(course, userCourse) &&
        !isCourseInRestartState(course, userCourse) &&
        !isEvaluationLaunchAvailable(course, userCourse) &&
        userCourse.workflow &&
        !userCourse.workflow.registration.isCheckState() &&
        _.intersection(userCourse.workflow.registration.actions, [
          'LaunchPostAssessmentAction',
          'LaunchPreAssessmentAction',
          'LaunchPreEvaluationAction',
          'MoveToPostAssessmentAction',
          'MoveToEvaluationPendingAction',
          'CompleteAction',
        ]).length === 0 &&
        userCourse.workflow.registration.status_id !== courseRegistrationStatus.pending
      );
    }

    function isExpired(course) {
      return !course.active || (course.deactivationDate && moment(course.deactivationDate).diff(moment(), 'hours') < 0);
    }

    function isBuyNowAvailable(course, userCourse, mode) {
      // pending allow to buy course, it needs to retry purchase process is it failed on payment system
      const isPending = _.get(userCourse, 'lastRegistration.statusId') === courseRegistrationStatus.pending;

      return (
        _.get(globalConfig, 'settings.ecommerceEnabled') &&
        isRegistrationToCourseOpen(course) &&
        _.get(course, 'price.amount') &&
        ((!isSinglePlayCourse(mode) && course.price.type_id === 2) ||
          (course.price.type_id === 1 &&
            (isCourseInCreateRegistrationState(course, userCourse, true) ||
              !checkAccessExpiration(userCourse) ||
              isPending)))
      );
    }

    function checkAccessExpiration(userCourse) {
      return userCourse.accessExpirationDate && moment(userCourse.accessExpirationDate).diff(moment(), 'seconds') > 0;
    }

    function isAttendTheMeetingBtnAvailable(userCourse, course) {
      if (course.formatTypeId !== courseEnums.formatType.liveEvent) {
        return false;
      }

      let registration = _.get(userCourse, 'workflow.registration'),
        continueBtnActions = ['LaunchOnlineMeetingAction'];

      return !!(
        registration &&
        (registration.id || (registration.completedRegistration && registration.completedRegistration.id)) &&
        _.intersection(registration.actions, continueBtnActions).length
      );
    }

    function isRegistrationToCourseOpen(course) {
      // Registration to course allowed when course is approved
      // Registration to course allowed when course publish date passed
      // Registration to course allowed when open registration date passed
      if (course.approvalStatusId !== approvalStatus.approved) {
        return false;
      }

      if (course.publishDate && moment(course.publishDate).diff(moment(), 'seconds') > 0) {
        return false;
      }

      return !(course.registrationOpensDate && moment(course.registrationOpensDate).diff(moment(), 'seconds') > 0);
    }

    function isRegistrationDeadline(course) {
      return !!course.registrationDeadline && moment(course.registrationDeadline).diff(moment(), 'seconds') < 0;
    }

    function getAvailablePlayCourseActions(userCourse, playCourseActions) {
      return _.sortBy(
        _.filter(playCourseActions, function (action) {
          return _.includes(userCourse.workflow.registration.actions, action.name);
        }),
        'playCourseOrder',
      );
    }
  }
})();
