(function () {
  angular.module('kmi.lms.course.details').component('courseCredits', {
    template: require('ajs/modules/course/views/components/modern/course-credits.html').default,
    bindings: {
      course: '<',
    },
    controllerAs: 'vm',
    controller: CourseCreditTypesController,
  });

  /* @ngInject */
  function CourseCreditTypesController(elms) {
    var vm = this;

    vm.$onInit = onInit;
    vm.formatDigit = elms.formatDigit;

    function onInit() {}
  }
})();
