(function () {
  angular.module('kmi.lms.core').component('activeMenuItemTitle', {
    template: require('ajs/modules/app/navigation/active-menu-item-title.html').default,
    controller: ActiveMenuItemTitleController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function ActiveMenuItemTitleController($state, $transitions, $stateParams, menuService, _) {
    const vm = this,
      menuItems = menuService.getMenuItems();

    activate();

    function activate() {
      setTitle();

      $transitions.onSuccess({}, setTitle);
    }

    function findStateInMenu(menuItems) {
      // Iterate through each item in the menuItems array
      for (let i = 0; i < menuItems.length; i++) {
        const item = menuItems[i];

        // Check if the current item's state matches the current state
        if (item.state === $state.current.name) {
          return true; // State found
        }

        // If the current item has children, recursively search within them
        if (item.children && item.children.length > 0) {
          const foundInChildren = findStateInMenu(item.children);
          if (foundInChildren) {
            return true; // State found in children
          }
        }
      }

      // If the loop completes and no match is found, return false
      return false;
    }

    function setTitle() {
      vm.title = null;

      for (var i = 0; i < menuItems.length; i++) {
        if (menuItems[i].state) {
          // if state has target param find title by target
          if ($stateParams.target) {
            if (menuItems[i].stateParams && $stateParams.target === menuItems[i].stateParams.target) {
              vm.title = menuItems[i].title;
              return;
            }
          } else if ($state.includes(menuItems[i].state, menuItems[i].stateParams)) {
            vm.title = menuItems[i].title;
            return;
          }
        }
      }

      let stateParams;

      for (var s = 0; s < menuItems.length; s++) {
        if (menuItems[s].state) {
          stateParams =
            menuItems[s].stateParams &&
            _.pickBy(menuItems[s].stateParams, function (n) {
              return typeof n === 'boolean' || !!n;
            });

          if ($state.includes(menuItems[s].state) && _.isEmpty(stateParams)) {
            vm.title = menuItems[s].title;
            return;
          }
        }
      }

      if (!vm.title && $state.current.component && _.get($state.current, 'data.pageTitle')) {
        vm.title = _.get($state.current, 'data.label');
      }

      if (!vm.title) {
        let adminMenuItems = menuService.getAdminMenuItems();
        // If current state is admin menu item, then se set title Admin
        if (findStateInMenu(adminMenuItems)) {
          vm.title = 'Admin';
        }
      }
    }
  }
})();
