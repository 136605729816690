(function () {
  angular.module('kmi.lms.course.registrations').component('courseRegistrationAlerts', {
    template: require('ajs/modules/course/registrations/components/modern/course-registration-alerts.html').default,
    controller: CourseRegistrationAlertsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function CourseRegistrationAlertsController(
    $scope,
    $state,
    globalConfig,
    currentUser,
    moment,
    courseRegistrationStatus,
    _,
    rootScopeService,
  ) {
    var vm = this;
    var filterStatuses = [courseRegistrationStatus.pending, courseRegistrationStatus.declined];

    vm.settings = _.get(globalConfig, 'settings.courseDetails');
    vm.hideAnonymousWarnings = vm.settings.hideAnonymousWarnings;
    vm.allowAccountCreation = !!$state.get('prompt.userRegistration');
    vm.registrationExpirationEnabled = globalConfig.settings.registrationExpirationEnabled;
    vm.hasContactsState = $state.get('main.contact');
    vm.signInWording = globalConfig.signInWording;

    vm.isAnonymous = currentUser.get().anonymous;

    vm.isAccessExpired = isAccessExpired;
    vm.isExpired = isExpired;
    vm.willExpire = willExpire;
    vm.expireCountdown = expireCountdown;
    vm.regNotDeclined = regNotDeclined;
    vm.$onInit = onInit;
    vm.login = login;

    function onInit() {}

    function willExpire() {
      return vm.userCourse && vm.userCourse.accessExpirationDate && regNotDeclined();
    }

    function expireCountdown() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return null;
      }

      return moment(vm.userCourse.accessExpirationDate).fromNow();
    }

    function isAccessExpired() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return false;
      }
      return regNotDeclined() && isExpired(vm.userCourse.accessExpirationDate);
    }

    function isExpired(date) {
      return moment(date).diff(moment(), 'seconds') <= 0;
    }

    function regNotDeclined() {
      return filterStatuses.indexOf(vm.userCourse.statusId) === -1;
    }

    function login() {
      rootScopeService.broadcast('event:auth-loginRequired');
    }
  }
})();
