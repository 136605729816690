(function () {
  angular.module('kmi.lms.course.details').component('courseCollectionGenericDetails', {
    template: require('ajs/modules/course/views/formats/collection/generic-collection-details.html').default,
    controller: CourseCollectionGenericDetailsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
      hideProgressBar: '<?',
      hasCompilationStructure: '=?',
    },
  });

  /* @ngInject */
  function CourseCollectionGenericDetailsController(
    $scope,
    rootScopeService,
    $window,
    currentUser,
    elmsAuthService,
    collectionsService,
    courseComponentsService,
    globalConfig,
    courseRegistrationStatus,
    trainingService,
    $timeout,
    $state,
    _,
  ) {
    var vm = this,
      user = currentUser.get();

    vm.settings = globalConfig.settings;
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};

    vm.compilationStat = {
      completed: 0,
      inprogress: 0,
    };

    vm.$onInit = onInit;
    vm.hasRegistration = hasRegistration;

    function onInit() {
      var window = angular.element($window);

      vm.loading = true;

      initCompilationStat().then(function () {
        vm.loading = null;
      });

      _checkMobileView();

      window.bind('resize', _checkMobileView);

      $scope.$on('event:course.registration.action.finished', function () {
        elmsAuthService.refreshUserLoggedIn().then(function (result) {
          if (result) {
            initCompilationStat();
          }
        });
      });

      $scope.$on('event:course.registration.updated', function () {
        initCompilationStat();
      });

      $scope.$on('event:course.registration.workflow.initialized', function (event, workflow) {
        if (workflow.registration.courseId === vm.course.id) {
          vm.workflow = workflow;
          vm.workflow.registration.hasNonRegisteredComponents = vm.hasNonRegisteredComponents;
        }
      });

      $scope.$on('event:collection:scrollToCourse', function () {
        var nextCourse = trainingService.findNextCollectionComponent(vm.compilationStructure);

        if (nextCourse) {
          $timeout(function () {
            rootScopeService.broadcast('event:scrollTo', nextCourse.id, 'center');
          });
        }

        vm.highlightedCourse = nextCourse;
      });

      $scope.$on('event:collection:goToPlayer', function () {
        var nextCourse = trainingService.findNextCollectionComponent(vm.compilationStructure);

        if (!nextCourse) {
          nextCourse = trainingService.findFirstCollectionComponent(vm.compilationStructure);
        }

        if (nextCourse) {
          $state.go('view.collectionPlayer', {
            courseId: nextCourse.id,
            collectionId: vm.course.id,
          });
        }
      });

      function _checkMobileView() {
        vm.mobileView = window.width() < 768;
      }
    }

    function initCompilationStat() {
      return getStructure().then(function (response) {
        if (!vm.compilationStructure) {
          vm.compilationStructure = response.structure;
        } else {
          vm.compilationStructure = collectionsService.updateChangedComponents(
            vm.compilationStructure,
            response.structure,
          );
        }

        vm.hasCompilationStructure = vm.compilationStructure.length > 0;

        vm.course.playable = angular.isDefined(vm.course.playable)
          ? vm.course.playable
          : collectionsService.isCollectionPlayable(vm.compilationStructure);

        vm.hasNonRegisteredComponents = response.has_non_registered_components;

        if (vm.workflow) {
          vm.workflow.registration.hasNonRegisteredComponents = vm.hasNonRegisteredComponents;
        }

        calculateExpandedItems(vm.compilationStructure);

        $scope.$broadcast('event:collection:structureChanged', vm.compilationStructure);
        calcStat(vm.compilationStructure);
      });
    }

    function getStructure() {
      return courseComponentsService.getCompilationStructure(vm.course.id, user.id, { view: 'all' });
    }

    function calcStat(compilationStructure) {
      vm.compilationStat = collectionsService.aggregateRegistrationsInfo(compilationStructure);
    }

    function hasRegistration() {
      return (
        _.get(vm, 'userCourse.statusId') && !_.includes(courseRegistrationStatus.notStartedSet, vm.userCourse.statusId)
      );
    }

    function calculateExpandedItems(structure) {
      vm.expandedItem = 'all';
      if (_.get(globalConfig, 'settings.courseDetails.collectionSectionsCollapsed')) {
        var nextCourse = trainingService.findNextCollectionComponent(vm.compilationStructure, true);
        vm.expandedItem = null;

        if (nextCourse) {
          var meta = collectionsService.getCollectionStructureMeta(structure, nextCourse);

          vm.expandedItem =
            (meta.currentCourseSection && meta.currentCourseSection !== 'root' && meta.currentCourseSection.name) ||
            vm.expandedItem;
        }
      }
    }
  }
})();
