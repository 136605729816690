(function () {
  angular.module('kmi.lms.course.details').component('conferenceSessionRegistrationControl', {
    template:
      require('ajs/modules/course/views/formats/collection/conference/components/session-registration-control.html')
        .default,
    controller: ConfSessionRegistrationControlController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      session: '=',
      sessionUserCourse: '=',
      conferenceUserCourse: '=',
      readonlyData: '<?',
    },
  });

  /* @ngInject */
  function ConfSessionRegistrationControlController(
    moment,
    _,
    courseEnums,
    courseRegistrationStatus,
    $window,
    conferenceDetailsService,
  ) {
    const vm = this;

    vm.locationType = courseEnums.locationType;
    vm.status = courseRegistrationStatus;
    vm.isSessionJoinAllowed = conferenceDetailsService.isSessionJoinAllowed;
    vm.joinSession = joinSession;
    vm.isSessionPlayButtonAllowed = conferenceDetailsService.isSessionPlayButtonAllowed;
    vm.isRegistered = conferenceDetailsService.isRegistered;
    vm.sessionRegularStatusAllowed = sessionRegularStatusAllowed;

    function sessionRegularStatusAllowed() {
      const status = _.get(vm.sessionUserCourse, 'statusId');

      return (
        (vm.sessionUserCourse && _.includes(courseRegistrationStatus.completedSet, status)) ||
        courseRegistrationStatus.expired === status
      );
    }

    function joinSession() {
      $window.open(vm.session.course.courseLocation.virtualLocationUrl, '_blank');
    }
  }
})();
