(function () {
  angular.module('kmi.lms.core').factory('httpRequestCachingService', httpRequestCachingService);

  /* @ngInject */
  function httpRequestCachingService($q, $injector, _) {
    return {
      request: function (config) {
        var globalConfig = $injector.get('globalConfig');

        if (
          config &&
          (globalConfig.settings.view === 'admin' || config.cacheByPass || _.get(config, 'params.cacheByPass'))
        ) {
          /** Nginx configured to not take response from a cache in some conditions. Setting "proxy_cache_bypass"
           * http://nginx.org/en/docs/http/ngx_http_proxy_module.html?&_ga=2.258905870.507024983.1495611857-403730876.1495611857#proxy_cache_bypass
           * It can be achieved with a several request options.
           */

          // 1 option: Header.
          // proxy_cache_bypass $http_cachepurge;
          config.headers['X-Proxy-Cache'] = 'BYPASS';

          // 2 option: query parameter
          // proxy_cache_bypass $arg_nocache;

          //config.params.nocache = true;

          if (_.get(config, 'params.cacheByPass')) {
            delete config.params.cacheByPass;
          }
        }

        return config || $q.when(config);
      },
    };
  }
})();
