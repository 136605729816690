(function () {
  angular.module('kmi.lms.course.registrations').component('executionConfirmationDialog', {
    bindings: {
      workflow: '=',
    },
    template: require('ajs/modules/course/registrations/components/dialogs/execution-confirmation.html').default,
    controller: ExecutionConfirmationDialogController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function ExecutionConfirmationDialogController(courseEnums, _) {
    const vm = this;

    vm.$onInit = onInit;
    vm.getCustomFieldName = getCustomFieldName;
    vm.getRoleName = getRoleName;
    vm.locationType = courseEnums.locationType;

    function onInit() {
      vm.actionData = vm.workflow.actionData;
      if (vm.actionData.creditTypes !== undefined) {
        vm.creditTypesData = true;
        vm.creditTypes = vm.actionData.creditTypes
          ? _.get(vm.actionData, 'creditTypes', []).filter((creditType) => creditType.id)
          : [];
      }
      if (vm.actionData.custom_fields) {
        vm.customFields = Object.keys(vm.actionData.custom_fields);
      }

      vm.attendanceTypeId = vm.workflow.actionData.attendanceTypeId;

      const courseLocation = _.get(vm.workflow.registration, 'course.courseLocation');
      if (
        !vm.attendanceTypeId &&
        courseLocation &&
        courseLocation.typeId !== courseEnums.locationType.inPersonAndVirtual
      ) {
        vm.attendanceTypeId = courseLocation.typeId;
      }
    }

    function getCustomFieldName(id) {
      let field = vm.workflow.registration.custom_fields.find((item) => item.id === parseInt(id));
      return field ? field.description : null;
    }

    function getRoleName(roleId) {
      let role = vm.workflow.registration.session.roles.find((role) => role.id === roleId);
      return role ? role.name : null;
    }
  }
})();
