(function () {
  angular.module('kmi.lms.course.details').component('courseStateMessages', {
    template: require('ajs/modules/course/views/components/state-messages/state-messages.html').default,
    controller: CourseStateMessagesController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function CourseStateMessagesController($scope, $compile, $element, courseStateMessagesService, _) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      bindMessages();

      $scope.$on('event:course.registration.workflow.initialized', function () {
        bindMessages();
      });

      $scope.$watch('vm.userCourse', function () {
        bindMessages();
      });
    }

    function bindMessages() {
      vm.messages = _.filter(courseStateMessagesService.getMessages(vm.course, vm.userCourse), (message) => {
        return message.restriction === null || message.restriction === undefined || message.restriction();
      });

      angular.element($element[0].querySelector('div.state-message-placehodler')).html(null);

      vm.messages.forEach((message) => {
        const template = angular.element(['<div class="alert alert-warning" >', message.template, '</div>'].join(''));
        var link = $compile(template);

        var $newScope = $scope.$new();
        Object.assign($newScope, vm.bindings);
        link($newScope);

        angular.element($element[0].querySelector('div.state-message-placehodler')).append(template);
      });
    }
  }
})();
