(function () {
  angular.module('kmi.lms.core').factory('gtag', gtagService);

  function gtagService($location, _, $window, $timeout) {
    var re = new RegExp('^[^#]*?://.*?(/.*)$'),
      skipNextPWE = false,
      pwEvent = null;

    return {
      sendClickEvent: sendClickEvent,
      sendPageView: sendPageView,
      skipNextPWEvent: skipNextPWEvent,
    };

    function sendPageView() {
      if (skipNextPWE) {
        skipNextPWE = false;
        return;
      }

      if (!pwEvent) {
        pwEvent = $timeout(sendPageViewImpl);
      }
    }

    function skipNextPWEvent() {
      skipNextPWE = true;
    }

    function sendPageViewImpl() {
      pwEvent = null;

      if (skipNextPWE) {
        skipNextPWE = false;
        return;
      }

      if (angular.isDefined($window.gtag)) {
        var absUrl = $location.absUrl(),
          result = re.exec(absUrl),
          fullPathname = result ? result[1] : null,
          googleAnalyticsCode = _.get($window.elmsEnvironment, 'siteSettings.googleAnalyticsCode', null),
          googleAnalyticsAlternative = _.get($window.elmsEnvironment, 'googleAnalyticsCodeAlternative', null);

        if (isOldGACode(googleAnalyticsCode)) {
          $window.gtag('event', 'page_view', {
            page_title: document.title,
            page_location: absUrl,
            page_path: fullPathname,
            send_to: ['default'],
          });
        }

        if (isOldGACode(googleAnalyticsAlternative)) {
          $window.gtag('event', 'page_view', {
            page_title: document.title,
            page_location: absUrl,
            page_path: fullPathname,
            send_to: ['affiliateTracker'],
          });
        }
      }
    }

    function sendClickEvent(url, event) {
      if (angular.isDefined($window.gtag)) {
        $window.gtag('event', 'click', {
          event_category: event,
          event_label: url,
          send_to: ['default', 'affiliateTracker'],
        });
      }
    }

    function isOldGACode(code) {
      return code && code.startsWith('UA-');
    }
  }
})();
