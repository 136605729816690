(function () {
  angular.module('kmi.lms.course.posts').component('modernCoursePosts', {
    bindings: {
      course: '=',
      view: '@',
      trackingName: '<?',
      query: '=',
    },
    template: require('ajs/modules/course/posts/modern-posts.html').default,
    controller: CoursePostsController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function CoursePostsController(
    $scope,
    $timeout,
    _,
    currentUser,
    UserNetworkActivity,
    networkSearchService,
    networkService,
    globalConfig,
    courseDetailsState,
    networkServiceUpgraded,
  ) {
    var vm = this,
      searchService,
      courseNetworkService;

    vm.activityOrigin = 'course';
    vm.user = currentUser.get();
    vm.networkSettings = globalConfig.settings.network;
    vm.pageChanged = pageChanged;
    vm.currentPage = 1;
    vm.itemsPerPage = 5;

    vm.activities = [];

    vm.prepareNewPost = prepareNewPost;
    vm.search = searchPosts;
    vm.$onInit = onInit;

    ///////////

    function onInit() {
      if (vm.query) {
        vm.searchQuery = vm.query;
      }

      var searchParams = { activityOrigin: vm.activityOrigin, courseId: vm.course.id, take: 5 };
      if (vm.searchQuery) {
        searchParams.query = vm.searchQuery;
      }

      searchService = networkSearchService(searchParams);
      courseNetworkService = networkService(searchService, vm, $scope);

      vm.searchConditions = searchService.conditions;

      activate();
    }

    function activate() {
      vm.loading = courseNetworkService.loadData().finally(function () {
        vm.loading = null;

        var scrollTo = courseDetailsState.getScrollPosition();
        if (scrollTo === 'discussions') {
          $timeout(function () {
            $scope.$emit('event:scrollTo', scrollTo);
            courseDetailsState.setScrollPosition(null);
          }, 10);
        }
      });
    }

    function prepareNewPost() {
      networkServiceUpgraded
        .showEditThreadDialog(
          UserNetworkActivity.newPost({
            courseId: vm.course.id,
            activityOrigin: vm.activityOrigin,
          }),
        )
        .then((activity) => {
          if (!activity) {
            return;
          }
          vm.currentPage = 1;
          courseNetworkService.getPage().then(() => {
            vm.activities = _.filter(vm.activities, (item) => {
              return parseInt(item.id) !== parseInt(activity.id);
            });
            vm.activities.unshift(activity);
          });
        }, angular.noop);
    }

    function searchPosts() {
      courseNetworkService.search().then(function () {
        vm.currentPage = 1;
        vm.searchQuery = vm.searchConditions.query;
      });
    }

    function pageChanged() {
      // skip the event if all data are loaded
      if (vm.itemCount <= vm.activities.length) {
        return;
      }

      courseNetworkService.getPage();
    }
  }
})();
