(function () {
  angular.module('kmi.lms.core').component('printNavigation', {
    template: require('ajs/modules/app/navigation/components/print-navigation.html').default,
    controller: PrintNavigationController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function PrintNavigationController($window) {
    var vm = this;
    vm.print = print;

    function print() {
      $window.print();
    }
  }
})();
