// Module
var code = `<div> <div class="sm-margin-bottom" ng-if="!vm.shortViewMode"> Please review the content listing requirements and complete the form below. Your request will be reviewed. </div> <div class="well well-sm sm-margin-bottom" ng-if="!vm.shortViewMode"> <ol> <li> Content must be designed for the continuing education and training of professionals who protect the public's health and be consistent with the target audiences in the TRAIN Learning Network. Consumer-oriented health education and materials are not permitted. </li> <li> Content must be designed to build knowledge, skills, or competence in one or more of the subject areas of the TRAIN Learning Network. </li> <li> Content must be designed for instructional - not reference - use by learners or trainers. Content must be delivered in formats consistent with those on the TRAIN Learning Network. </li> </ol> </div> <div modal="vm.showRequirementsWindow"> <div class="modal-header"> <button type="button" class="close" data-ng-click="vm.showRequirementsWindow = 0" aria-label="Close"> <span aria-hidden="true">&times;</span> </button> <h4 class="modal-title">Content Listing Requirements</h4> </div> <div class="modal-body course-review-rating"> <div> <ol> <li> Content must be designed for the continuing education and training of professionals who protect the public's health and be consistent with the target audiences in the TRAIN Learning Network. Consumer-oriented health education and materials are not permitted. </li> <li> Content must be designed to build knowledge, skills, or competence in one or more of the subject areas of the TRAIN Learning Network. </li> <li> Content must be designed for instructional - not reference - use by learners or trainers. Content must be delivered in formats consistent with those on the TRAIN Learning Network. </li> </ol> </div> </div> </div> <div ng-form="courseProviderSurveyForm"> <div class="form-group row" ng-class="{
            'has-error':
              courseProviderSurveyForm.reviewedRequirements.\$invalid &&
              (vm.formOptions.submitted || courseProviderSurveyForm.reviewedRequirements.\$dirty)
          }"> <div class="col-xs-12 checkbox mb-0"> <label class="" for="reviewedRequirements"> <input type="checkbox" class="required" id="reviewedRequirements" name="reviewedRequirements" ng-required="1" ng-model="vm.provider.reviewedRequirements"/> &nbsp;I have reviewed the <span ng-if="!vm.shortViewMode">content listing requirements.</span> </label> <a href="#" ng-click="vm.showRequirementsWindow = 1" ng-if="vm.shortViewMode"> content listing requirements. </a> <required-field></required-field> <div class="help-block" ng-show="vm.formOptions.submitted  && courseProviderSurveyForm.reviewedRequirements.\$error.required"> You must agree with content listing requirements. </div> </div> </div> <div class="form-group row" ng-class="{
          'has-error':
            courseProviderSurveyForm.healthRelatedTopics.\$invalid &&
            (vm.formOptions.submitted || courseProviderSurveyForm.healthRelatedTopics.\$dirty)
         }"> <div class="col-xs-12"> <label class="control-label" for="healthRelatedTopics">What public health-related topics will be covered in your content? <required-field></required-field></label> </div> <div class="col-xs-12 col-sm-9" ng-class="{'col-sm-12':vm.shortViewMode}"> <input type="text" placeholder="e.g., subject areas, competencies" class="form-control required" id="healthRelatedTopics" name="healthRelatedTopics" maxlength="2000" ng-required="1" ng-model="vm.provider.healthRelatedTopics"/> <p class="error-block" ng-if="courseProviderSurveyForm.healthRelatedTopics.\$invalid"> <span ng-if="courseProviderSurveyForm.healthRelatedTopics.\$error.required">Health related topics field is required.</span> </p> </div> </div> <div class="form-group row" ng-class="{
           'has-error':
              courseProviderSurveyForm.targetAudiences.\$invalid &&
              (vm.formOptions.submitted || courseProviderSurveyForm.targetAudiences.\$dirty)
          }"> <div class="col-xs-12"> <label class="control-label" for="targetAudiences">What audiences does the majority of your content serve? <required-field></required-field></label> </div> <div class="col-xs-12 col-sm-9" ng-class="{'col-sm-12':vm.shortViewMode}"> <input type="text" class="form-control required" id="targetAudiences" placeholder="e.g., nurses, administrators, epidemiologists" name="targetAudiences" maxlength="2000" ng-required="1" ng-model="vm.provider.targetAudiences"/> <p class="error-block" ng-if="courseProviderSurveyForm.targetAudiences.\$invalid"> <span ng-if="courseProviderSurveyForm.targetAudiences.\$error.required">Target audiences field is required.</span> </p> </div> </div> <div class="form-group row" ng-class="{
           'has-error':
              courseProviderSurveyForm.accredited.\$invalid &&
              (vm.formOptions.submitted || courseProviderSurveyForm.accredited.\$dirty)
          }"> <div class="col-xs-12"> <label class="control-label" for="accredited">Is your organization accredited to provide training related to these topics or audiences? <required-field></required-field></label> </div> <div class="col-xs-12 col-sm-2 xs-margin-bottom-phone" ng-class="{'col-sm-5':vm.shortViewMode}"> <select class="form-control required" ng-required="1" id="accredited" name="accredited" ng-change="vm.changeAccredited()" yes-no-value ng-model="vm.provider.accredited"> <option value="" ng-hide="vm.provider.feeCharged === true || vm.provider.feeCharged === false">Choose</option> <option value="yes">Yes</option> <option value="no">No</option> </select> <p class="error-block" ng-if="courseProviderSurveyForm.accredited.\$invalid"> <span ng-if="courseProviderSurveyForm.accredited.\$error.required">Accreditation choice is required.</span> </p> </div> <div class="col-sm-7 col-xs-12 xs-margin-top-phone no-margin-horizontal form-group mb-0" ng-class="{
              'has-error':
                courseProviderSurveyForm.accreditation.\$invalid &&
                (vm.formOptions.submitted || courseProviderSurveyForm.accreditation.\$dirty)
           }"> <label class="visibility-hidden d-none">Specify selection <required-field></required-field></label> <input type="text" placeholder="Provide the name of the accrediting body" class="form-control required" id="accreditation" name="accreditation" maxlength="2000" ng-if="vm.provider.accredited" ng-required="vm.provider.accredited" ng-model="vm.provider.accreditation"/> <p class="error-block" ng-if="courseProviderSurveyForm.accreditation.\$invalid"> <span ng-if="courseProviderSurveyForm.accreditation.\$error.required">Accredited field is required.</span> </p> </div> </div> <div class="form-group row" ng-class="{
           'has-error':
              courseProviderSurveyForm.creditsOffered.\$invalid &&
              (vm.formOptions.submitted || courseProviderSurveyForm.creditsOffered.\$dirty)
         }"> <div class="col-xs-12"> <label class="control-label" for="creditsOffered">Does any of your public health-related content offer continuing education credits? <required-field></required-field></label> </div> <div class="col-xs-12 col-sm-2 xs-margin-bottom-phone" ng-class="{'col-sm-5':vm.shortViewMode}"> <select class="form-control required" ng-required="1" id="creditsOffered" name="creditsOffered" ng-change="vm.changeCreditsOffered()" yes-no-value ng-model="vm.provider.creditsOffered"> <option value="" ng-hide="vm.provider.feeCharged === true || vm.provider.feeCharged === false">Choose</option> <option value="yes">Yes</option> <option value="no">No</option> </select> <p class="error-block" ng-if="courseProviderSurveyForm.creditsOffered.\$invalid"> <span ng-if="courseProviderSurveyForm.creditsOffered.\$error.required">Credit offered choice is required.</span> </p> </div> <div class="col-sm-7 col-xs-12 xs-margin-top-phone no-margin-horizontal form-group mb-0" ng-class="{
           'has-error':
              courseProviderSurveyForm.credits.\$invalid &&
              (vm.formOptions.submitted || courseProviderSurveyForm.credits.\$dirty)
         }"> <label class="visibility-hidden d-none">Specify selection <required-field></required-field></label> <input type="text" class="form-control required" id="credits" name="credits" maxlength="100" placeholder="Specify the credit types (e.g., CHES, CNE, CEU, CME)" ng-if="vm.provider.creditsOffered" ng-required="vm.provider.creditsOffered" ng-model="vm.provider.credits"/> <p class="error-block" ng-if="courseProviderSurveyForm.credits.\$invalid"> <span ng-if="courseProviderSurveyForm.credits.\$error.required">Credits field is required.</span> </p> </div> </div> <div class="form-group row" ng-class="{
           'has-error':
              courseProviderSurveyForm.feeCharged.\$invalid &&
              (vm.formOptions.submitted || courseProviderSurveyForm.feeCharged.\$dirty)
          }"> <div class="col-xs-12"> <label class="control-label" for="feeCharged">Will there be fees charged for any of your content? <required-field></required-field></label> </div> <div class="col-xs-12 col-sm-2" ng-class="{'col-sm-5':vm.shortViewMode}"> <select class="form-control required" ng-required="1" id="feeCharged" name="feeCharged" yes-no-value ng-model="vm.provider.feeCharged"> <option value="" ng-hide="vm.provider.feeCharged === true || vm.provider.feeCharged === false">Choose</option> <option value="yes">Yes</option> <option value="no">No</option> </select> <p class="error-block" ng-if="courseProviderSurveyForm.feeCharged.\$invalid"> <span ng-if="courseProviderSurveyForm.feeCharged.\$error.required">Fee charged choice is required.</span> </p> </div> </div> <div class="form-group row"> <div class="col-xs-12"> <label for="additionalInformation" class="control-label">Additional information you wish to provide</label> </div> <div class="col-xs-12 col-sm-9" ng-class="{'col-sm-12':vm.shortViewMode}"> <input type="text" class="form-control" id="additionalInformation" name="additionalInformation" maxlength="2000" ng-model="vm.provider.additionalInformation"/> </div> </div> </div> </div> `;
// Exports
export default code;