(function () {
  angular.module('kmi.lms.course.details').component('courseRelatedCollections', {
    template: require('ajs/modules/course/views/components/related-collections.html').default,
    controller: 'CourseRelatedCollectionsController',
    controllerAs: 'vm',
    bindings: {
      courseId: '<',
      modernView: '<?',
    },
  });
})();
