(function () {
  angular.module('kmi.lms.course.registrations').component('courseRegistrationCreditBase', {
    template: require('ajs/modules/course/registrations/components/registration-credit-base.html').default,
    controller: CourseRegistrationCreditBaseController,
    controllerAs: 'vm',
    bindings: {
      registrationCredit: '<',
      fullName: '@?',
    },
  });

  /* @ngInject */
  function CourseRegistrationCreditBaseController(elms) {
    const vm = this;
    vm.formatDigit = elms.formatDigit;
  }
})();
