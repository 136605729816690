(function () {
  angular.module('kmi.lms.course.details').directive('courseTagListInfo', courseTagListInfo);

  function courseTagListInfo() {
    return {
      restrict: 'EA',
      scope: {
        tagsListInfo: '<',
      },
      template: require('ajs/modules/course/views/components/modern/course-list-blocks-info.html').default,
    };
  }
})();
