(function () {
  angular.module('kmi.lms.course.details').component('courseViewHeader', {
    template: require('ajs/modules/course/views/components/header/course-header.html').default,
    controller: CourseViewHeaderController,
    controllerAs: 'vm',
    bindings: {
      course: '<',
      userCourse: '=',
      hasSidebar: '<?',
    },
  });

  /* @ngInject */
  function CourseViewHeaderController($filter, $window, globalConfig, rootScopeService, $timeout, _) {
    const vm = this;

    vm.heroView = _.get(globalConfig, 'settings.courseDetails.heroImageDetails');
    vm.$onInit = onInit;
    vm.$onDestroy = onDestroy;

    function onInit() {
      rootScopeService.on('event:urlVersion.updated', () => {
        loadHeroBackground();
        setScrollBarSize();
      });

      loadHeroBackground();

      $timeout(function () {
        setScrollBarSize();
        setHeaderOffset();
      }, 10);

      angular.element($window).bind('resize', setHeaderOffset);
      angular.element($window).bind('resize', setScrollBarSize);
    }

    function onDestroy() {
      angular.element($window).unbind('resize', setHeaderOffset);
      angular.element($window).unbind('resize', setScrollBarSize);
    }

    function loadHeroBackground() {
      var url = $filter('urlVersion')(['/a/course/', vm.course.id, '/hero/'].join(''));

      if (vm.heroView && vm.course.heroImage) {
        vm.heroBackground =
          'linear-gradient(180deg, rgba(0, 0, 0, 0.7) ' + '-24.69%, rgba(0, 0, 0, 0.7) 100%), url(' + url + ')';
      } else {
        vm.heroBackground = null;
      }
    }

    function getScrollbarOffset() {
      return Math.round((window.innerWidth - document.body.clientWidth) / 2);
    }

    function setScrollBarSize() {
      document.body.style.setProperty('--scrollbarOffset', `${getScrollbarOffset()}px`);
    }

    function setHeaderOffset() {
      const el = document.querySelector<HTMLElement>('.header-group');
      if (el) {
        document.body.style.setProperty('--headerOffsetDesktop', `${el.offsetHeight}px`);
      }
    }
  }
})();
