(function () {
  angular.module('kmi.lms.core').factory('entityDataService', entityDataService);

  /* @ngInject */
  function entityDataService($q) {
    var entityLoaders = {};

    return {
      getData: getData,
      registerLoader: registerLoader,
    };

    /**
     * @description
     * Registers loader function for the appropriate entity type
     * @param entityType
     * @param loaderFn
     */
    function registerLoader(entityType, loaderFn) {
      entityLoaders[entityType] = loaderFn;
    }

    /**
     * Triggers loader registered for the provided entity type
     * @param entityType
     * @param id
     * @returns {promise}
     */
    function getData(entityType, id) {
      var loader = entityLoaders[entityType];

      return $q.resolve((loader && loader(id)) || {});
    }
  }
})();
