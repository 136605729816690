(function () {
  angular.module('kmi.lms.core').factory('locationHistory', locationHistory);

  function locationHistory($cookies, cookiePrefix, currentUser, globalConfig, _) {
    var history = [];
    var historyExclusions = [
        'authorizationRequirement',
        'trainPendingCourseSurveys',
        'logout',
        'prompt.resetPassword',
        'main.admin.reports.details',
        'main.access-denied',
      ],
      navigationHistoryCookieName = cookiePrefix + '.navigationHistory';

    var service = {
      clear: clear,
      getLength: getLength,
      holdLastVisit: holdLastVisit,
      clearLastVisit: clearLastVisit,
      replaceParams: replaceParams,
      pop: pop,
      push: push,
      remove: remove,
      getLastState: getLastState,
    };

    Object.defineProperty(service, 'lastVisit', {
      get: function () {
        var navigationHistory = angular.fromJson($cookies.get(navigationHistoryCookieName) || '{}');
        return navigationHistory[currentUser.get().id];
      },
    });

    return service;

    function clear() {
      history.length = 0;
    }

    function getLength() {
      return history.length;
    }

    function pop() {
      return history.pop();
    }

    function push(value) {
      if (!_.includes(historyExclusions, value.name)) {
        history.push(value);
      }
    }

    function remove(state, params) {
      _.remove(history, function (item) {
        return item.name === state && angular.equals(item.params, params);
      });
    }

    function replaceParams(stateName, oldParams, newParams) {
      var state = _.find(history, function (item) {
        return item.name === stateName && angular.equals(item.params, oldParams);
      });

      if (state) {
        state.params = newParams;
      }
    }

    function holdLastVisit() {
      if (history.length) {
        var navigationHistory = angular.fromJson($cookies.get(navigationHistoryCookieName) || '{}');
        navigationHistory[currentUser.get().id] = { state: _.last(history), appView: globalConfig.settings.view };

        $cookies.put(navigationHistoryCookieName, angular.toJson(navigationHistory));
      }
    }

    function clearLastVisit() {
      var navigationHistory = angular.fromJson($cookies.get(navigationHistoryCookieName) || '{}');

      if (navigationHistory) {
        delete navigationHistory[currentUser.get().id];
      }

      if (_.keys(navigationHistory).length) {
        $cookies.put(navigationHistoryCookieName, angular.toJson(navigationHistory));
      } else {
        $cookies.remove(navigationHistoryCookieName);
      }
    }

    function getLastState() {
      return history.length ? history[history.length - 1] : null;
    }
  }
})();
