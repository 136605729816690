(function () {
  angular.module('kmi.lms.course.reviews').component('editCourseReviewWindow', {
    template: require('ajs/modules/course/reviews/modal/edit-review-window.html').default,
    controller: EditCourseReviewWindowController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function EditCourseReviewWindowController(
    $scope,
    $uibModal,
    CourseReview,
    courseReviewsService,
    serverErrorHandlerService,
    courseRegistrationStatus,
    globalConfig,
    _,
  ) {
    var vm = this;

    vm.hiddenMode = true;
    vm.close = cancelModal;

    vm.$onInit = onInit;

    //////////

    function onInit() {
      // It should works for admin course details for manual add review
      $scope.$on('event:course:review:new', function () {
        _openModal();
        vm.newReview = CourseReview.newReview({ courseId: vm.course.id });
      });

      $scope.$on('event:user:course:review:added', cancelModal);

      vm.newReview = CourseReview.newReview({ courseId: vm.course.id });
      $scope.$watchGroup(['vm.userCourse.statusId', 'vm.userCourse.lastRegistration.statusId'], function () {
        if (
          vm.userCourse &&
          vm.userCourse.statusId &&
          _.includes(courseRegistrationStatus.completedSet, vm.userCourse.statusId) &&
          !_.includes(courseRegistrationStatus.activeSet, _.get(vm.userCourse, 'lastRegistration.statusId'))
        ) {
          courseReviewsService
            .updateStat(vm.course)
            .then(function () {
              var r = vm.course.ratings;

              if (r.prompt_after_completed && r.allow_addition && r.visible && globalConfig.settings.view !== 'admin') {
                _openModal();
              }
            })
            .catch(function (reason) {
              serverErrorHandlerService.handleForbiddenError(reason);
            });
        }
      });
    }

    function cancelModal() {
      if (vm.modalInstance) {
        vm.modalInstance.dismiss('cancel');
      }
      vm.newReview = null;
    }

    function _openModal() {
      vm.modalInstance = $uibModal.open({
        component: 'editCourseReviewModalComponent',
        resolve: {
          course: function () {
            return vm.course;
          },
          newReview: function () {
            return vm.newReview;
          },
        },
      });
    }
  }
})();
