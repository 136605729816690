(function () {
  angular.module('kmi.lms.customAttributes.common').component('customAttributesPlainForm', {
    restrict: 'AE',
    replace: true,
    bindings: {
      model: '=',
      modelType: '=',
      formOptions: '=',
      strictlyRequired: '=?',
      cacheEnabled: '=?',
    },
    template: require('ajs/modules/custom-attributes/common/plain-form-template.html').default,
    controller: CustomAttributesPlainForm,
    controllerAs: 'vm',
    bindToController: true,
  });

  /* @ngInject */
  function CustomAttributesPlainForm(
    $scope,
    $http,
    $q,
    _,
    attributesService,
    customCourseAttributesService,
    customSessionAttributesService,
    serverErrorHandlerService,
    globalConfig,
  ) {
    var vm = this;
    var canceller;

    var modelTypes = {
      course: {
        savingEvent: 'event:course:beforeSave',
        service: customCourseAttributesService,
      },
      session: {
        savingEvent: 'event:courseSession:beforeSave',
        service: customSessionAttributesService,
      },
    };

    vm.componentReady = false;
    vm.categories = [];
    vm.initForm = attributesService.initForm;

    vm.$onInit = activate;

    //////////

    function activate() {
      if (vm.cacheEnabled) {
        attributesService.initCache(vm.model.attributes);
      }

      loadCustomAttributes(function () {
        $scope.$watchCollection('vm.model.groups', changeGroupsHandler);
      });

      $scope.$on(modelTypes[vm.modelType].savingEvent, function (event, model) {
        model.attributes = [];
        attributesService.prepareAttributesForSave(model, vm.categories);
      });
    }

    function loadCustomAttributes(callback) {
      var groupIds = _.map(vm.model.groups, 'id');

      canceller = $q.defer();

      modelTypes[vm.modelType].service
        .getCategories(
          {
            groups: angular.toJson(groupIds),
            withAttributes: true,
          },
          true,
          canceller,
        )
        .then(function (res) {
          vm.categories = res.data;

          hardcodedValidation();

          if (vm.componentReady || !vm.cacheEnabled) {
            _.each(vm.categories, function (cat) {
              attributesService.mergeWithValues(cat.attributes, vm.model.attributes, 'typeId');
            });
          }
          if (angular.isFunction(callback)) {
            callback();
          }
          vm.componentReady = true;
        })
        .catch(function (reason) {
          serverErrorHandlerService.handleForbiddenError(reason);
        });
    }

    function changeGroupsHandler(newValue, oldValue) {
      if (newValue && newValue.length) {
        if (!angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
          if (canceller && _.some($http.pendingRequests, { timeout: canceller.promise })) {
            canceller.resolve();
          }
          loadCustomAttributes();
        }
      } else {
        vm.categories = [];
      }
    }

    // this function is derpicated. this is a temporary solution for validation attribute by regexp
    function hardcodedValidation() {
      if (vm.modelType === 'session') {
        if (globalConfig.projectName === 'train') {
          _.each(vm.categories, function (cat) {
            var hardcodedAttribute = _.find(cat.attributes, { typeId: 1 }); // For Train attribute "COPE - Event ID Number (COPE)"
            if (hardcodedAttribute) {
              hardcodedAttribute.pattern = 'digitsOnly';
              return;
            }
          });
        }
      }
    }
  }
})();
