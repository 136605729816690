(function () {
  angular.module('kmi.lms.core').factory('Announcement', function ($resource) {
    return $resource(
      '/a/announcements/:announcementId',
      { announcementId: '@id' },
      {},
      {
        stripTrailingSlashes: false,
      },
    );
  });
})();
