(function () {
  angular.module('kmi.lms.course.registrations').factory('userCoursesService', userCoursesService);

  /* @ngInject */
  function userCoursesService(
    $state,
    $window,
    moment,
    CourseRegistration,
    $http,
    currentUser,
    _,
    LearningObjectRegistration,
    courseRegistrationStatus,
    $httpParamSerializer,
    globalConfig,
    securityService,
  ) {
    var statusDictionary = {
      all: {
        label: 'All',
        statuses: null,
      },
      incomplete: {
        label: 'Incomplete',
        statuses: courseRegistrationStatus.incompleteSet,
      },
      completed: {
        label: 'Completed',
        statuses: courseRegistrationStatus.strictCompletedSet,
      },
      withdrawn: {
        label: 'Withdrawn',
        statuses: courseRegistrationStatus.withdrawnSet,
      },
      pending_evaluation: {
        label: 'Pending Evaluation',
        statuses: 'pending_evaluation',
      },
    };

    var customStatusDictionary = {
      pending: {
        label: 'Pending',
        statuses: [courseRegistrationStatus.pending],
      },
      approved: {
        label: 'Approved',
        statuses: [courseRegistrationStatus.approved],
      },
      declined: {
        label: 'Declined',
        statuses: [courseRegistrationStatus.declined],
      },
      incompleteNotStarted: {
        label: 'Incomplete',
        statuses: [courseRegistrationStatus.notStarted],
      },
    };

    return {
      exportCourses: exportCourses,
      exportExternalCourses: exportExternalCourses,
      exportRegistrations: exportRegistrations,
      getRegistrations: getRegistrations,
      setTargetCompletionDate: setTargetCompletionDate,
      getStatusDictionary: getStatusDictionary,
      getAvailableCourses: getAvailableCourses,
      getTranscriptUrl: getTranscriptUrl,
      getRecentCourses: getRecentCourses,
      getCompilations: getCompilations,
      getCourses: getCourses,
      getCompilationModulesStat: getCompilationModulesStat,
      getScormSessions: getScormSessions,
      getSummary: getSummary,
    };

    function getTranscriptUrl(user) {
      if (securityService.isStateAvailable('main.userTranscript')) {
        return $state.href('main.userTranscript', { userId: user.id });
      } else if (securityService.isStateAvailable('main.userTranscriptReadOnly')) {
        return $state.href('main.userTranscriptReadOnly', { userId: user.id });
      } else {
        return null;
      }
    }

    function exportCourses(userId, options) {
      var fileName = (options && options.fileName) || 'user_courses_' + moment(new Date()).format('YYYY-MM-DD');
      var format = (options && options.format) || 'csv';
      var params = {
        format: format,
        file_name: fileName,
      };

      if (options) {
        params.query = angular.toJson(options.data);
      }

      $window.location.assign(['/a/user/', userId, '/courses/?', $httpParamSerializer(params)].join(''));
    }

    function exportExternalCourses(userId, options) {
      var fileName = (options && options.fileName) || 'user_courses_external' + moment(new Date()).format('YYYY-MM-DD');
      var format = (options && options.format) || 'csv';
      var params = {
        format: format,
        file_name: fileName,
      };

      if (options) {
        params.query = angular.toJson(options.data);
      }

      $window.location.assign(['/a/user/', userId, '/courses/external?', $httpParamSerializer(params)].join(''));
    }

    function exportRegistrations(userId, options) {
      var fileName = (options && options.fileName) || 'user_courses_' + moment(new Date()).format('YYYY-MM-DD');
      var format = (options && options.format) || 'csv';
      var params = {
        format: format,
        file_name: fileName,
        sort: options.sort,
      };

      if (options) {
        params.query = angular.toJson(options.data);
      }

      $window.location.assign(['/a/user/', userId, '/courses/registrations/?', $httpParamSerializer(params)].join(''));
    }

    function setTargetCompletionDate(params) {
      var currentPromise,
        targetCompletionDate = params.targetCompletionDate
          ? moment(params.targetCompletionDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;

      if (params.registrationId) {
        currentPromise = CourseRegistration.update(
          { registrationId: params.registrationId },
          { targetCompletionDate: targetCompletionDate },
        ).$promise;
      } else if (params.courseId) {
        var registration = new LearningObjectRegistration({ id: params.courseId });
        registration.courseId = params.courseId;
        currentPromise = registration.executeAction('AddToCurriculum', {
          target_completion_date: targetCompletionDate,
        });
      }
      return currentPromise;
    }

    function getStatusDictionary() {
      if (globalConfig.hasOwnProperty('customCoursesFilterStatuses')) {
        var customStatuses = globalConfig.customCoursesFilterStatuses;
        for (var statusIndex = 0; statusIndex < customStatuses.length; statusIndex++) {
          var customType = customStatuses[statusIndex];
          statusDictionary[customType] = customStatusDictionary[customType];
        }
      }

      return statusDictionary;
    }

    function getAvailableCourses(params) {
      var userId = params.userId ? params.userId : currentUser.get().id;

      return $http.get(['/a/user/', userId, '/courses/available/'].join(''), { params: params });
    }

    function getRegistrations(userId, params) {
      return $http
        .get(['/a/user/', userId, '/courses/registrations/'].join(''), { params: params })
        .then(function (response) {
          return response.data;
        });
    }

    function getRecentCourses(count) {
      var params = {};
      if (count) {
        angular.extend(params, { count: count });
      }

      return $http.get('/a/curriculum/recent_courses/', { params: params }).then(function (response) {
        return response.data;
      });
    }

    function getCompilations(userId, params) {
      return $http
        .get(['/a/user/', userId, '/courses/compilations/'].join(''), { params: params })
        .then(function (response) {
          return response.data;
        });
    }

    function getCourses(userId, params) {
      return $http.get(['/a/user/', userId, '/courses/'].join(''), { params: params }).then(function (response) {
        return response.data;
      });
    }

    function getCompilationModulesStat(courseId, userId) {
      return $http
        .get(['/a/user/', userId, '/courses/', courseId, '/compilation_modules_stat/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function getScormSessions(courseId, userId) {
      return $http
        .get(['/a/user/', userId, '/courses/' + courseId + '/registration_sessions/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function getSummary(params) {
      return $http.get('/a/curriculum/summary/', { params: params }).then(function (response) {
        return response.data;
      });
    }
  }
})();
