(function () {
  angular.module('kmi.lms.course.details').component('collectionStructureLayer', {
    template: require('ajs/modules/course/views/formats/collection/components/collection-structure-layer.html').default,
    controller: CollectionStructureLayerController,
    controllerAs: 'vm',
    bindings: {
      items: '=',
      blsCourse: '=?',
      itemTitle: '=?',
      itemRequired: '=?',
      highlightedItem: '=',
      nextItem: '=?',
      mobileView: '=',
      playCurrentOnly: '=?',
      simpleView: '<?',
      expandedItem: '<?',
      randomOrder: '=?',
    },
  });

  /* @ngInject */
  function CollectionStructureLayerController(
    $scope,
    courseRegistrationStatus,
    courseComponentsService,
    trainingService,
    elms,
    globalConfig,
    _,
  ) {
    var vm = this;

    vm.$onInit = onInit;
    vm.isArray = _.isArray;
    vm.isCompleted = isCompleted;
    vm.isOptional = isOptional;
    vm.isFrozen = isFrozen;
    vm.getNextItem = getNextItem;
    vm.calculateCourseCount = _.memoize(calculateCourseCount);
    vm.includesCoursesAndSections = _.memoize(includesCoursesAndSections);
    vm.formatDigit = elms.formatDigit;
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};

    function onInit() {
      initListChunks();
      bindIsCollapsed();

      $scope.$on('event:collection:unCollapseAll', function () {
        vm.isCollapsed = false;
      });

      $scope.$on('event:collection:structureChanged', bindIsCollapsed);
    }

    function initListChunks() {
      var chunk = [];

      vm.chunks = [];

      for (var i = 0; i < vm.items.length; i++) {
        if (vm.items[i].type === 2) {
          if (chunk.length) {
            vm.chunks.push(angular.copy(chunk));
            chunk.length = 0;
          }
          vm.chunks.push(vm.items[i]);
        } else {
          chunk.push(vm.items[i]);
        }
      }

      if (chunk.length) {
        vm.chunks.push(chunk);
      }
    }

    function isCompleted(statusId) {
      return _.includes(courseRegistrationStatus.strictCompletedSet, statusId);
    }

    function isOptional() {
      return !_.includes([true, false], vm.itemRequired);
    }

    function getNextItem(chunk) {
      var nextItem = null;

      if (chunk.randomOrder !== false) {
        return nextItem;
      }

      if (vm.playCurrentOnly) {
        nextItem = _.find(chunk.items, function (item) {
          return item.type === 1 && !isCompleted(item.status_id);
        });
      } else {
        nextItem = trainingService.findNextCollectionComponent(chunk.items);
      }

      return nextItem;
    }

    function calculateCourseCount(items) {
      var courseCount = 0;
      _.each(items, function (i) {
        courseCount += vm.isArray(i) ? _.get(_.filter(i, { type: 1 }), 'length') : Number(i.type === 1);
      });

      return courseCount;
    }

    function includesCoursesAndSections(items) {
      return items && _.get(_.filter(items, vm.isArray), 'length') !== items.length;
    }

    function isFrozen(items, item) {
      return _.get(vm.nextItem, 'id') !== item.id && _.get(vm.nextItem, 'order') <= item.order;
    }

    function bindIsCollapsed() {
      var courseCount = vm.calculateCourseCount(vm.chunks),
        hasCoursesAndSections = vm.includesCoursesAndSections(vm.chunks);

      vm.isCollapsed = (isCollapsedByExpandedItem() || areCollapsedAllItems()) && courseCount && !hasCoursesAndSections;
    }

    function isCollapsedByExpandedItem() {
      var subSectionRegExp = new RegExp(['\\s•\\s', elms.escapeRegExp(vm.expandedItem), '(\\s•\\s)?$'].join(''));

      // itemTitle must be 'subsec' or 'sec • subsec' or 'sec • sub1 • subsec' etc.
      return (
        vm.expandedItem &&
        vm.expandedItem !== 'all' &&
        vm.itemTitle &&
        !subSectionRegExp.test(vm.itemTitle) &&
        vm.itemTitle !== vm.expandedItem
      );
    }

    function areCollapsedAllItems() {
      return vm.itemTitle && vm.expandedItem === null;
    }
  }
})();
