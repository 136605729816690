(function () {
  angular.module('kmi.lms.dictionary', ['kmi.lms.core', 'kmi.lms.components']).config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider.state('main.dictionary', {
      url: '^/dictionary',
      component: 'dictionaryComponent',
      data: {
        label: 'Dictionary',
        availableFor: ['regularUser', 'admin'],
      },
    });
  }
})();
