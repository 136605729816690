(function () {
  angular.module('kmi.lms.course.common').factory('CourseCouponCodeAvailability', CourseCouponCodeAvailabilityService);

  /* @ngInject */
  function CourseCouponCodeAvailabilityService($resource) {
    return $resource(
      '/a/course/:courseId/coupon_codes/availability/',
      { courseId: '@courseId' },
      {},
      { stripTrailingSlashes: false },
    );
  }
})();
