(function () {
  /**
   * @ngdoc service
   * @name kmi.lms.core.service:httpRequestPermitService
   *
   *
   * @description
   * Check all errors to authorization permit problem
   */

  angular.module('kmi.lms.core').factory('httpRequestPermitService', httpRequestPermitService);

  /* @ngInject */
  function httpRequestPermitService($q, $injector) {
    return {
      responseError: function (rejection) {
        error(rejection);

        return $q.reject(rejection);
      },
    };

    function error(rejection) {
      //403 - Should be handled by controller which requested WS call
      if (
        !(rejection.status <= 0 && rejection.config.timeout) &&
        403 === rejection.status &&
        rejection.data === 'sida'
      ) {
        var authorizationService = $injector.get('authorizationService');
        // remove all authorization cookies
        authorizationService.removeCookie();
        // Check required authorization steps
        authorizationService.checkRequirements();
      }
    }
  }
})();
