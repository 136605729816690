(function () {
  angular.module('kmi.lms.course.registrations').factory('ExternalCourse', externalCourse);

  /* @ngInject */
  function externalCourse($resource) {
    return $resource(
      '/a/user/:userId/courses/external/:courseId/',
      { userId: '@userId', courseId: '@id' },
      { update: { method: 'PUT' } },
      { stripTrailingSlashes: false },
    );
  }
})();
