(function () {
  angular.module('kmi.lms.course.details').component('courseDetailsRightPanel', {
    template: require('ajs/modules/course/views/components/course-details-right-panel.html').default,
    controller: courseDetailsRightPanelController,
    controllerAs: 'vm',
    bindings: {
      course: '<',
      userCourse: '<',
    },
  });

  /* @ngInject */
  function courseDetailsRightPanelController(
    contentSectionsService,
    $uibModal,
    courseAccreditationsService,
    courseRegistrationStatus,
  ) {
    const vm = this;

    vm.showAccreditationStatements = showAccreditationStatements;
    vm.checkSubSectionIsAvailable = contentSectionsService.checkSubSectionIsAvailable;
    vm.creditTypesVisible = creditTypesVisible;
    vm.$onInit = onInit;

    function onInit(): void {
      vm.loading = courseAccreditationsService
        .getCourseAccreditationStatements(vm.course.id)
        .then((accreditationStatements) => {
          vm.accreditationStatements = accreditationStatements;
        })
        .finally(() => {
          vm.loading = null;
        });
    }

    function creditTypesVisible() {
      let statuses = [
        courseRegistrationStatus.withdrawn,
        courseRegistrationStatus.declined,
        courseRegistrationStatus.notPassed,
        courseRegistrationStatus.expired,
        courseRegistrationStatus.notStarted,
      ];
      let registration = vm.userCourse && vm.userCourse.lastRegistration;
      return (
        registration &&
        registration.creditTypes &&
        registration.creditTypes.length &&
        !statuses.includes(registration.statusId)
      );
    }

    function showAccreditationStatements() {
      $uibModal.open({
        component: 'accreditationStatementsModal',
        size: 'lg',
        resolve: {
          course: function () {
            return vm.course;
          },
          accreditationStatements: function () {
            return vm.accreditationStatements;
          },
        },
      });
    }
  }
})();
