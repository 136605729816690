(function () {
  angular
    .module('kmi.lms.core', [
      'ngCookies',
      'ngResource',
      'ngSanitize',
      'angular.filter',
      'ui.router',
      'ui.bootstrap',
      'globalDependencies',
      'http-auth-interceptor',
      'kmi.lms.services',
    ])
    .constant('globalConfig', (<any>window)._globalConfig) // globalConfig object's values are specified in project.json file. They are injected into index.html during build process.
    .constant('cookiePrefix', 'kmi.lms')
    .run(moduleRun)
    .config(moduleConfig);

  /* @ngInject */
  function moduleRun(entityDataService, $injector) {
    entityDataService.registerLoader('announcement', function (id) {
      var Announcement = $injector.get('Announcement');
      return Announcement.get({ announcementId: id }).$promise;
    });

    entityDataService.registerLoader('document', function (id) {
      var Document = $injector.get('Document');
      return Document.get({ documentId: id }).$promise;
    });

    entityDataService.registerLoader('profile', function (id) {
      var Profile = $injector.get('Profile');
      return Profile.get({ userId: id }).$promise;
    });

    entityDataService.registerLoader('user', function (id) {
      var User = $injector.get('User');
      return User.get(id).then(function (user) {
        return angular.extend(user, { $resolved: true });
      });
    });

    entityDataService.registerLoader('report', function (id) {
      var UserReport = $injector.get('UserReport');
      return UserReport.get({ reportId: id }).$promise;
    });
  }

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('edit', {
        abstract: true,
        url: '/edit',
        data: {
          label: 'Edit',
        },
        template: '<ui-view/>',
      })
      .state('view', {
        abstract: true,
        url: '/view',
        template: '<ui-view/>',
        data: {
          label: 'View',
        },
      });
  }
})();
