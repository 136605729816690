(function () {
  angular.module('kmi.lms.course.common').factory('CourseSession', CourseSessionService);

  /* @ngInject */
  function CourseSessionService($resource, uuid, $cacheFactory) {
    var courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');

    var CourseSession = $resource(
      '/a/course/:courseId/sessions/:sessionId/',
      { courseId: '@courseId', sessionId: '@id' },
      {
        query: {
          method: 'GET',
          params: { courseId: '@courseId', sessionId: '@id' },
          url: '/a/course/:courseId/sessions/:id/',
          cache: courseCache,
          transformResponse: function (data, headers, status) {
            if (status === 200) {
              var wrapped = angular.fromJson(data);

              angular.forEach(wrapped.items, function (item, idx) {
                wrapped.items[idx] = CourseSession.newSession(item);
              });

              return wrapped;
            }

            //When status is not 200 response body may contain error message or some useful data.
            if (headers('Content-Type') === 'application/json') {
              return angular.fromJson(data);
            }
            return data;
          },
          isArray: false,
        },
        clone: {
          method: 'POST',
          params: { courseId: '@courseId', clone: true },
          hasBody: false,
          interceptor: {
            response: function (response) {
              clearCache();
              return response.data;
            },
          },
        },
        update: {
          method: 'PUT',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        save: {
          method: 'POST',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        delete: {
          method: 'DELETE',
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        get: {
          method: 'GET',
          transformResponse: function (data, headers, status) {
            if (status === 200) {
              var wrapped = angular.fromJson(data);

              return CourseSession.newSession(wrapped);
            }

            //When status is not 200 response body may contain error message or some useful data.
            if (headers('Content-Type') === 'application/json') {
              return angular.fromJson(data);
            }
            return data;
          },
        },
        saveBulk: {
          method: 'POST',
          isArray: true,
          interceptor: {
            response: function () {
              clearCache();
            },
          },
        },
        getLearners: {
          method: 'GET',
          url: '/a/course/:courseId/sessions/:sessionId/learners/',
        },
      },
      {
        stripTrailingSlashes: false,
      },
    );

    function clearCache() {
      let cache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');
      cache.removeAll();
    }

    CourseSession.newSession = function (attrs) {
      return new CourseSession(
        angular.extend(
          {
            globalId: uuid.v4(),
            schedules: [],
            active: true,
            showInCalendar: true,
          },
          attrs,
        ),
      );
    };

    return CourseSession;
  }
})();
