(function () {
  angular.module('kmi.lms.core').component('lmsMainContainer', {
    template: require('ajs/modules/app/lms/lms-main-container.html').default,
    controllerAs: 'vm',
    controller: LmsMainContainerController,
  });

  /* @ngInject */
  function LmsMainContainerController($state, lmsIntegrityService) {
    var vm = this;

    vm.state = $state;
    vm.updateAlerts = updateAlerts;
    vm.$onInit = onInit;

    function onInit() {
      lmsIntegrityService.init();
      vm.alertsExists = false;
    }

    function updateAlerts(event) {
      vm.alertsExists = event;
    }
  }
})();
