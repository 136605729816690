(function () {
  angular.module('kmi.lms.core').factory('serverErrorHandlerService', function ($q, notificationService, _) {
    return {
      handleForbiddenError: function (reason, callback) {
        if (!reason) {
          return $q.reject(null);
        }

        let headers = reason.headers ? reason.headers() : {};
        if (_.get(headers, 'content-type', '').contains('text/html') && reason.status === 404) {
          return $q.reject(null);
        }

        if (_.includes([401, 403, 404], reason.status)) {
          let responseData, message, detail;

          try {
            responseData = angular.fromJson(reason.data);
          } catch {
            responseData = {};
          }

          message =
            responseData.message ||
            responseData.detail ||
            reason.data ||
            _.trim(reason.responseText, '"') ||
            'The remote server is not available at this time. Try again later.';

          detail = responseData.detail || detail;

          if (callback) {
            callback(message, detail);
          } else {
            notificationService.error(message, 5e3);
          }

          return $q.reject(null);
        } else {
          return $q.reject(reason);
        }
      },
    };
  });
})();
