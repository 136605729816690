(function () {
  /**
   * @ngdoc component
   * @name kmi.lms.admin.course.details.component:userAlsoRegisteredFor
   *
   * @requires $scope
   * @requires promotionService
   *
   * @description
   * Returns list of user's registrations
   */

  angular.module('kmi.lms.course.details').component('modernUserAlsoRegisteredFor', {
    bindings: {
      course: '=',
      trackingName: '<?',
      maxCount: '<?',
    },
    template: require('ajs/modules/course/views/components/modern/also-registered-for.html').default,
    controller: UserAlsoRegisteredForController,
    controllerAs: 'vm',
  });

  /*@ngInject*/
  function UserAlsoRegisteredForController($window, $http, _) {
    var vm = this;

    vm.$onInit = $onInit;

    function $onInit() {
      var window = angular.element($window);

      checkMobileView();

      window.bind('resize', checkMobileView);

      vm.loading = true;

      getAccompanyingCourses(vm.course.id)
        .then(function (response) {
          vm.courses = _.slice(
            _.map(response.data.courses, function (course) {
              course.labelName = course.labelName || course.label;
              return course;
            }),
            0,
            vm.maxCount || response.data.courses.length,
          );
        })
        .finally(function () {
          vm.loading = null;
        });

      function checkMobileView() {
        vm.mobileView = window.width() < 768;
      }
    }

    function getAccompanyingCourses(courseId) {
      return $http.get('/a/course_details/' + courseId + '/users_also_registered_for/');
    }
  }
})();
