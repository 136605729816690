(function () {
  angular.module('kmi.lms.course.registrations').component('manageCustomFieldsModalComponent', {
    template: require('ajs/modules/course/registrations/components/modal/manage-custom-fields.html').default,
    controller: ManageCustomFieldsModalController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function ManageCustomFieldsModalController() {
    var vm = this;
    vm.$onInit = onInit;

    vm.closeModal = closeModal;

    function onInit() {
      vm.workflow = vm.resolve.workflow;
    }

    function closeModal() {
      vm.modalInstance.close();
    }
  }
})();
