(function () {
  angular.module('kmi.lms.core').component('authTicketComplianceErrorComponent', {
    template: require('ajs/modules/app/authorization/modal/auth-ticket-compliance-error-modal.html').default,
    controller: AuthTicketComplianceErrorController,
    controllerAs: 'vm',
    bindings: {
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function AuthTicketComplianceErrorController(currentUser, $state) {
    var vm = this;
    vm.isLoginState = $state.current.name === 'prompt.login';
    vm.reload = reload;
    vm.close = close;
    vm.isAnonymous = currentUser.get().anonymous;

    function reload() {
      vm.modalInstance.close();
    }
    function close() {
      vm.modalInstance.dismiss();
    }
  }
})();
