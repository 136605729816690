(function () {
  angular.module('kmi.lms.course.registrations').component('courseDialogEmailPhysicalCarrier', {
    bindings: {
      workflow: '=',
    },
    template: require('ajs/modules/course/registrations/components/dialogs/course-dialog-email-physical-carrier.html')
      .default,
    controller: CourseDialogEmailPhysicalCarrierController,
    controllerAs: 'vm',
  });

  /* @ngInject */
  function CourseDialogEmailPhysicalCarrierController(_) {
    const vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
    }

    function isContinueDisabled() {
      return (
        (vm.emailForm && vm.emailForm.$invalid) ||
        _.get(vm.workflow, 'actionData.orderEmail.emailTo.length') === 0 ||
        _.get(vm.workflow, 'actionData.orderEmail.attachment.progress')
      );
    }
  }
})();
