(function () {
  angular.module('kmi.lms.course.registrations').component('registrationCustomInfo', {
    template: require('ajs/modules/course/registrations/components/custom-info/view/custom-info.html').default,
    controller: RegistrationCustomInfoController,
    controllerAs: 'vm',
    bindings: {
      editable: '=?',
      workflow: '=',
      infoFields: '=',
    },
  });

  /* @ngInject */
  function RegistrationCustomInfoController($scope, _) {
    var vm = this;

    vm.editInfo = editInfo;
    vm.saveInfo = saveInfo;
    vm.cancelEdit = cancelEdit;

    activate();

    function activate() {
      $scope.$on('event:course.registration.action.finished', updated);
    }

    function editInfo(info) {
      vm.infoToEdit = angular.copy(info);
      vm.editSubmitted = false;
    }

    function saveInfo() {
      vm.editSubmitted = true;
      if (!vm.infoToEdit.required || vm.infoToEdit.value) {
        var customFields = {};
        customFields[vm.infoToEdit.id] = vm.infoToEdit.value;
        vm.workflow.exec('UpdateCustomFieldsAction', { custom_fields: customFields });
      }
    }

    function cancelEdit() {
      vm.infoToEdit = null;
    }

    function updated() {
      if (vm.infoToEdit) {
        var updatedItem = _.find(vm.infoFields, { id: vm.infoToEdit.id });
        angular.extend(updatedItem, vm.infoToEdit);
        vm.infoToEdit = null;
      }
    }
  }
})();
