(function () {
  angular.module('kmi.lms.core').directive('lmsWcagLevel', lmsWcagLevel);

  function lmsWcagLevel(wcagLevelService, _) {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, element) {
      scope.$watch(
        function () {
          return wcagLevelService.get();
        },
        function (newValue, oldValue) {
          if (newValue && !angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
            element[0].id = 'wcag-' + newValue;
          } else {
            // default value always n
            element[0].id = 'wcag-n';
          }
        },
        true,
      );
    }
  }
})();
