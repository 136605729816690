(function () {
  angular.module('kmi.lms.course.registrations').component('addToCalendarComponent', {
    template: require('ajs/modules/course/registrations/calendar/modal/add-to-calendar.html').default,
    controller: AddToCalendarController,
    controllerAs: 'vm',
    bindings: {
      resolve: '=',
      modalInstance: '=',
    },
  });

  /* @ngInject */
  function AddToCalendarController($window, calendarService) {
    var vm = this;
    vm.$onInit = onInit;

    // calendar types: 'local', 'ms', 'google', 'yahoo'
    vm.calendarType = 'local';
    vm.submit = addToCalendar;
    vm.cancel = cancelModal;

    function onInit() {
      vm.schedule = vm.resolve.schedule;
    }

    function addToCalendar() {
      var url = calendarService[vm.calendarType](vm.schedule);

      if (vm.calendarType === 'local') {
        $window.location.assign(url);
      } else {
        $window.open(url, '_blank');
      }

      vm.modalInstance.dismiss('cancel');
    }

    function cancelModal() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
