(function () {
  angular.module('kmi.lms.course.details').directive('targetCapabilities', function () {
    return {
      restrict: 'EA',
      scope: {
        course: '=',
        count: '=?',
      },
      template: require('ajs/modules/course/views/components/target-capabilities.html').default,
      controller: TargetCapabilities,
      bindToController: true,
      controllerAs: 'vm',
    };
  });

  /* @ngInject */
  function TargetCapabilities($http) {
    var vm = this;

    vm.targetCapabilities = [];

    vm.$onInit = active;

    function active() {
      $http.get('/a/course/' + vm.course.id + '/target_capabilities/').then(function (response) {
        vm.targetCapabilities = response.data;

        if (vm.targetCapabilities.length > 0) {
          vm.targetCapabilities[0].expanded = true;
        }

        vm.count = vm.targetCapabilities.length;
      });
    }
  }
})();
