(function () {
  angular
    .module('kmi.lms.course.common')
    .value('courseDefaults', {
      newUpdatePeriodDays: 7,
      completionOptionDescriptions: {
        1: 'Registration move to the next step upon launch',
        2: 'Registration will stay In Progress until learner completes it manually',
      },
    })
    .factory(
      'courseService',
      function (
        $http,
        $cacheFactory,
        $uibModal,
        apiUrl,
        globalConfig,
        _,
        menuService,
        $window,
        moment,
        $httpParamSerializer,
        $state,
        metaService,
      ) {
        var courseOptionsCache = $cacheFactory('courseOptions');

        return {
          getCourseOptions: getCourseOptions,
          getCourseTrainingOptions: getCourseTrainingOptions,
          getCourseAvailability: getCourseAvailability,
          getCourseTileData: getCourseTileData,
          cloneCourse: cloneCourse,
          immediatelyCloneDialog: immediatelyCloneDialog,
          isClonable: isClonable,
          getPrerequisiteForCourses: getPrerequisiteForCourses,
          copyCourseLink: copyCourseLink,
          exportCourseEditHistory: exportCourseEditHistory,
          setMetaInfo: setMetaInfo,
          getCourseTrainingCategories: getCourseTrainingCategories,
        };

        function getCourseOptions() {
          return $http.get(apiUrl('/a/course/options/'), { cache: courseOptionsCache }).then(function (response) {
            return response.data;
          });
        }

        function getCourseTrainingOptions(courseId) {
          var courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');

          // Exists duplicate in roster training service - courseRegistrationManagerService
          return $http
            .get(['/a/course/', courseId, '/training_options/'].join(''), { cache: courseCache })
            .then(function (response) {
              return response.data;
            });
        }

        function getCourseTileData(courseId) {
          // Exists duplicate in roster training service - courseRegistrationManagerService
          return $http
            .get(['/a/course/', courseId, '/course_tile_data/'].join(''), { cache: true })
            .then(function (response) {
              return response.data;
            });
        }

        function getCourseAvailability() {
          return $http.get(apiUrl('/a/course/availability/'), { cache: true }).then(function (response) {
            return response.data;
          });
        }

        function immediatelyCloneDialog(course) {
          return $uibModal.open({
            component: 'cloneCourseModal',
            size: 'lg',
            resolve: {
              course: function () {
                return course;
              },
            },
          }).result;
        }

        function cloneCourse(course) {
          if (_.get(globalConfig, 'settings.courseDetails.adminDetailsFormats', []).includes(course.format)) {
            return immediatelyCloneDialog(course);
          } else {
            return $uibModal.open({
              component: 'cloneCourseAsFormatModal',
              size: 'lg',
              resolve: {
                course: function () {
                  return course;
                },
              },
            }).result;
          }
        }

        function isClonable(course) {
          var nonClonableFormats = [5, 6]; // Conference, Conference Session
          var isCloneMenuAvailable =
            isStateAvailable(menuService.getAdminMenuItems(), 'main.admin.courses.add') ||
            isStateAvailable(menuService.getAdminMenuItems(), 'main.admin.training.add');

          if (
            globalConfig.settings.courseCloneSettings &&
            globalConfig.settings.courseCloneSettings.isClonable &&
            isCloneMenuAvailable
          ) {
            return course.formatTypeId && !_.includes(nonClonableFormats, course.formatTypeId);
          }
          return false;

          function isStateAvailable(menuItems, state) {
            return (
              _.some(menuItems, (item) => {
                return item.state === state;
              }) ||
              _.some(
                _.filter(menuItems, (item) => {
                  return item.children;
                }),
                function (item) {
                  return isStateAvailable(item.children, state);
                },
              )
            );
          }
        }

        function getPrerequisiteForCourses(course) {
          return $http.get(apiUrl('/a/course/' + course.id + '/prerequisite_for/')).then(function (response) {
            return response.data;
          });
        }

        function copyCourseLink(courseId, title) {
          $uibModal.open({
            component: 'copyLinkDialog',
            keyboard: false,
            resolve: {
              title: function () {
                return title || 'Copy course link';
              },
              link: function () {
                const portal = globalConfig.base.split('/').filter((x) => !!x)[0];
                return [$window.location.origin, '/gateway/course/', courseId, '/view', '?portal=', portal].join('');
              },
            },
          });
        }

        function exportCourseEditHistory(courseId, options) {
          var fileName =
            (options && options.fileName) ||
            ['course_edit_history', courseId, moment(new Date()).format('YYYY-MM-DD')].join('_');
          var format = (options && options.format) || 'csv';
          var params = { format: format, file_name: fileName };

          if (options) {
            params.query = angular.toJson(options.data);
          }

          $window.location.assign(['/a/course/', courseId, '/history/?', $httpParamSerializer(params)].join(''));
        }

        function setMetaInfo(course) {
          var ogpImage = '',
            title = course.name,
            description = course.description;

          if (globalConfig.settings.courseDetails && globalConfig.settings.courseDetails.skillLevelCourseFormatMeta) {
            title = [course.name, course.skillLevelName].join(' ');
            description = [
              course.name,
              ' is a ',
              course.skillLevelName,
              ' ',
              course.formatName,
              ' formatted class focusing on ',
              _.map(course.subjectAreas, 'name').join(', '),
              !!course.length ? ['. Approximately ', course.length, ' hrs will be needed to complete'].join('') : '',
            ].join('');
          }

          if (course.previewImage && course.previewImage.id && course.previewImage.rnd) {
            ogpImage = ['/a/media/file/', course.previewImage.id, '/?rnd=', course.previewImage.rnd].join('');
          }

          metaService.setMeta({
            title: title,
            description: description,
            url: $state.href('main.course', { id: course.id }),
            image: ogpImage,
            type: 'website',
          });
        }

        function getCourseTrainingCategories(courseId) {
          const courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');

          return $http
            .get(['/a/course/', courseId, '/training-categories/'].join(''), { cache: courseCache })
            .then(function (response) {
              return response.data;
            });
        }
      },
    );
})();
