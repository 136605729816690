(function () {
  angular.module('kmi.lms.course.details').component('conferenceSessionWaysOfAttendance', {
    template:
      require('ajs/modules/course/views/formats/collection/conference/components/session-ways-of-attendance.html')
        .default,
    controller: ConferenceSessionWaysOfAttendanceController,
    controllerAs: 'vm',
    bindings: {
      session: '=',
    },
  });

  /* @ngInject */
  function ConferenceSessionWaysOfAttendanceController(courseEnums, $scope) {
    const vm = this;
    vm.$onInit = onInit;
    vm.locationType = courseEnums.locationType;

    function onInit() {
      if (
        (vm.session.course.attendCap !== null && vm.session.course.attendCap !== undefined) ||
        (vm.session.course.attendVirtualCapacity !== null && vm.session.course.attendVirtualCapacity !== undefined)
      ) {
        initCourseCapacity();
      }
    }

    function initCourseCapacity() {
      vm.inPersonAvailable = vm.session.course.attendCap - vm.session.seatsAllocated.inPersonAllocated;
      vm.virtualAvailable = vm.session.course.attendVirtualCapacity - vm.session.seatsAllocated.virtualAllocated;

      $scope.$on('event:course.registration.updated', function (event, registration) {
        if (vm.session.blsCourseId !== registration.courseId) {
          return;
        }

        vm.inPersonAvailable = vm.session.course.attendCap - registration.inPersonAllocated;
        vm.virtualAvailable = vm.session.course.attendVirtualCapacity - registration.virtualAllocated;
      });
    }
  }
})();
