(function () {
  angular.module('kmi.lms.course.details').component('courseAvailableCredits', {
    template: require('ajs/modules/course/views/components/credits/course-available-credits.html').default,
    controller: CourseAvailableCreditsController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
    },
  });

  /* @ngInject */
  function CourseAvailableCreditsController(_, courseEnums, $window) {
    const vm = this;

    vm.$onInit = onInit;
    vm.courseEnums = courseEnums;
    vm.creditTypeSelectionEnabled = false;

    function onInit() {
      vm.creditTypeSelectionEnabled = _.get($window, 'elmsEnvironment.settings.creditTypeSelectionEnabled', false);
    }
  }
})();
