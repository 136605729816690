(function () {
  angular.module('kmi.lms.course.registrations').factory('calendarService', calendarService);

  /* @ngInject */
  function calendarService($httpParamSerializerJQLike, moment) {
    return {
      local: getLocalCalendarUrl,
      ms: getMSCalendarUrl,
      yahoo: getYahooCalendarUrl,
      google: getGoogleCalendarUrl,
    };

    function getLocalCalendarUrl(schedule) {
      var urlTail = '';
      if (moment(schedule.end_date).diff(moment(schedule.start_date), 'days') > 0) {
        urlTail += '?multiDay=true';
      } else {
        urlTail += [schedule.id || '', schedule.id ? '' : '?type=2'].join('');
      }
      return ['/a/course_registrations/', schedule.registration_id, '/event_export/', urlTail].join('');
    }

    /**
     * Since we don't have official documentation about how to generate the link and what params and their formats
     * are supposed to be, there is a specific repository that monitors every change.
     * @namespace kmi.lms.user.learning
     * @desc generates deep link for Outlook live
     * @memberOf calendarService
     * @see https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/master/services/outlook-web.md
     */
    function getMSCalendarUrl(schedule) {
      var url = 'https://outlook.live.com/owa/';

      var query = {
        rru: 'addevent',
        startdt: [formatDate(schedule.utc_start_date, 'UTC', 'YYYY-MM-DDTHH:mm:ss'), 'Z'].join(''),
        enddt: [formatDate(schedule.utc_end_date, 'UTC', 'YYYY-MM-DDTHH:mm:ss'), 'Z'].join(''),
        subject: schedule.subject,
        location: schedule.locationDescription || buildLocationString(schedule),
        body: schedule.description,
      };

      return buildUrl(url, query);
    }

    /**
     * Since we don't have official documentation about how to generate the link and what params and their formats
     * are supposed to be, there is a specific repository that monitors every change.
     * @namespace kmi.lms.user.learning
     * @desc generates deep link for Yahoo calendar
     * @memberOf calendarService
     * @see https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/master/services/yahoo.md
     */
    function getYahooCalendarUrl(schedule) {
      var url = 'https://calendar.yahoo.com/',
        duration = moment(schedule.end_date).diff(moment(schedule.start_date)),
        query = {
          v: '60',
          TITLE: schedule.subject,
          DUR: duration ? moment(duration).format('HHmm') : null,
          ST: [formatDate(schedule.utc_start_date, 'UTC'), 'Z'].join(''),
          ET: [formatDate(schedule.utc_end_date, 'UTC'), 'Z'].join(''),
          DESC: schedule.description,
          in_loc: schedule.locationDescription || buildLocationString(schedule),
        };

      return buildUrl(url, query);
    }

    /**
     * Since we don't have official documentation about how to generate the link and what params and their formats
     * are supposed to be, there is a specific repository that monitors every change.
     * @namespace kmi.lms.user.learning
     * @desc generates deep link for Google calendar
     * @memberOf calendarService
     * @see https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/master/services/google.md
     */
    function getGoogleCalendarUrl(schedule) {
      var url = 'https://calendar.google.com/calendar/render';

      var query = {
        action: 'TEMPLATE',
        sf: true,
        output: 'xml',
        text: schedule.subject,
        dates: [formatDate(schedule.utc_start_date, 'UTC') + 'Z', formatDate(schedule.utc_end_date, 'UTC') + 'Z'].join(
          '/',
        ),
        details: schedule.description,
        location: schedule.locationDescription || buildLocationString(schedule),
      };

      return buildUrl(url, query);
    }

    function buildUrl(url, query) {
      return [url, $httpParamSerializerJQLike(query)].join('?');
    }

    function buildLocationString(schedule) {
      return [schedule.name, schedule.streetAddress, schedule.city, schedule.state, schedule.zip].join(', ');
    }

    function formatDate(date, toUTC, pattern) {
      var formatPattern = pattern || 'YYYYMMDDTHHmmss';

      return toUTC ? moment.utc(date).format(formatPattern) : date ? moment(date).format(formatPattern) : '';
    }
  }
})();
