(function () {
  angular.module('kmi.lms.core').directive('lmsHeaderTitle', lmsHeaderTitle);

  function lmsHeaderTitle(siteSettingService, metaService, customTitleService, globalConfig, _) {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, element) {
      scope.meta = metaService;
      scope.siteSettings = siteSettingService.getSiteSetting();
      scope.customTitle = customTitleService.base(scope.siteSettings);

      scope.$watch(
        function () {
          if (globalConfig.settings.view !== 'admin') {
            return scope.customTitle.pageTitle() || scope.meta.title() || scope.siteSettings.pageTitle;
          } else {
            return scope.customTitle.pageTitle() || scope.siteSettings.pageTitle;
          }
        },
        function (newValue, oldValue) {
          if (newValue && !angular.equals(_.sortBy(newValue), _.sortBy(oldValue))) {
            element.text(newValue);
          }
        },
        true,
      );
    }
  }
})();
