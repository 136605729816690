(function () {
  angular.module('kmi.lms.core').factory('wcagLevelService', wcagLevelService);

  /* @ngInject */
  function wcagLevelService() {
    var defaultValue = 'n',
      wcagLevel = JSON.parse(sessionStorage.getItem('wcagLevel')) || defaultValue;

    return {
      get: getWcagLevel,
      set: setWcagLevel,
    };

    function getWcagLevel() {
      return wcagLevel;
    }

    function setWcagLevel(value) {
      wcagLevel = value || defaultValue;
      sessionStorage.setItem('wcagLevel', JSON.stringify(wcagLevel));
    }
  }
})();
