(function () {
  angular.module('kmi.lms.course.details').component('registrationOpensState', {
    template: require('ajs/modules/course/views/components/state-messages/registration-opens-indicator.html').default,
    controller: RegistrationOpensStateController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
    },
  });

  /* @ngInject */
  function RegistrationOpensStateController(moment, courseEnums) {
    const vm = this;

    vm.$onInit = onInit;
    vm.format = courseEnums.format;

    function onInit() {
      vm.opens =
        vm.course.courseFormat.registrationRequired &&
        vm.course.registrationOpensDate &&
        moment(vm.course.registrationOpensDate).diff(moment(), 'minutes') >= 0;
    }
  }
})();
