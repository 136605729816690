(function () {
  angular
    .module('kmi.lms.course.common')
    .constant('playableCollectionCourseFormats', [102, 118, 121, 108, 109, 116, 43, 115, 103, 101, 130, 129])
    .factory('collectionsService', collectionsService);

  /* @ngInject */
  function collectionsService($http, courseRegistrationService, playableCollectionCourseFormats, _) {
    return {
      aggregateRegistrationsInfo: aggregateRegistrationsInfo,
      updateChangedComponents: updateChangedComponents,
      getCourseCollections: getCourseCollections,
      getComponentsEarnedCertificates: getComponentsEarnedCertificates,
      isCollectionPlayable: isCollectionPlayable,
      getCollectionStructureMeta: getCollectionStructureMeta,
      checkLocationTypeForComponents: checkLocationTypeForComponents,
    };

    function aggregateRegistrationsInfo(compilationStructure, includeOptional) {
      var countItems = courseRegistrationService.calculateCollectionProgress(
        {
          items: compilationStructure || [],
          type: 2,
          required: true,
        },
        includeOptional,
      );

      return {
        completed: countItems.total > 0 ? (countItems.strictlyCompleted / countItems.total) * 100 : 0,
        total: countItems.total,
        completedCount: countItems.strictlyCompleted,
      };
    }

    function updateChangedComponents(compilationStructure, newCompilationStructure) {
      return _compareComponents(compilationStructure, newCompilationStructure);

      function orderFn(item1, item2) {
        if (item1.order > item2.order) {
          return 1;
        } else if (item1.order < item2.order) {
          return -1;
        } else if (item1.order === item2.order) {
          return 0;
        }
      }

      function _compareComponents(items, newItems) {
        let i = items.length - 1;
        let updatedItems = [];

        for (; i >= 0; i--) {
          let item = items[i];
          if (item.type === 1 && item.status_id !== newItems[i].status_id) {
            item = angular.extend({}, items[i], newItems[i]);
            if (!item.registration) {
              item.registration = {};
            }
          }

          if (item.type === 2) {
            item.items = _compareComponents(item.items, newItems[i].items);
          }

          updatedItems.push(item);
        }

        return updatedItems.sort(orderFn);
      }
    }

    function getComponentsEarnedCertificates(courseId) {
      return $http
        .get(['/a/course/', courseId, '/collections/components/earned-certificates/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function getCourseCollections(courseId, formatIds, permittedFor, skipSystem, full) {
      var params = {};
      if (formatIds) {
        params.formatIds = formatIds;
      }

      if (permittedFor) {
        params.permitted_for = permittedFor;
      }

      if (skipSystem) {
        params.skip_system = true;
      }

      if (full) {
        params.full = true;
      }

      return $http
        .get(['/a/course/', courseId, '/collections/'].join(''), { params: params, cache: true })
        .then(function (response) {
          return _.flatten(
            _.map(response.data, function (collection) {
              if (collection.hours && collection.hours.length) {
                return _.map(collection.hours, function (collectionHours) {
                  return angular.extend(
                    {
                      hours: collectionHours.hours,
                    },
                    _.omit(collection, 'hours'),
                  );
                });
              }

              return collection;
            }),
          );
        });
    }

    function checkLocationTypeForComponents(collectionId, locationTypeId) {
      return $http
        .post(['/a/course/', collectionId, '/collections/check_components_location_type/'].join(''), {
          locationTypeId: locationTypeId,
        })
        .then(function (response) {
          return response.data;
        });
    }

    function isCollectionPlayable(collectionStructure) {
      function hasPlayableCourse(items) {
        var i = items.length - 1;

        for (; i >= 0; i--) {
          if (
            (items[i].type === 1 && _.includes(playableCollectionCourseFormats, items[i].format)) ||
            (items[i].type === 2 && hasPlayableCourse(items[i].items))
          ) {
            return true;
          }
        }

        return false;
      }

      return hasPlayableCourse(collectionStructure);
    }

    function getCollectionStructureMeta(structure, nextCourse) {
      var metaData = {
        firstSection: null,
        currentCourseSection: null,
      };

      function _scanItems(items, parent) {
        items.forEach(function (item) {
          if (item.type === 1 && item.id === nextCourse.id) {
            metaData.currentCourseSection = metaData.currentCourseSection || parent;
          }

          if (item.type === 2) {
            metaData.firstSection = metaData.firstSection || item;
            _scanItems(item.items, item);
          }
        });
      }

      _scanItems(structure, 'root');

      return metaData;
    }
  }
})();
