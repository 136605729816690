(function () {
  angular
    .module('kmi.lms.course.common')
    .constant('CourseMediaTypes', {
      introductionVideo: 1,
      videoContent: 2,
      content: 3,
      googleDrive: 4,
      office365: 5,
      scormPackage: 6,
      contentWithName: 7,
      article: 8,
      scormCloud: 9,
      scormManifest: 10,
      audio: 11,
    })
    .constant('MediaHostingTypes', {
      vimeo: 1,
      limeliteOrchestrate: 2,
      scormCloud: 3,
    })
    .factory('courseMediaService', CourseMediaService);

  /* @ngInject */
  function CourseMediaService($http, CourseMediaTypes, _) {
    return {
      getMediaInfo: getMediaInfo,
      cloneContent: cloneContent,
      removeContent: removeContent,
      isContent: isContent,
    };

    function getMediaInfo(courseId, typeId) {
      return $http.get(['/a/course/media/', courseId, '/type/', CourseMediaTypes[typeId], '/'].join(''));
    }

    function cloneContent(courseId, cloneTo, targetStorage) {
      targetStorage = targetStorage || 'course_content_cloud';

      return $http
        .post(
          [
            '/a/course/media/',
            courseId,
            '/uploaded_content/?target_storage=',
            targetStorage,
            '&clone_to=',
            cloneTo,
          ].join(''),
        )
        .then(function (response) {
          return response.data;
        });
    }

    function removeContent(courseId, targetStorage) {
      targetStorage = targetStorage || null;

      return $http
        .delete(
          [
            '/a/course/media/',
            courseId,
            '/uploaded_content/',
            targetStorage ? ['?target_storage=', targetStorage].join('') : '',
          ].join(''),
        )
        .then(function (response) {
          return response.data;
        });
    }

    function isContent(item) {
      return _.includes(
        [CourseMediaTypes.content, CourseMediaTypes.contentWithName, CourseMediaTypes.scormManifest],
        item.type,
      );
    }
  }
})();
