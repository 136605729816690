(function () {
  angular.module('kmi.lms.core').factory('EventDispatcher', EventDispatcherService);

  function EventDispatcherService() {
    function EventDispatcher() {
      this._listeners = {};

      this.on = function (type, listener) {
        if (!this._listeners[type]) {
          this._listeners[type] = [];
        }
        this._listeners[type].push(listener);
      };

      this.removeEventListener = function (type, listener) {
        if (this._listeners[type]) {
          var index = this._listeners[type].indexOf(listener);

          if (index !== -1) {
            this._listeners[type].splice(index, 1);
          }
        }
      };

      this.dispatchEvent = function (type, params) {
        var listeners = this._listeners[type];

        if (listeners) {
          for (var i = listeners.length - 1; i >= 0; i--) {
            listeners[i](params);
          }
        }
      };
    }

    return EventDispatcher;
  }
})();
