(function () {
  angular.module('kmi.lms.course.details').factory('conferenceDetailsService', conferenceDetailsService);

  function conferenceDetailsService(_, moment, courseRegistrationStatus, courseEnums) {
    return {
      isSessionRegistrationAllowed: isSessionRegistrationAllowed,
      isSessionPlayButtonAllowed: isSessionPlayButtonAllowed,
      isCompleted: isCompleted,
      isRegistered: isRegistered,
      isSessionJoinAllowed: isSessionJoinAllowed,
      sessionEnded: sessionEnded,
    };

    function isSessionJoinAllowed(session, sessionUserCourse) {
      if (!isRegistered(sessionUserCourse)) {
        return false;
      }

      // If location is not allow join return false
      if (_.get(session, 'course.courseLocation.typeId') === courseEnums.locationType.inPerson) {
        return false;
      }

      // If registration marked as in person do not allow to join return and false
      if (_.get(sessionUserCourse, 'lastRegistration.attendanceTypeId') === courseEnums.locationType.inPerson) {
        return false;
      }

      const startDate = _.get(session, 'course.startDate');
      return startDate && moment(startDate).diff(moment(), 'minutes') < 30 && !sessionEnded(session);
    }

    function sessionEnded(session) {
      const endDate = _.get(session, 'course.endDate');
      return endDate && moment(endDate).diff(moment(), 'seconds') < 0;
    }

    function isRegistered(userCourse) {
      const statusId = _.get(userCourse, 'statusId');
      return (
        courseRegistrationStatus.inProgressSet.includes(statusId) || courseRegistrationStatus.approved === statusId
      );
    }

    function isCompleted(conferenceUserCourse) {
      return courseRegistrationStatus.completedSet.includes(_.get(conferenceUserCourse, 'statusId'));
    }

    function isSessionRegistrationAllowed(course, session, conferenceUserCourse) {
      if (!isRegistered(conferenceUserCourse)) {
        return false;
      }

      return !(course.registrationDeadline && moment(course.registrationDeadline).diff(moment(), 'seconds') < 0);
    }

    function isSessionPlayButtonAllowed(course, session, sessionUserCourse, conferenceUserCourse) {
      return (
        (isSessionRegistrationAllowed(course, session, conferenceUserCourse) && !isRegistered(sessionUserCourse)) ||
        (isRegistered(sessionUserCourse) && sessionEnded(session))
      );
    }
  }
})();
