(function () {
  angular
    .module('kmi.lms.course.registrations')
    .constant('CourseBulkEnrollmentSessionStatus', {
      NotStarted: 1,
      Pending: 2,
      InProgress: 3,
      Complete: 4,
      Error: 5,
    })
    .factory('CourseBulkEnrollmentSession', BulkEnrollmentSessionService);

  /* @ngInject */
  function BulkEnrollmentSessionService($resource, uuid, _, rootScopeService, CourseBulkEnrollmentSessionStatus) {
    var CourseBulkEnrollmentSession = $resource(
      '/a/course_registrations/bulk-enrollment-sessions/:sessionId/',
      { sessionId: '@id' },
      {
        get: {
          method: 'GET',
          transformResponse: function (data, headers, status) {
            if (status === 200) {
              return CourseBulkEnrollmentSession.newSession(data);
            }
            if (headers('Content-Type') === 'application/json') {
              return angular.fromJson(data);
            }
            return data;
          },
        },
        save: {
          method: 'POST',
        },
      },
      {
        stripTrailingSlashes: false,
      },
    );

    Object.defineProperty(CourseBulkEnrollmentSession, 'defaults', {
      get: function () {
        return {
          actionOptions: { bypassNotifications: false },
          globalId: uuid.v4(),
        };
      },
    });

    CourseBulkEnrollmentSession.newSession = function (data) {
      return new CourseBulkEnrollmentSession(
        angular.extend(CourseBulkEnrollmentSession.defaults, angular.fromJson(data)),
      );
    };

    CourseBulkEnrollmentSession.prototype.isProcessing = function () {
      return _.includes(
        [
          CourseBulkEnrollmentSessionStatus.NotStarted,
          CourseBulkEnrollmentSessionStatus.Pending,
          CourseBulkEnrollmentSessionStatus.InProgress,
        ],
        this.statusId,
      );
    };

    CourseBulkEnrollmentSession.prototype.isComplete = function () {
      return _.includes(
        [CourseBulkEnrollmentSessionStatus.Complete, CourseBulkEnrollmentSessionStatus.Error],
        this.statusId,
      );
    };

    return CourseBulkEnrollmentSession;
  }
})();
