(function () {
  angular.module('kmi.lms.core').component('policiesComponent', {
    template: require('ajs/modules/app/policies.html').default,
    controller: PoliciesController,
    controllerAs: 'vm',
  });

  function PoliciesController(
    $scope,
    $sce,
    policiesService,
    $timeout,
    $uiRouterGlobals,
    backUrlService,
    markdownService,
    _,
  ) {
    var vm = this;

    vm.backUrl = backUrlService;
    vm.getPoliciesName = getPoliciesName;
    vm.getPoliciesText = getPoliciesText;
    vm.$onInit = onInit;

    function onInit() {
      vm.loading = true;

      policiesService
        .getPolicies()
        .then(function (policies) {
          vm.policies = policies;
          vm.policies.text = policies.text;
          highlightSection();
        })
        .finally(() => {
          vm.loading = null;
        });
    }

    function highlightSection() {
      if ($uiRouterGlobals.params.scrollTo) {
        $timeout(function () {
          $scope.$broadcast('event:scrollTo', $uiRouterGlobals.params.scrollTo);
        });
      }
    }

    function _getHtmlSafe(key) {
      return $sce.trustAsHtml(markdownService.toHtml(_.get(vm.policies, key, '')));
    }

    function getPoliciesText() {
      return _getHtmlSafe('text');
    }

    function getPoliciesName() {
      return _getHtmlSafe('name');
    }
  }
})();
