(function () {
  angular.module('kmi.lms.course.common').factory('courseAccreditationsService', courseAccreditationsService);

  function courseAccreditationsService($http) {
    return {
      getCourseAccreditationStatements: getCourseAccreditationStatements,
    };

    function getCourseAccreditationStatements(courseId) {
      return $http.get(['/a/course/', courseId, '/accreditations/statements/'].join('')).then(function (response) {
        return response.data;
      });
    }
  }
})();
