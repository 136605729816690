(function () {
  angular.module('kmi.lms.core').directive('corporateLicenceCode', function ($q, Group, groupService) {
    var canceler;

    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl) {
        ctrl.$asyncValidators.corporateLicenceCode = function (newCode) {
          var deferred = $q.defer();
          if (!newCode) {
            deferred.resolve();
          } else {
            if (canceler) {
              canceler.resolve({ canceled: true });
            }
            canceler = $q.defer();
            groupService.validateCorporateLicenseCode(newCode).then(function (response: boolean) {
              if (response === true) {
                deferred.resolve();
              } else {
                deferred.reject();
              }
            });
          }
          return deferred.promise;
        };
      },
    };
  });
})();
