(function () {
  angular.module('kmi.lms.course.details').component('modernCourseDescriptionHero', {
    template: require('ajs/modules/course/views/components/modern/course-description-hero.html').default,
    bindings: {
      course: '<?',
      userCourse: '=?',
    },
    controllerAs: 'vm',
    controller: ModernCourseDescriptionHero,
  });

  /* @ngInject */
  function ModernCourseDescriptionHero(playCourseService) {
    var vm = this;
    vm.isPlayCourseAvailable = isPlayCourseAvailable;
    vm.hasContent = hasContent;
    vm.playButtonAvailable = false;

    function isPlayCourseAvailable() {
      return (
        playCourseService.isCoursePlayAvailable(vm.course, vm.userCourse, 'roundedView') ||
        playCourseService.isPrintCourseAvailable(vm.course, vm.userCourse)
      );
    }

    function hasContent() {
      return (vm.course.description && vm.course.description.trim().length > 0) || vm.playButtonAvailable;
    }
  }
})();
