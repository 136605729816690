(function () {
  angular.module('kmi.lms.course.common').component('conferenceSessionTimeBadge', {
    template: require('ajs/modules/course/common/session-time-badge.html').default,
    controller: ConferenceSessionTimeBadgeController,
    controllerAs: 'vm',
    bindings: {
      session: '<',
    },
  });

  /* @ngInject */
  function ConferenceSessionTimeBadgeController() {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {}
  }
})();
