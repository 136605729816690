(function () {
  angular.module('kmi.lms.course.details').component('modernCollectionPrerequisites', {
    template: require('ajs/modules/course/views/components/modern/prerequisites/collection-prerequisites.html').default,
    controller: CollectionPrerequisitesController,
    controllerAs: 'vm',
    bindings: {
      course: '<',
      trackingName: '<?',
    },
  });

  /* @ngInject */
  function CollectionPrerequisitesController(
    $scope,
    $window,
    coursePrerequisitesService,
    _,
    moment,
    Course,
    courseRegistrationStatus,
  ) {
    var vm = this,
      window = angular.element($window);

    vm.$onInit = onInit;
    vm.requiredPrerequisites = [];
    vm.prerequisiteTypes = {
      course: 1,
      bls: 2,
      ls: 3,
    };

    function onInit() {
      vm.mobileView = window.width() < 768;
      window.bind('resize', setWindowWidth);

      function setWindowWidth() {
        vm.mobileView = window.width() < 768;
        $scope.$apply();
      }

      var isExpired = vm.course.deactivationDate && moment(vm.course.deactivationDate).diff(moment(), 'hours') < 0;

      if (vm.course.active && !isExpired && vm.course.courseFormat.registrationRequired) {
        coursePrerequisitesService.getCoursePrerequisites(vm.course.id).then(function (prerequisites) {
          var courses = _.map(prerequisites.prerequisites, 'course_id');
          var requiredPrerequisites = _.concat(prerequisites.ls_prerequisites, prerequisites.bls_prerequisites);

          vm.requiredPrerequisites = _.keyBy(requiredPrerequisites, 'course_id');
          vm.allPrerequisites = _adjustWithCourseObject(
            _.uniqBy(
              _.concat(
                prerequisites.prerequisites,
                _.filter(requiredPrerequisites, function (prerequisite) {
                  return !_.includes(courses, prerequisite.course_id);
                }),
              ),
              'course_id',
            ),
          );
        });
      }

      function _adjustWithCourseObject(prerequisites) {
        return _.map(prerequisites, function (p) {
          if (!p.course) {
            var courseObj = {
              id: p.course_id,
              name: p.course_name,
              description: p.course_description,
              length: p.course_length_in_hours,
              format: p.course_format_id,
              formatTypeId: p.course_format_type_id,
              status_id: p.course_status_id,
              active: true,
              registration: p.userCourse ? p.userCourse : {},
              rating: p.rating,
              ratingCount: p.ratingCount,
            };

            if (courseObj.registration && courseObj.registration.course) {
              angular.extend(courseObj, courseObj.registration.course);
            }

            // It should not be overriding. Price always shown for prerequisite as first price for course
            courseObj.price = p.price;

            p.course = new Course(courseObj);
          }

          if (!p.course.registration && p.userCourse) {
            p.course.registration = p.userCourse;
          }

          if (p.course_status_id) {
            p.isCompleted = _isCompleted(p.course_status_id);
          }

          return p;
        });
      }

      function _isCompleted(statusId) {
        return _.includes(courseRegistrationStatus.strictCompletedSet, statusId);
      }
    }
  }
})();
