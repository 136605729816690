(function () {
  angular.module('kmi.lms.course.registrations').component('registrationAssessmentResult', {
    template: require('ajs/modules/course/registrations/view/components/assessment-result.html').default,
    controller: RegistrationAssessmentResultController,
    controllerAs: 'vm',
    bindings: {
      assessmentResult: '=',
      registration: '=',
      course: '=',
      shortView: '=?',
      points: '=?',
      percentage: '=?',
      regsReadOnly: '<?',
    },
  });

  /* @ngInject */
  function RegistrationAssessmentResultController($state, elms, currentUser) {
    var vm = this,
      statuses = {
        3: 'Passed',
        4: 'Failed',
      };

    vm.formatDigit = elms.formatDigit;
    vm.isNumber = angular.isNumber;

    vm.reviewQuiz = reviewQuiz;
    vm.getAssessmentResultStatus = getAssessmentResultStatus;
    vm.$onInit = onInit;

    function onInit() {
      vm.quizReviewEnabled = !vm.assessmentResult.assessmentId && !!$state.get('main.courseQuizResult');
      vm.regsReadOnly = vm.regsReadOnly || !currentUser.get().permissions.includes('course.quiz.result.view');
      vm.showDetailedView = !vm.shortView;
    }

    function reviewQuiz() {
      $state.go('main.courseQuizResult', {
        courseId: vm.registration.courseId,
        resultId: vm.assessmentResult.id,
      });
    }

    function getAssessmentResultStatus() {
      return statuses[vm.assessmentResult.statusId] === vm.assessmentResult.statusName
        ? vm.assessmentResult.statusName
        : statuses[vm.assessmentResult.statusId];
    }
  }
})();
