(function () {
  angular.module('kmi.lms.course.details').component('courseAssessmentIntendedForState', {
    template: require('ajs/modules/course/views/components/state-messages/assessment-intended-for-admin.html').default,
    controller: CourseAssessmentIntendedForStateController,
    controllerAs: 'vm',
    bindings: {
      course: '=',
      userCourse: '=',
    },
  });

  /* @ngInject */
  function CourseAssessmentIntendedForStateController() {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.show = vm.userCourse.lastRegistration.statusId === 9 && vm.userCourse.assessmentIntendedFor === 2;
    }
  }
})();
