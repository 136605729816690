export class ElmsAuthService {
  rootScopeService: any;
  http: any;
  $q: any;
  csrfProtection: any;
  User: any;
  authService: any;
  currentUser: any;
  navigationService: any;
  LmsBasePathHttpHeader: any;
  $injector: any;
  $window: any;
  locationHistory: any;
  LOGIN_SERVICE_URL: any;
  modalInstance: any;
  authToken: string;
  auditAuthToken: string;
  authorised: boolean;

  static $inject = [
    'rootScopeService',
    '$http',
    '$q',
    'csrfProtection',
    'User',
    'authService',
    'currentUser',
    'apiUrl',
    'navigationService',
    'LmsBasePathHttpHeader',
    '$injector',
    '$window',
    'locationHistory',
  ];
  constructor(
    rootScopeService: any,
    $http: any,
    $q: any,
    csrfProtection: any,
    User: any,
    authService: any,
    currentUser: any,
    apiUrl: any,
    navigationService: any,
    LmsBasePathHttpHeader: any,
    $injector: any,
    $window: any,
    locationHistory: any,
  ) {
    this.rootScopeService = rootScopeService;
    this.http = $http;
    this.$q = $q;
    this.csrfProtection = csrfProtection;
    this.User = User;
    this.authService = authService;
    this.currentUser = currentUser;
    this.navigationService = navigationService;
    this.LmsBasePathHttpHeader = LmsBasePathHttpHeader;
    this.$injector = $injector;
    this.$window = $window;
    this.locationHistory = locationHistory;

    this.LOGIN_SERVICE_URL = apiUrl('/a/login/');
    this.authToken = '';
    this.authorised = false;
  }

  login(credentials: any) {
    return this.http.post(this.LOGIN_SERVICE_URL, credentials).then(
      (response: any) => {
        var data = response.data;

        this.csrfProtection.createCsrfHeaders();

        // if success, confirm login with authService
        if (data.success) {
          return this.currentUser.loadData().then(() => {
            let lastVisit = this.locationHistory.lastVisit;

            if (lastVisit) {
              this.navigationService.setTargetState(lastVisit.state);
              this.locationHistory.clearLastVisit();
            }

            // notify authService to retry all the buffered http requests
            this.authService.loginConfirmed();
            //Clear the information about the alert which is to be stored for anonymous user
            sessionStorage.setItem('Alert', null);

            this.authToken = data.authToken;
            this.rootScopeService.broadcast('elmsAuthService.notification.login');
          });
        } else {
          this.rootScopeService.broadcast('elmsAuthService.error.login', data.message, data.status, data.data);
          return this.$q.reject(data);
        }
      },
      (error: any) => {
        this.rootScopeService.broadcast('elmsAuthService.error.login', error.message, error.status, error.data);
        return this.$q.reject(error);
      },
    );
  }

  logout() {
    return this.http.delete(this.LOGIN_SERVICE_URL).then((response: any) => {
      this.authToken = '';
      sessionStorage.clear();
      this.rootScopeService.broadcast('elmsAuthService.notification.logout', response.data.targetUrl);
      return response.data;
    });
  }

  refreshUserLoggedIn() {
    return this.User.get(null, { full: false }).then((user: any) => {
      var oldUser = this.currentUser.get();

      if (oldUser.id !== user.id) {
        this.currentUser.set(user);
        this.rootScopeService.broadcast('elmsAuthService.notification.logout');
      }
      return oldUser.id === user.id;
    });
  }

  loginSso(token: string, portal: any) {
    var headers = {};

    headers[this.LmsBasePathHttpHeader] = portal.basePath;

    return this.http.post(['/a/login/sso/', token, '/'].join(''), null, { headers: headers });
  }

  setAuditToken(token: string) {
    this.auditAuthToken = token;
  }

  showAuthTicketComplianceError() {
    if (this.modalInstance) {
      return;
    }

    // Service initialized later because of circular dependency problem.
    var modal = this.$injector.get('$uibModal');
    this.modalInstance = modal.open({
      keyboard: false,
      backdrop: 'static',
      component: 'authTicketComplianceErrorComponent',
      windowClass: 'modal-window-top-level',
    });

    this.modalInstance.result.then(() => {
      this.$window.location.reload();
    });
  }

  isImpersonated() {
    return (
      this.$window.elmsEnvironment &&
      !!this.$window.elmsEnvironment.impersonatedByUser &&
      !this.currentUser.get().anonymous
    );
  }
}
