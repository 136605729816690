(function () {
  /**
   * @ngdoc filter
   * @name kmi.lms.components.filter:urlVersion
   *
   *
   * @description
   * Add timestamp version to url if timestamp exists
   */
  angular.module('kmi.lms.core').filter('urlVersion', urlVersion);

  /* @ngInject */
  function urlVersion(urlVersionService) {
    return function (url) {
      var timestamp = urlVersionService.get();

      if (angular.isDefined(timestamp) && timestamp !== null) {
        url = [url, url.indexOf('?') === -1 ? '?' : '&', '_=', timestamp].join('');
      }

      return url;
    };
  }
})();
